import React from 'react'
import AccountTab from '../../Component/AccountPage/AccountTab'
import AccountProfileSection from '../../Component/AccountPage/AccountProfileSection'
import { GroupsTab } from '../../Component/AccountPage/GroupsTab'

export default function Group() {
  return (
    <div className="bg-[#000] overflow-hidden h-full mb-2  relative">
    {/* <p className="text-[#ff3b3b] text-center w-full mb-2  text-[12px] font-[700] flex justify-center z-50 lg:text-[10px] uppercase">
        CC ACCOUNT GROUP PAGE-FE - V11 / JUNE 29, 2024
    </p> */}
    <p className="text-[#ff3b3b] text-center w-full mb-2  text-[12px] font-[700] flex justify-center z-50 lg:text-[10px] uppercase">
    Copyright © 2024 Galileo One Inc
    </p>
    <div className="">
      <AccountProfileSection />
    </div>
    <AccountTab activeTab={"Group"} />
    <div className="p-2 overflow-y-scroll no-scrollbar absolute w-full h-[-webkit-fill-available]">
      <GroupsTab />
    </div>
  </div>
  )
}
