import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";

const LandingPage = ({ isMobile }) => {
   const navigate = useNavigate();

   return (
      <Fragment>
         {!isMobile && (
            <p className="hidden md:block text-center text-[24px] font-semibold py-4">
               Settings
            </p>
         )}
         <div className={`flex flex-col lg:flex-row flex-wrap pb-3 mx-1`}>
            <div className={`w-full ${isMobile ? "" : "lg:w-1/3"} px-2 mb-3`}>
               <div
                  className="bg-white rounded-2xl px-5 lg:px-4 py-4 lg:py-4 h-[22vh] md:h-[25vh] shadow-[rgba(0,_0,_0,_0.50)_0px_2px_10px] cursor-pointer"
                  onClick={() => navigate("/settings/account")}
               >
                  <p className="text-center text-[20px] font-semibold mb-3">
                     Accounts
                  </p>
                  <p className="text-justify text-[16px] md:text-[14px]">
                     We use your Name and other Identifying details to verify your
                     account and they remain private. Change identifying details for
                     your account here.
                  </p>
               </div>
            </div>
            <div className={`w-full ${isMobile ? "" : "lg:w-1/3"} px-2 mb-3`}>
               <div
                  className="bg-white rounded-2xl px-5 lg:px-4 py-4 lg:py-4 h-[22vh] md:h-[25vh] shadow-[rgba(0,_0,_0,_0.50)_0px_2px_10px] cursor-pointer"
                  onClick={() => navigate("/settings/security")}
               >
                  <p className="text-center text-[20px] font-semibold mb-3">
                     Security
                  </p>
                  <p className="text-justify text-[16px] md:text-[14px]">
                     You to setup MFA, last seen and logged in status, deactivate or
                     delete your accounts from here.
                  </p>
               </div>
            </div>
            <div className={`w-full ${isMobile ? "" : "lg:w-1/3"} px-2 mb-3`}>
               <div
                  className="bg-white rounded-2xl px-5 lg:px-4 py-4 lg:py-4 h-[22vh] md:h-[25vh] shadow-[rgba(0,_0,_0,_0.50)_0px_2px_10px] cursor-pointer"
                  onClick={() => navigate("/settings/privacy")}
               >
                  <p className="text-center text-[20px] font-semibold mb-3">
                     Privacy
                  </p>
                  <p className="text-justify text-[16px] md:text-[14px]">
                     Control what you see across CC. To see changes, refresh the page,
                     or restart Chaa Ching  on your devices. Completing this information helps
                     us give you a better user experience.
                  </p>
               </div>
            </div>
            <div className={`w-full ${isMobile ? "" : "lg:w-1/3"} px-2 mb-3`}>
               <div
                  className="bg-white rounded-2xl px-5 lg:px-4 py-4 lg:py-4 h-[22vh] md:h-[25vh] shadow-[rgba(0,_0,_0,_0.50)_0px_2px_10px] cursor-pointer"
                  onClick={() => navigate("/settings/preferences")}
               >
                  <p className="text-center text-[20px] font-semibold mb-3">
                     Preferences
                  </p>
                  <p className="text-justify text-[16px] md:text-[14px]">
                     Control what you see across CC. To see changes, refresh the page,
                     or restart Chaa Ching  on your devices. Completing this information helps
                     us give you a better user experience.
                  </p>
               </div>
            </div>
            <div className={`w-full ${isMobile ? "" : "lg:w-1/3"} px-2 mb-3`}>
               <div
                  className="bg-white rounded-2xl px-5 lg:px-4 py-4 lg:py-4 h-[22vh] md:h-[25vh] shadow-[rgba(0,_0,_0,_0.50)_0px_2px_10px] cursor-pointer"
                  onClick={() => navigate("/settings/notifications")}
               >
                  <p className="text-center text-[20px] font-semibold mb-3">
                     Notifications
                  </p>
                  <p className="text-justify text-[16px] md:text-[14px]">
                     Keep track of what’s happening in your CC via email alerts,
                     on-site notifications, or a combination of the two.
                  </p>
               </div>
            </div>
            <div className={`w-full ${isMobile ? "" : "lg:w-1/3"} px-2 mb-3`}>
               <div
                  className="bg-white rounded-2xl px-5 lg:px-4 py-4 lg:py-4 h-[22vh] md:h-[25vh] shadow-[rgba(0,_0,_0,_0.50)_0px_2px_10px] cursor-pointer"
                  onClick={() => navigate("/settings/display")}
               >
                  <p className="text-center text-[20px] font-semibold mb-3">
                     Display 
                  </p>
                  <p className="text-justify text-[16px] md:text-[14px]">
                  Personalize your app experience with Display Settings. Adjust brightness, themes, font sizes, and more. Switch between light and dark modes for optimal comfort, creating a user-friendly interface tailored to you
                  </p>
               </div>
            </div>
            <div className={`w-full ${isMobile ? "" : "lg:w-1/3"} px-2 mb-3`}>
               <div
                  className="bg-white rounded-2xl px-5 lg:px-4 py-4 lg:py-4 h-[22vh] md:h-[25vh] shadow-[rgba(0,_0,_0,_0.50)_0px_2px_10px] cursor-pointer"
                  onClick={() => navigate("/settings/whatsnew")}
               >
                  <p className="text-center text-[20px] font-semibold mb-3">
                     What’s New
                  </p>
                  <p className="text-justify text-[16px] md:text-[14px]">
                     Here’s what we’ve been up to. They're not official features, so if
                     something weird happens, please let us know! And if it keeps being
                     weird, you can just turn it off.
                  </p>
               </div>
            </div>
         </div>
      </Fragment>
   );
};

export default LandingPage;
