import React, { Fragment, useState, useRef, useEffect } from "react";
import {
  RiCloseCircleLine,
  RiVideoUploadFill,
  RiVideoAddFill,
  RiAdvertisementLine,
} from "react-icons/ri";
import { CiStreamOn } from "react-icons/ci";
import { MdGroupAdd } from "react-icons/md";
import {  IoIosTrendingUp } from "react-icons/io";
import { RiFolderVideoFill } from "react-icons/ri";
import { Drawer, Typography } from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";
import { Uploadvideo } from "../../Redux/ApiSlice/UploadVideoSlice";
import { useDispatch } from "react-redux";
import { UploadVideoWaringModal } from "../Modals/UploadVideo/UploadVideoWaringModal";
import Carousel from "react-multi-carousel";

import { TbArrowBigRightLinesFilled } from "react-icons/tb";

const Post = ({ openDrawerBottom, closeDrawerBottom, openBottom }) => {
  const dispatch = useDispatch();
  const [selectedImg, setSelectedImg] = useState(null);
  const [selectVideo, setSelectVideo] = useState(null);
  const [waringOpen, setWaringOpen] = useState(false);

  const navigate = useNavigate();
  const fileRef = useRef(null);

  const handleOpen = () => setWaringOpen(!waringOpen);

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (!file) return;
    const fileUrl = URL.createObjectURL(file);

    // const tempImgs = JSON.parse(JSON.stringify());
    // tempImgs[selectedImg].src = fileUrl;

    if (file.size > 100e6) {
      return handleOpen();
    } else {
      setSelectedImg(fileUrl);
      setSelectVideo(file);
      dispatch(Uploadvideo({ fileURL: fileUrl, file }));
    }
  };
  const handleNavigate = (value) => {
    navigate(`${value}`);
    closeDrawerBottom();
  };
  useEffect(() => {
    if (selectVideo) {
      navigate("/uploadvideo");
      closeDrawerBottom();
    }
    return () => {
      closeDrawerBottom();
    };
  }, [selectVideo]);


  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 2
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      slidesToSlide: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 3,
      slidesToSlide: 1
    }
  };


  // Custom Right Arrow Component
  const CustomRightArrow = ({ onClick }) => {
    return (
      <button
        className="absolute -right-2 top-1/2 transform -translate-y-1/2  p-2 rounded-full shadow-lg z-10"
        onClick={onClick}
      >
        <TbArrowBigRightLinesFilled className="text-black hover:text-red-700" />
        {/* <FiArrowRight size={20} /> */}
      </button>
    );
  };

  // Custom Left Arrow Component (Hidden)
  const CustomLeftArrow = () => null;


  return (
    <Fragment>
      {openBottom && (
        <Drawer
          placement="bottom"
          open={openBottom}
          onClose={closeDrawerBottom}
          size={window.screen.width > 500 ? 220 : 250}
          className="px-0 py-3 w-full  max-h-20 md:max-h-20 absolute"
          style={{ zIndex: 1000 }}
        >
          <div className="mb-4 md:mb-4 px-4 flex items-center  justify-center">
            <Typography variant="h4" color="blue-gray">
              Create
            </Typography>
            <RiCloseCircleLine
              className="text-[23px] absolute right-2 cursor-pointer"
              onClick={closeDrawerBottom}
            />
          </div>
          <Carousel
            swipeable={true}
            draggable={true}
            showDots={false}
            responsive={responsive}
            customRightArrow={<CustomRightArrow />}
            customLeftArrow={<CustomLeftArrow />}
            ssr={true}
            infinite={true}
            className=""
          >
            <div className="grid grid-cols-2 grid-rows-2 gap-2 px-2">
              <div className="col-span-2 flex flex-col justify-center items-center cursor-pointer">
                <RiVideoUploadFill
                  className="text-[3rem] md:text-[2rem]"
                  onClick={() => {
                    fileRef.current?.click();
                  }}
                />
                <div className="text-[11px] md:text-[9px] text-center">
                  Upload Video
                </div>
                <input
                  type="file"
                  ref={fileRef}
                  className="hidden"
                  accept="video/*"
                  onChange={handleFileChange}
                />
              </div>
              <div className="col-span-2 flex flex-col justify-center items-center cursor-pointer">
                <MdGroupAdd
                  className="text-[3rem] md:text-[2rem]"
                  onClick={() => handleNavigate("/createnewgroup")}
                />
                <div
                  className="text-[11px] md:text-[9px] text-center"
                  onClick={() => handleNavigate("/createnewgroup")}
                >
                  Create Group
                </div>
              </div>

            </div>
            <div className="grid grid-cols-2 grid-rows-2 gap-2 px-2">
              <div className="col-span-2 flex flex-col justify-center items-center cursor-pointer">
                <RiVideoAddFill
                  className="text-[3rem] md:text-[2rem]"
                  onClick={() => handleNavigate("/createvideo")}
                />
                <div
                  className="text-[11px] md:text-[9px] text-center "
                  onClick={() => handleNavigate("/createvideo")}
                >
                  Create Video
                </div>
              </div>
              <div className="col-span-2 flex flex-col justify-center items-center cursor-pointer">
                <RiFolderVideoFill
                  className="text-[3rem] md:text-[2rem]"
                  onClick={() => handleNavigate("/account/saved")}
                />
                <div
                  className="text-[11px] md:text-[9px] text-center"
                  onClick={() => handleNavigate("/account/saved")}
                >
                  Saved Videos
                </div>
              </div>
            </div>
            <div className="grid grid-cols-2 grid-rows-2 gap-2 px-2">
              <div className="col-span-2 flex flex-col justify-center items-center cursor-pointer">
                <CiStreamOn className="text-[3rem] md:text-[2rem]" />
                <div
                  className="text-[11px] md:text-[9px] text-center"
                  onClick={() => handleNavigate("/createLiveStream")}
                >
                  Live Stream
                </div>
              </div>
              <div className="col-span-2 flex flex-col justify-center items-center cursor-pointer"  >
                <RiAdvertisementLine  className="text-[3rem] md:text-[2rem]" onClick={() => handleNavigate("/commingsoon")} />
                <div className="text-[11px] md:text-[9px] text-center" onClick={() => handleNavigate("/commingsoon")} >
                  Create Advert
                </div>
              </div>
            </div>
            <div className="grid grid-cols-2 grid-rows-2 gap-2 px-2">
              <div className="col-span-2 flex flex-col justify-center items-center cursor-pointer">
                <CiStreamOn className="text-[3rem] md:text-[2rem]" />
                <div
                  className="text-[11px] md:text-[9px] text-center"
                  onClick={() => handleNavigate("/createLiveStream")}
                >
                  Live Stream
                </div>
              </div>
              <div className="col-span-2 flex flex-col justify-center items-center cursor-pointer">
                <RiFolderVideoFill
                  className="text-[3rem] md:text-[2rem]"
                  onClick={() => handleNavigate("/account/saved")}
                />
                <div
                  className="text-[11px] md:text-[9px] text-center"
                  onClick={() => handleNavigate("/account/saved")}
                >
                  Saved Videos
                </div>
              </div>
            </div>

          </Carousel>
          <div className=" mt-4 flex justify-center flex-col">
            <h2 className="text-[red] font-bold text-[10px] text-center">
            Copyright © 2024 Galileo One Inc
            </h2>
            {/* <h2 className="text-[red] font-bold text-[10px] text-center">
              CC CREATE VIDEO /FE/ V1 /Nov 18 / 2024
            </h2> */}
            {/* <p className="text-center text-[8px] text-grey-100 font-bold ">
              Copyright © 2024 by Vikas Rai and Sudha Rai and Galileo One Inc
            </p> */}
          </div>
        </Drawer>
      )}
      {waringOpen && (
        <UploadVideoWaringModal
          message={"Please upload a file smaller than 100 MB"}
          open={waringOpen}
          handleOpen={handleOpen}
        />
      )}
    </Fragment>
  );
};

export default Post;
