import React, { Fragment, useEffect, useRef, useState } from "react";
import DesktopNav from "./DesktopNav";
import { IoCaretBackCircleSharp, IoPersonAddSharp } from "react-icons/io5";
import { BsThreeDots } from "react-icons/bs";
import { MdClose } from "react-icons/md";
import { Avatar } from "@material-tailwind/react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {
   Card,
   CardBody,
   CardFooter,
   Menu,
   MenuHandler,
   MenuList,
   MenuItem,
   Button,
} from "@material-tailwind/react";
import { UnmuteModal } from "../Modals/Settings/UnmuteModal";
import { useNavigate } from "react-router-dom";

const responsive = {
   desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 1,
   },
   tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      slidesToSlide: 1,
   },
   mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 3,
      slidesToSlide: 1,
   },
};

const Notifications = ({ itsMobile }) => {
   const [isChecked1, setIsChecked1] = useState(false);
   const [isChecked2, setIsChecked2] = useState(false);
   const [isChecked3, setIsChecked3] = useState(false);
   const [isChecked4, setIsChecked4] = useState(false);
   const [isChecked5, setIsChecked5] = useState(false);
   const [isChecked6, setIsChecked6] = useState(false);
   const [isChecked7, setIsChecked7] = useState(false);
   const [isChecked8, setIsChecked8] = useState(false);
   const [open, setOpen] = useState(false);
   const [ShowPopup, setShowPopup] = useState(false);
   const divRef = useRef(null);
   const navigate = useNavigate();

   const handleOpen = () => setOpen((cur) => !cur);
   const handleShow = () => setShowPopup((cur) => !cur);

   const handleCheckbox1 = () => {
      setIsChecked1(!isChecked1);
   };

   const handleCheckbox2 = () => {
      setIsChecked2(!isChecked2);
   };

   const handleCheckbox3 = () => {
      setIsChecked3(!isChecked3);
   };

   const handleCheckbox4 = () => {
      setIsChecked4(!isChecked4);
   };

   const handleCheckbox5 = () => {
      setIsChecked5(!isChecked5);
   };

   const handleCheckbox6 = () => {
      setIsChecked6(!isChecked6);
   };

   const handleCheckbox7 = () => {
      setIsChecked7(!isChecked7);
   };

   function handleClickOutside(event) {
      if (divRef.current && !divRef.current.contains(event.target)) {
         setShowPopup(false);
      }
   }

   useEffect(() => {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
         document.removeEventListener("mousedown", handleClickOutside);
      };
   }, [divRef]);

   const sampleData = [
      {
         name: "Veronica",
         image:
            "https://th.bing.com/th/id/OIP.oyEI7Z0cZaXpMexP2nCqVQHaFj?w=250&h=187&c=7&r=0&o=5&dpr=1.2&pid=1.7",
      },
      {
         name: "Stella",
         image:
            "https://th.bing.com/th/id/OIP.JLBwl-I1dT0F1sJAHQc8qQHaEK?w=274&h=180&c=7&r=0&o=5&dpr=1.2&pid=1.7",
      },
      {
         name: "Mathew",
         image:
            "https://th.bing.com/th/id/OIP.fypDPPjKoONeHAF4SdTCnQHaIh?w=162&h=187&c=7&r=0&o=5&dpr=1.2&pid=1.7",
      },
      {
         name: "Nina",
         image:
            "https://th.bing.com/th/id/OIP.gaoOqi05212_Alqymzpm_AHaEK?w=268&h=183&c=7&r=0&o=5&dpr=1.2&pid=1.7",
      },
   ];

   return (
      <Fragment>
         {/* <DesktopNav /> */}
         <p className="text-center font-bold text-[21px] cursor-pointer mt-4 px-3">
            <IoCaretBackCircleSharp
               className="text-black"
               onClick={() => navigate(-1)}
            />
         </p>
         <p className="text-[22px] text-center font-bold text-black">
            Notification
         </p>
         <div className={`px-3  ${itsMobile ? "" : "mx-0 shadow-none"} my-0  shadow-none rounded-none lg:rounded-xl`}>
            <p className="text-justify text-[12px] font-normal flex justify-center items-center mt-2 ">
               Keep track of what’s happening IN YOUR Chaa Ching  via email alerts, on-site
               notifications, or a combination of the two.
            </p>
            <div className="mt-5 text-justify text-[16px] font-bold  text-[#000000]">
               <h2>In App Notification</h2>
            </div>
            <div className="mt-1">
               <p className="text-justify text-[12px] font-normal mt-1 ">
                  You will always get alerted with notifications about reactions,
                  mentions AND invitations. To access your notifications, click on the
                  notification icon at the top of the page. You can also choose to
                  mute notifications.
               </p>
            </div>
            <div className="mt-5 text-justify text-[16px] font-bold  text-[#000000]">
               <h2>Mute Notifications</h2>
            </div>
            <div className="mt-1">
               <p className="text-justify text-[12px] font-normal mt-1">
                  You can mute on-site notifications for your contacts and interests.
                  You will not be shown notifications for them. A user's mute list is
                  private and not shown to other users on Chaa Ching . Learn More
               </p>
            </div>
            <div className="my-2">
               <Carousel
                  swipeable={true}
                  draggable={true}
                  showDots={false}
                  responsive={responsive}
                  ssr={true}
                  infinite={true}
                  removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
               >
                  {sampleData.map((data) => (
                     <div className="mt-2">
                        <div className="relative w-[90%]  rounded-2xl ">
                           <img
                              className="object-cover rounded-2xl h-24 w-full"
                              src={data.image}
                           />
                           <p className="text-[12px] text-black text-center mt-2 font-semibold">
                              {data.name}
                           </p>

                           <MdClose
                              className="text-[0.7rem] text-white absolute top-2 right-2 hidden md:block cursor-pointer bg-gray-600 rounded-sm"
                              onClick={handleOpen}
                           />
                        </div>
                     </div>
                  ))}
               </Carousel>
            </div>

            <div className="mt-6 text-justify text-[16px] font-bold text-[#000000]">
               <h2>Email Notifications</h2>
            </div>
            <div className="mt-1">
               <p className="text-justify text-[12px] font-normal">
                  You can choose to get alerted about invitations, reactions and
                  mentions with email notifications. Control what types of email
                  notifications you get from Chaa Ching .
               </p>
            </div>
            <div className="mt-5">
               <div className="flex justify-between items-start">
                  <p className="text-start text-[12px] font-medium">
                     Send me a weekly summary email of all unread notifications
                  </p>
                  <label className="autoSaverSwitch relative inline-flex cursor-pointer select-none items-center">
                     <input
                        type="checkbox"
                        name="autoSaver"
                        className="sr-only"
                        checked={isChecked1}
                        onChange={handleCheckbox1}
                     />
                     <span className={`slider flex h-[19px] w-[40px] items-center rounded-full p-1 duration-200 ${isChecked1 ? "bg-[#ae47f8]" : "bg-[#d9d9d9]"}`}>
                        <span className={`dot h-[13px] w-[13px] rounded-full ${isChecked1
                           ? "bg-[#ffffff]"
                           : "bg-[#ae47f8]"} shadow-2xl duration-200 ${isChecked1 ? "translate-x-5" : ""}`}
                        />
                     </span>
                  </label>
               </div>
            </div>
            <div className="mt-5">
               <div className="flex justify-between items-start">
                  <p className="text-start text-[12px] font-medium">
                     Send me a Daily summary email of all reactions to my content
                     received that day
                  </p>
                  <label className="autoSaverSwitch relative inline-flex cursor-pointer select-none items-center">
                     <input
                        type="checkbox"
                        name="autoSaver"
                        className="sr-only"
                        checked={isChecked2}
                        onChange={handleCheckbox2}
                     />
                     <span className={`slider flex h-[19px] w-[40px] items-center rounded-full p-1 duration-200 ${isChecked2 ? "bg-[#ae47f8]" : "bg-[#d9d9d9]"}`}>
                        <span
                           className={`dot h-[13px] w-[13px] rounded-full ${isChecked2
                              ? "bg-[#ffffff]"
                              : "bg-[#ae47f8]"} shadow-2xl duration-200 ${isChecked2 ? "translate-x-5" : ""}`}
                        />
                     </span>
                  </label>
               </div>
            </div>
            <div className="mt-5">
               <div className="flex justify-between items-start">
                  <p className="text-start text-[12px] font-medium">
                     Send me a daily summary email of all mentioned and tags received
                     that day
                  </p>
                  <label className="autoSaverSwitch relative inline-flex cursor-pointer select-none items-center">
                     <input
                        type="checkbox"
                        name="autoSaver"
                        className="sr-only"
                        checked={isChecked3}
                        onChange={handleCheckbox3}
                     />
                     <span className={`slider flex h-[19px] w-[40px] items-center rounded-full p-1 duration-200 ${isChecked3 ? "bg-[#ae47f8]" : "bg-[#d9d9d9]"}`}>
                        <span
                           className={`dot h-[13px] w-[13px] rounded-full ${isChecked3
                              ? "bg-[#ffffff]"
                              : "bg-[#ae47f8]"} shadow-2xl duration-200 ${isChecked3 ? "translate-x-5" : ""}`}
                        />
                     </span>
                  </label>
               </div>
            </div>
            <div className="mt-5">
               <div className="flex justify-between items-start">
                  <p className="text-start text-[12px] font-medium">
                     Send me a daily summary email of all requests and invitations
                     received that day
                  </p>
                  <label className="autoSaverSwitch relative inline-flex cursor-pointer select-none items-center">
                     <input
                        type="checkbox"
                        name="autoSaver"
                        className="sr-only"
                        checked={isChecked4}
                        onChange={handleCheckbox4}
                     />
                     <span className={`slider flex h-[19px] w-[40px] items-center rounded-full p-1 duration-200 ${isChecked4 ? "bg-[#ae47f8]" : "bg-[#d9d9d9]"}`}>
                        <span
                           className={`dot h-[13px] w-[13px] rounded-full ${isChecked4
                              ? "bg-[#ffffff]"
                              : "bg-[#ae47f8]"} shadow-2xl duration-200 ${isChecked4 ? "translate-x-5" : ""}`}
                        />
                     </span>
                  </label>
               </div>
            </div>

            <div className="mt-5 text-justify text-[16px] font-bold text-[#000000]">
               <h2>Email Alerts</h2>
            </div>
            <div className="mt-1">
               <p className="text-justify text-[12px] font-bold text-[#000000eb]">
                  Control what alerts you receive from CC.
               </p>
            </div>
            <div className="mt-5 mb-5">
               <div className="flex justify-between items-start mt-2">
                  <p className="text-start text-[12px] font-medium ">
                     Subscribe to survey emails
                  </p>
                  <label className="autoSaverSwitch relative inline-flex cursor-pointer select-none items-center">
                     <input
                        type="checkbox"
                        name="autoSaver"
                        className="sr-only"
                        checked={isChecked7}
                        onChange={handleCheckbox7}
                     />
                     <span className={`slider flex h-[19px] w-[40px] items-center rounded-full p-1 duration-200 ${isChecked7 ? "bg-[#ae47f8]" : "bg-[#d9d9d9]"}`}>
                        <span
                           className={`dot h-[13px] w-[13px] rounded-full ${isChecked7
                              ? "bg-[#ffffff]"
                              : "bg-[#ae47f8]"} shadow-2xl duration-200 ${isChecked7 ? "translate-x-5" : ""}`}
                        />
                     </span>
                  </label>
               </div>
            </div>
         </div>
         <UnmuteModal handleOpen={handleOpen} open={open} />
      </Fragment>
   );
};

export default Notifications;
