import React, { useState } from "react";
import { Drawer, Card } from "@material-tailwind/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { IoIosArrowForward } from "react-icons/io";

const SliderMenu = () => {
   const [isDrawerOpen, setIsDrawerOpen] = useState(false);

   const openDrawer = () => setIsDrawerOpen(true);
   const closeDrawer = () => setIsDrawerOpen(false);

   return (
      <>
         <button onClick={openDrawer}>
            {isDrawerOpen ? (
               <XMarkIcon className={`h-7 w-7 stroke-2 text-[#fff]`} />
            ) : (
               <Bars3Icon className={`h-7 w-7 stroke-2 text-[#fff]`} />
            )}
         </button>
         <Drawer
            open={isDrawerOpen}
            onClose={closeDrawer}
            placement="right"
            className="absolute overflow-y-scroll w-[75%]"
         // style={{ zIndex: 1, height: "100vh", maxHeight: "100vh" }}
         >
            <Card
               color="transparent"
               shadow={false}
               className="overflow-scroll no-scrollbar rounded-none"
            // style={{ zIndex: 1, height: "100vh", maxHeight: "100vh" }}
            >
               <p className="text-[red] text-[6px] font-bold absolute top-1 w-full text-center">
               Copyright © 2024 Galileo One Inc
               </p>
               {/* <p className="text-[red] text-[6px] font-bold absolute top-1 w-full text-center">
                  CC GROUP OWNER SEARCH SLIDER MENU - FE - V2 - APR 25, 2024
               </p> */}
               <div className="pt-5">
                  <div className="px-3 py-2 flex justify-start items-center gap-[6px] transition ease-in-out border-b border-gray-300 hover:scale-105 duration-300 hover:bg-[#d9d9d9] hover:text-black">
                     <IoIosArrowForward className="cursor-pointer" />
                     <p className="cursor-pointer">Menu 01</p>
                  </div>
                  <div className="px-3 py-2 flex justify-start items-center gap-[6px] transition ease-in-out border-b border-gray-300 hover:scale-105 duration-300 hover:bg-[#d9d9d9] hover:text-black">
                     <IoIosArrowForward className="cursor-pointer" />
                     <p className="cursor-pointer">Menu 02</p>
                  </div>
                  <div className="px-3 py-2 flex justify-start items-center gap-[6px] transition ease-in-out border-b border-gray-300 hover:scale-105 duration-300 hover:bg-[#d9d9d9] hover:text-black">
                     <IoIosArrowForward className="cursor-pointer" />
                     <p className="cursor-pointer">Menu 03</p>
                  </div>
                  <div className="px-3 py-2 flex justify-start items-center gap-[6px] transition ease-in-out border-b border-gray-300 hover:scale-105 duration-300 hover:bg-[#d9d9d9] hover:text-black">
                     <IoIosArrowForward className="cursor-pointer" />
                     <p className="cursor-pointer">Menu 04</p>
                  </div>
                  <div className="px-3 py-2 flex justify-start items-center gap-[6px] transition ease-in-out border-b border-gray-300 hover:scale-105 duration-300 hover:bg-[#d9d9d9] hover:text-black">
                     <IoIosArrowForward className="cursor-pointer" />
                     <p className="cursor-pointer">Menu 05</p>
                  </div>
                  <div className="px-3 py-2 flex justify-start items-center gap-[6px] transition ease-in-out border-b border-gray-300 hover:scale-105 duration-300 hover:bg-[#d9d9d9] hover:text-black">
                     <IoIosArrowForward className="cursor-pointer" />
                     <p className="cursor-pointer">Menu 06</p>
                  </div>
                  <div className="px-3 py-2 flex justify-start items-center gap-[6px] transition ease-in-out border-b border-gray-300 hover:scale-105 duration-300 hover:bg-[#d9d9d9] hover:text-black">
                     <IoIosArrowForward className="cursor-pointer" />
                     <p className="cursor-pointer">Menu 07</p>
                  </div>
                  <div className="px-3 py-2 flex justify-start items-center gap-[6px] transition ease-in-out border-b border-gray-300 hover:scale-105 duration-300 hover:bg-[#d9d9d9] hover:text-black">
                     <IoIosArrowForward className="cursor-pointer" />
                     <p className="cursor-pointer">Menu 08</p>
                  </div>
                  <div className="px-3 py-2 flex justify-start items-center gap-[6px] transition ease-in-out border-b border-gray-300 hover:scale-105 duration-300 hover:bg-[#d9d9d9] hover:text-black">
                     <IoIosArrowForward className="cursor-pointer" />
                     <p className="cursor-pointer">Menu 09</p>
                  </div>
                  <div className="px-3 py-2 flex justify-start items-center gap-[6px] transition ease-in-out border-b border-gray-300 hover:scale-105 duration-300 hover:bg-[#d9d9d9] hover:text-black">
                     <IoIosArrowForward className="cursor-pointer" />
                     <p className="cursor-pointer">Menu 10</p>
                  </div>
               </div>
            </Card>
         </Drawer>
      </>
   )
}

export default SliderMenu