import React, { useEffect, useRef, useState } from 'react'
import { BiSearch } from 'react-icons/bi'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { FaCircleArrowLeft } from 'react-icons/fa6'
import { Card } from "@material-tailwind/react";
import { useOutsideClick } from '../../Hooks/useOutsideClick'
import { useNavigate } from 'react-router-dom'

export default function AchivedMessages() {
   const AchivedData = [
      {
         id: 9,
         name: "Isabella Rodriguez",
         joined: true,
         checked: false,

         userImg:
            "https://images.unsplash.com/photo-1534528741775-53994a69daeb?q=80&w=1964&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      },
      {
         id: 10,
         name: "James Wilson",
         checked: false,
         userImg:
            "https://images.unsplash.com/photo-1568602471122-7832951cc4c5?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      },
      {
         id: 1,
         name: "Emily Johnson",
         checked: false,
         userImg:
            "https://images.unsplash.com/photo-1534008757030-27299c4371b6?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      },
      {
         id: 2,
         name: "Jacob Smith",
         joined: true,
         checked: false,
         userImg:
            "https://images.unsplash.com/photo-1633332755192-727a05c4013d?q=80&w=1780&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      },
      {
         id: 3,
         name: "Olivia Brown",
         checked: false,
         userImg:
            "https://images.unsplash.com/photo-1503104834685-7205e8607eb9?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      },
      {
         id: 4,
         name: "Ethan Martinez",
         checked: false,
         userImg:
            "https://images.unsplash.com/photo-1566492031773-4f4e44671857?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      },
      {
         id: 7,
         name: "Sophia Garcia",
         checked: false,
         userImg:
            "https://images.unsplash.com/photo-1479936343636-73cdc5aae0c3?q=80&w=1780&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      },
      {
         id: 5,
         name: "Ava Anderson",
         checked: false,
         userImg:
            "https://images.unsplash.com/photo-1560238970-cc0ae073d3a5?q=80&w=1935&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      },
      {
         id: 6,
         name: "Michael Thompson",
         checked: false,
         userImg:
            "https://images.unsplash.com/photo-1530268729831-4b0b9e170218?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      },

      {
         id: 8,
         name: "William Davis",
         checked: false,
         userImg:
            "https://images.unsplash.com/photo-1489980557514-251d61e3eeb6?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      },
   ];
   const navigate = useNavigate()
   const searchref = useRef()
   const menuRef = useRef()
   const [showSearch, setShowSearch] = useState(false)
   const [showMenu, setShowMenu] = useState(false)

   const [selectAll, setSelectAll] = useState(false)
   useOutsideClick(searchref, () => setShowSearch(false))
   useOutsideClick(menuRef, () => setShowMenu(false))
   const [query, setQuery] = useState('');
   const [data, setData] = useState(null);

   const handleSearch = (event) => {
      setQuery(event.target.value);
   };

   const filteredData = data?.filter(item =>
      item.name?.toLowerCase().includes(query.toLowerCase())
   );

   const handleCheck = (id, check) => {
      const chekedData = AchivedData.map((data) => data.id == id ? { ...data, checked: !check } : data)
      setData(chekedData)
   }
   useEffect(() => {
      setData(AchivedData)
   }, [])
   return (
      <div className='h-full w-full bg-black relative'>
         {/* <p className='text-red-900 font-bold w-full absolute top-0 text-[10px] text-center'>CC ACHIVED MESSAGES PAGE , FE, V1, JULY 20 ,2024</p> */}
         <p className='text-red-900 font-bold w-full absolute top-0 text-[10px] text-center'>Copyright © 2024 Galileo One Inc</p>
         <div className='grid grid-cols-12 sticky top-0 z-50 bg-black'>
            <div className='col-span-12 mt-3'>
               <div className='flex justify-between items-center px-4 h-10 relative' ref={searchref} >
                  <FaCircleArrowLeft className='text-[1.2rem] text-white cursor-pointer' onClick={() => navigate(-1)} />
                  {showSearch ? <input type='text' className='bg-white outline-none border-none py-[0.5] px-2 w-3/4 rounded-sm placeholder:text-[14px]' placeholder='Search achived...' onChange={handleSearch} /> : <h2 className='text-[1.2rem] text-white font-bold'>
                     Achived
                  </h2>
                  }

                  {showSearch ? "" : <BiSearch className='text-white items-center absolute right-12 cursor-pointer' onClick={() => setShowSearch(true)} />
                  }
                  <BsThreeDotsVertical className='text-[1.2rem] text-white cursor-pointer' onClick={() => setShowMenu(true)} />

                  {showMenu &&
                     <Card className='absolute h-auto w-24 float-end top-5 right-3 rounded-md z-50' ref={menuRef}>
                        <p className='px-2 text-[14px] py-2 cursor-pointer hover:bg-gray-200 rounded-md font-bold' onClick={() => setShowMenu(true)}>Unachive</p>
                     </Card>
                  }
               </div>
               <hr className='h-[0.5] w-full' />
            </div>
            <div className=' col-span-12 gap-2 flex justify-end items-center py-2 px-4'>

               <p className='text-[14px] text-white'>
                  Select all
               </p>
               <input type='checkbox' onChange={() => setSelectAll(!selectAll)} checked={selectAll} />
            </div>
         </div>
         <div className="h-full w-full overflow-y-scroll absolute bg-black">

            {filteredData?.map((data) => (
               <>
                  <div
                     className="grid grid-cols-12 items-center pt-2 pb-2 px-4 hover:bg-[#1b1b1b] cursor-pointer"
                     key={data.id}
                     
                  >
                     <div className="col-span-1">
                        <input type='checkbox' checked={selectAll ? true : data.checked} onChange={() => handleCheck(data.id, data.checked)} />
                     </div>
                     <div className="col-span-2 relative" onClick={() => navigate(`/mobileMessages/userChat/123`)}>
                        <img
                           alt={data.name}
                           src={data.userImg}
                           className="h-8 w-8 rounded-full object-cover"
                        />
                        <p className='absolute flex justify-center items-center h-3 w-3 rounded-full bg-red-900 text-[white] text-[8px] text-center top-0 -left-1'>5</p>
                     </div>
                     <div className="col-span-7 ml-[-2px]" onClick={() => navigate(`/mobileMessages/userChat/123`)}>
                        <p className="text-[14px] text-white leading-[14px] font-bold">
                           {data.name}
                        </p>
                        <p className='text-[10px] text-gray-500 leading-[14px] line-clamp-1'>React Hook useEffect has missing dependencie</p>
                     </div>
                     <div className="col-span-2 flex justify-end items-center" onClick={() => navigate(`/mobileMessages/userChat/123`)}>
                        <p className='text-[10px] text-blue-700 font-bold text-end'>10.00pm</p>
                     </div>
                  </div>
                  <hr className="w-full mt-[1px] my-[1px] h-[0.5px] bg-gray-900 border-0" />
               </>
            ))}
         </div>
      </div>
   )
}
