import React, { Fragment, useEffect, useState } from "react";
import cookies from "../Assets/common/cookies.svg";
import { useLocation } from "react-router-dom";

export default function Cookies() {
   const location = useLocation();
   const { pathname } = location;
   const [cookie, setCookies] = useState(false);

   useEffect(() => {
      if (pathname === "/cookie") {
         setCookies(true);
      }
      return () => {
         setCookies(false);
      };
   }, [pathname]);

   const cookiesQus = [
      { id: "1", question: "What are cookies?" },
      { id: "2", question: "How we use cookies?" },
      { id: "3", question: "Duration of cookies." },
      { id: "4", question: "Use of other technologies" },
      { id: "5", question: "Cookies preferences" },
      { id: "6", question: "What are these technologies used for?" },
      { id: "7", question: "What third parties use these technologies in connection with our Services?" },
      { id: "8", question: "What about other tracking technologies, like web beacons and SDKs?" },
      { id: "9", question: "How can you control cookies?" },
      { id: "10", question: "Revisions to this policy" },
      { id: "11", question: "How to contact us?" },
      { id: "12", question: "More information" }
   ];

   const technologies = [
      {
         qus: "Purpose",
         ans: "Description",
         id: "1",
      },
      {
         id: "2",
         qus: "Authentication",
         ans: "We use cookies and similar technologies to recognize you when you visit our Services.If you’re signed into CC, these technologies help us show you the right information and personalize your experience in line with your settings. For example, cookies enable CC to identify you and verify your account",
      },
      {
         id: "3",
         qus: "Security",
         ans: "We use cookies and similar technologies to make your interactions with our Services faster and more secure.For example, we use cookies to enable and support our security features, keep your account safe and to help us detect malicious activity and violations of our User Agreement",
      },
      {
         id: "4",
         qus: "Preferences, features and services",
         ans: "We use cookies and similar technologies to enable the functionality of our Services, such as helping you to fill out forms on our Services more easily and providing you with features, insights and customized content in conjunction with ouplugins. We also use these technologies to remember information about your browser and your preferences.For example, cookies can tell us which language you prefer and what your communications preferences are. We may also use local storage to speed up site functionality.",
      },
      {
         id: "5",
         qus: "Customized content",
         ans: "We use cookies and similar technologies to customize your experience on our Services.For example, we may use cookies to remember previous searches so that when you return to our services, we can offer additional information that relates to your previous search",
      },
      {
         id: "6",
         qus: "Plugins on and off CC",
         ans: "We use cookies and similar technologies to enable CC plugins both on and off the CC sites. For example, our plugins, including the `Apply with CC` button or the `Share` button may be found on CC or third-party sites, such as the sites of our customers and partners. Our plugins use cookies and other technologies to provide analytics and recognize you on CC and third-party sites. If you interact with a plugin (for instance, by clicking `Apply`), the plugin will use cookies to identify you and initiate your request to apply.You can learn more about plugins in our Privacy Policy",
      },
      {
         id: "7",
         qus: "Advertising ",
         ans: "Cookies and similar technologies help us show relevant advertising to you more effectively, both on and off our Services and to measure the performance of such ads. We use these technologies to learn whether content has been shown to you or whether someone who was presented with an ad later came back and took an action (e.g., downloaded a white paper or made a purchase) on another site. Similarly, our partners or service providers may use these technologies to determine whether we've shown an ad or a post and how it performed or provide us with information about how you interact with ads.We may also work with our customers and partners to show you an ad on or off CC, such as after you’ve visited a customer’s or partner’s site or application. These technologies help us provide aggregated information to our customers and partners.",
      },
      {
         id: "8",
         qus: "Analytics and research ",
         ans: "Cookies and similar technologies help us learn more about how well our Services and plugins perform in different locations.We or our service providers use these technologies to understand, improve, and research products, features and services, including as you navigate through our sites or when you access CC from other sites, applications or devices. We or our service providers, use these technologies to determine and measure the performance of ads or posts on and off CC and to learn whether you have interacted with our websites, content or emails and provide analytics based on those interactions.We also use these technologies to provide aggregated information to our customers and partners as part of our Services.If you are a CC member but logged out of your account on a browser, CC may still continue to log your interaction with our Services on that browser until the expiration of the cookie in order to generate usage analytics for our Services. We may share these analytics in aggregate form with our customers",
      },
   ];

   const scrollToAnswer = (id) => {
      console.log(id, " wertyuio")
      document?.getElementById(id)?.scrollIntoView({
         behavior: "smooth",
         block: "start",
      });
   };

   return (
         <div className="grid grid-cols-12">
            <div className="col-span-12 ">
               <img src={cookies} alt="cookies" className={`w-full mx-auto`} />
            </div>
            <div className="col-span-12 flex justify-center items-center mt-4">
               <p className={`bg-white text-black border-[1px] border-[#7a7a7a] rounded-none ${cookie ? "text-[16px] p-2" : "text-[12px] p-1"} text-justify`}>
                  Last Updated : August 24
               </p>
            </div>
            <div className={`col-span-12 ${cookie ? " px-3 md:px-56 " : "px-3"}`}>
               <div className="mt-4">
                  <p className={`${cookie ? "text-[16px]" : "text-[12px]"} text-justify`}>
                     This Cookie Notice applies to your use of www.cc.com, and any
                     other websites, applications, or services provided by CC
                     Interactive, Inc. (with its affiliates, “CC”, “we” or “us”) that
                     link to this Cookie Notice (collectively, the “CC Services”). CC
                     uses certain technologies to collect information through the CC
                     Services. In this Cookie Notice, we refer to these technologies,
                     which include HTTP cookies, local storage, pixel tags and web
                     beacons, collectively as “cookies.” This notice explains the
                     different types of cookies used on CC Services and how you can
                     control them. We may change this Cookie Notice at any time: please
                     take a look at the “last modified” legend at the top of this page
                     to see when this Cookie Notice was last revised.
                  </p>
                  <p className={`mt-2 ${cookie ? "text-[16px]" : "text-[12px]"} text-justify`}>
                     By visiting www.cc.com, setting up your CC account, or using the
                     CC Services, you are accepting the information handling practices
                     and storage and access of cookies as described in this Cookie
                     Noticer
                  </p>
                  <p className={`mt-2 ${cookie ? "text-[16px]" : "text-[11px]"} text-justify`}>
                     We hope that this notice helps you understand, and feel more
                     confident about, our use of cookies. If you have any further
                     queries, please contact us at xyz@cc.com.
                  </p>
                  <ul className="list-decimal mt-2 m-4">
                     {cookiesQus.map((qus) => (
                        <li onClick={() => scrollToAnswer(qus.id)} className={`${cookie ? "text-[16px]" : "text-[11px] "} font-bold text-blue-600 text-justify cursor-pointer mt-2`}>{qus.question}</li>
                     ))}
                  </ul>
               </div>

               <div className="mt-4">
                  <h3 className={`${cookie ? "text-[20px]" : "text-[14px]"} font-bold`} id="1">1. What are cookies?</h3>
                  <p className={`${cookie ? "text-[16px]" : "text-[12px]"} text-justify`}>
                     Cookies are small text files sent by us to your computer or mobile
                     device, which enable CC features and functionality. They are
                     unique to your account or your browser. Session-based cookies last
                     as long as your browser is open and are automatically deleted when
                     you close your browser. Persistent cookies last until you or your
                     browser delete them or until they expire. These technologies help
                     us do things like remember you and your preferences when you
                     return to our site, measure how you use the website, conduct
                     market research, and collect information about and interact with
                     advertisements you see.
                  </p>
               </div>

               <div className="mt-4">
                  <h3 className={`${cookie ? "text-[20px]" : "text-[14px]"} font-bold`} id="2" >2. How we use cookies?</h3>
                  <p className={`${cookie ? "text-[16px]" : "text-[12px]"} text-justify`}>
                     Cookies serve a variety of functions, like enabling us to remember
                     certain information you provide to us as you navigate between
                     pages on the Platform. We use cookies for the following purposes:
                  </p>
                  <ol className="">
                     <li className={`${cookie ? "text-[16px]" : "text-[12px]"} text-justify mt-2`}>
                        <span className={`${cookie ? "text-[17px]" : "text-[12px]"} text-justify font-bold`}>
                           i. Authentication:
                        </span>
                        We need to use cookies to enable you to log into secure areas of
                        our Platform. In addition, we may employ third-party services
                        that may use cookies to help you sign into their services from
                        our Platform
                     </li>
                     <li className={`${cookie ? "text-[16px]" : "text-[12px]"} text-justify mt-2`}>
                        <span
                           className={`${cookie ? "text-[17px]" : "text-[12px]"} text-justify font-bold`}
                        >
                           ii. Services, features, and preferences:
                        </span>
                        We use cookies to provide functionality and help us deliver our
                        products and services according to your preferences in the
                        following ways.
                        <p>
                           a. To facilitate communications: We automatically receive
                           information about communications sent and received using our
                           Services.
                        </p>
                        <p>
                           b. To remember your settings and preferences: We use cookies
                           to recognize your browser and remember your preferences (such
                           as your preferred language).
                        </p>
                        <p>
                           c. To improve performance: We use cookies to provide the best
                           experience. For example, we use cookies to speed up the
                           loading of our Services.
                        </p>
                     </li>
                     <li className={`${cookie ? "text-[16px]" : "text-[12px]"} text-justify mt-2`}>
                        <span className={`${cookie ? "text-[17px]" : "text-[12px]"} text-justify font-bold`}>
                           iii. Security:
                        </span>
                        We use cookies to support or enable security features we have
                        deployed, and to help us detect malicious activity and
                        violations of our Terms of Service. These cookies help us
                        prevent fraudulent use of login credentials.
                     </li>
                     <li className={`${cookie ? "text-[16px]" : "text-[12px]"} text-justify mt-2`}>
                        <span className={`${cookie ? "text-[17px]" : "text-[12px]"} text-justify font-bold`}>
                           iv. Performance, Analytics and Research:
                        </span>
                        We use cookies to help us analyze how the Platform is being
                        accessed and used, and enable us to track performance of the
                        Platform. This helps us to understand, improve, and research
                        features and content on the Platform. We may also use other
                        third-party cookies and related technologies, to assist with
                        analyzing performance on our Platform. As part of providing
                        these services, these partners may use cookies and the
                        technologies described below to collect and store information
                        about your device, such as time of visit, pages visited, time
                        spent on each page of the Platform, links clicked and conversion
                        information, IP address, browser, mobile network information,
                        and type of operating system used.
                     </li>
                     <li className={`${cookie ? "text-[16px]" : "text-[12px]"} text-justify mt-2`}>
                        <span className={`${cookie ? "text-[17px]" : "text-[12px]"} text-justify font-bold`}>
                           v. Advertising:
                        </span>
                        We and our third-party advertising partners use cookies to
                        display ads to you on our Platform and on third party websites
                        or online services, to make those ads more relevant to your
                        interests, and to analyze the effectiveness of ad campaigns. Our
                        first party cookies record your visit to our Platform, the pages
                        you have visited, and the links you have followed, so that we
                        can share relevant ads about Chaa Ching  services and other products
                        with you on our Platform and on third party websites or online
                        services. Our advertising partners include advertisers,
                        advertising service providers and third-party publishers. They
                        may collect cookie and other information about your activity
                        across different websites, including our Platform, and across
                        your different devices to infer your interests, develop
                        personalized content and deliver ads that may be relevant to
                        you.
                     </li>
                  </ol>
               </div>

               <div className="mt-4">
                  <h3 className={`${cookie ? "text-[20px]" : "text-[14px]"} font-bold`} id="3" >3. Duration of cookies</h3>
                  <p className={`${cookie ? "text-[16px]" : "text-[12px]"} text-justify`}>
                     Some cookies, known as "session cookies", will stay on your device
                     only for as long as you are accessing our Platform. Others, known
                     as "persistent cookies", are stored on your device for longer
                     periods.
                  </p>
               </div>

               <div className="mt-4">
                  <h3 className={`${cookie ? "text-[20px]" : "text-[14px]"} font-bold`} id="4">
                     4. Use of other technologies
                  </h3>
                  <p className={`${cookie ? "text-[16px]" : "text-[12px]"} text-justify`}>
                     We may allow others to provide analytics services and serve
                     advertisements on our behalf. In addition to the uses of cookies
                     described above, these entities may use other methods, such as the
                     technologies described below, to collect information about your
                     use of the Platform and other websites and online services.
                  </p>
                  <ol className="">
                     <li className={`${cookie ? "text-[16px]" : "text-[12px]"} text-justify mt-1`}>
                        <span className={`${cookie ? "text-[17px]" : "text-[13px]"} text-justify font-bold`}>
                           i. Pixels tags:
                        </span>
                        Pixel tags (which are also called clear GIFs, web beacons, or
                        pixels), are small pieces of code that can be embedded on
                        websites and emails. Pixels tags may be used to learn how you
                        interact with our site pages and emails, and this information
                        helps us and our partners provide you with a more tailored
                        experience
                     </li>
                     <li className={`${cookie ? "text-[16px]" : "text-[12px]"} text-justify mt-1`}>
                        <span className={`${cookie ? "text-[17px]" : "text-[13px]"} text-justify font-bold`}>
                           ii. Device Identifiers:
                        </span>
                        A device identifier is a unique label can be used to identify a
                        mobile device. Device identifiers may be used to track, analyze
                        and improve the performance of the Platform and ads delivered
                     </li>
                     <li className={`${cookie ? "text-[16px]" : "text-[12px]"} text-justify mt-1`}>
                        <span className={`${cookie ? "text-[17px]" : "text-[13px]"} text-justify font-bold`}>
                           iii. Local storage:
                        </span>
                        Local storage is an industry-standard technology that enables
                        the storage and retrieval of data on a computer, mobile phone or
                        other device. Local storage may be used to store member
                        preferences on devices.
                     </li>
                     <li className={`${cookie ? "text-[16px]" : "text-[12px]"} text-justify mt-1`}>
                        <span className={`${cookie ? "text-[17px]" : "text-[13px]"} text-justify font-bold`}>
                           iv. Cookies:
                        </span>
                        A cookie is a small file placed onto your device that enables CC
                        features and functionality. Any browser visiting our sites may
                        receive cookies from us or cookies from third parties such as
                        our customers, partners or service providers. We or third
                        parties may also place cookies in your browser when you visit
                        non-CC sites that display ads or that host our plugins or tags.
                     </li>
                     <li className={`${cookie ? "text-[16px]" : "text-[12px]"} text-justify mt-1`}>
                        We use two types of cookies: persistent cookies and session
                        cookies. A persistent cookie lasts beyond the current session
                        and is used for many purposes, such as recognizing you as an
                        existing user, so it’s easier to return to CC and interact with
                        our Services without signing in again. Since a persistent cookie
                        stays in your browser, it will be read by CC when you return to
                        one of our sites or visit a third-party site that uses our
                        Services. Session cookies last only as long as the session
                        (usually the current visit to a website or a browser session)
                     </li>
                  </ol>
               </div>

               <div className="mt-4">
                  <h3 className={`${cookie ? "text-[20px]" : "text-[14px]"} font-bold`} id="5" >5. Cookies preferences</h3>
                  <p className={`${cookie ? "text-[16px]" : "text-[12px]"} text-justify`}>
                     When you visit any website, it may store or retrieve information
                     on your browser, mostly in the form of cookies. This information
                     might be about you, your preferences or your device and is mostly
                     used to make the site work as you expect it to. The information
                     does not usually directly identify you, but it can give you a more
                     personalized web experience
                  </p>
                  <ol className="">
                     <li className={`${cookie ? "text-[16px]" : "text-[12px]"} text-justify mt-1`}>
                        <p className={`${cookie ? "text-[17px]" : "text-[12px]"} text-justify font-bold`}>
                           A. Strictly Necessary Cookies / Always Active Cookies
                        </p>
                        These cookies are necessary for the website to function and
                        cannot be switched off in our systems. They are usually only set
                        in response to actions made by you which amount to a request for
                        services, such as setting your privacy preferences, logging in
                        or filling in forms. You can set your browser to block or alert
                        you about these cookies, but some parts of the site will not
                        then work. These cookies do not store any personally
                        identifiable information.
                     </li>
                     <li className={`${cookie ? "text-[16px]" : "text-[12px]"} text-justify mt-1`}>
                        <p className={`${cookie ? "text-[17px]" : "text-[12px]"} text-justify font-bold`}>
                           B. Performance Cookies:
                        </p>
                        These cookies allow us to count visits and traffic sources so we
                        can measure and improve the performance of our site. They help
                        us to know which pages are the most and least popular and see
                        how visitors move around the site. All information these cookies
                        collect is aggregated and therefore anonymous. If you do not
                        allow these cookies we will not know when you have visited our
                        site, and will not be able to monitor its performance
                     </li>
                     <li className={`${cookie ? "text-[16px]" : "text-[12px]"} text-justify mt-1`}>
                        <p className={`${cookie ? "text-[17px]" : "text-[12px]"} text-justify font-bold`}>
                           C. Functional Cookies:
                        </p>
                        These cookies enable the website to provide enhanced
                        functionality and personalization. They may be set by us or by
                        third party providers whose services we have added to our pages.
                        If you do not allow these cookies then some or all of these
                        services may not function properly.
                     </li>
                     <li className={`${cookie ? "text-[16px]" : "text-[12px]"} text-justify mt-1`}>
                        <p className={`${cookie ? "text-[17px]" : "text-[12px]"} text-justify font-bold`}>
                           D. Advertisement and Targeting Cookies:
                        </p>
                        These cookies may be set through our site by our advertising
                        partners. They may be used by those companies to build a profile
                        of your interests and show you relevant adverts on other sites.
                        They do not store directly personal information, but are based
                        on uniquely identifying your browser and internet device. If you
                        do not allow these cookies, you will experience less targeted
                        advertising.
                     </li>
                  </ol>
               </div>

               <div className="mt-4">
                  <h3 className={`${cookie ? "text-[20px]" : "text-[14px]"} font-bold`} id="6" >
                     6. What are these technologies used for?
                  </h3>
                  <p className={`${cookie ? "text-[16px]" : "text-[12px]"} text-justify`}>
                     Below we describe the purposes for which we use these technologies
                  </p>
                  <div className="grid grid-cols-12 gap-2 mt-2">
                     {technologies.map((item) => {
                        return (
                           <>
                              <div className={`col-span-4 border-r-2 border-blue-600 ${cookie ? " text-[14px]" : "text-[12px]"} text-center flex justify-center items-center`}>
                                 {item.qus}
                              </div>
                              <div className={`col-span-8 ${cookie ? "text-[14px]" : "text-[12px]"}  flex justify-start pl-1 text-justify `}>
                                 {item.ans}
                              </div>
                           </>
                        );
                     })}
                  </div>
               </div>

               <div className="mt-4">
                  <h3 className={`${cookie ? "text-[20px]" : "text-[14px]"} font-bold `} id="7">
                     7. What third parties use these technologies in connection with
                     our Services?
                  </h3>
                  <p className={`${cookie ? "text-[16px]" : "text-[12px]"} text-justify`}>
                     Third parties such as our customers, partners and service
                     providers may use cookies in connection with our Services. For
                     example, third parties may use cookies in their CC pages, job
                     posts and their advertisements on and off CC for their own
                     marketing purposes. For an illustration, please visit CC’s Help
                     and Support.
                  </p>
                  <ol className="">
                     <li className={`${cookie ? "text-[16px]" : "text-[12px]"} text-justify mt-2`}>
                        <p className={`${cookie ? "text-[17px]" : "text-[12px]"} text-justify font-bold`}>
                           a. Browser cookie controls:
                        </p>
                        In addition, your browser or device may offer settings that
                        allow you to choose whether browser cookies are set and to
                        delete them. These controls vary by browser, and manufacturers
                        may change both the settings that they make available and how
                        they work at any time. Please be aware that these controls are
                        distinct from the controls that we offer you.
                        <div>
                           <p>· Google Chrome </p>
                           <p>· Internet Explorer</p>
                           <p>· Firefox</p>
                           <p>· Safari</p>
                           <p>· Safari Mobile </p>
                           <p>· Opera</p>
                        </div>
                     </li>
                     <li className={`${cookie ? "text-[16px]" : "text-[12px]"} text-justify mt-2`}>
                        <p className={`${cookie ? "text-[17px]" : "text-[13px]"} text-justify font-bold`}>
                           b. More information
                        </p>
                        To find out more about cookies, including how to see what
                        cookies have been set and how to manage and delete them, visit
                        About Cookies or www.allaboutcookies.org
                     </li>
                  </ol>
               </div>

               <div className="mt-4">
                  <h3 className={`${cookie ? "text-[20px]" : "text-[14px]"} font-bold`} id="10">
                     10. Revisions to this policy
                  </h3>
                  <p className={`${cookie ? "text-[16px]" : "text-[12px]"} text-justify`}>
                     We may modify this Cookie Policy from time to time. When we do, we
                     will provide notice to you by publishing the most current version
                     and revising the date at the top of this page. Depending on the
                     circumstances, we may provide additional notice to you of changes
                     to this Cookie Policy, such as by sending you an email or
                     displaying a prominent notice on our Platform. By continuing to
                     use the Platform after any changes come into effect, you agree to
                     the revised Cookie Policy
                  </p>
               </div>
               <div className="my-4">
                  <h3 className={`${cookie ? "text-[20px]" : "text-[14px]"} font-bold`} id="11" >
                     11. How to contact us?
                  </h3>
                  <p className={`${cookie ? "text-[16px]" : "text-[12px]"} text-justify`}>
                     If you have any other questions or comments about our Cookie
                     Policy, please contact us at
                  </p>
                  <p className={`${cookie ? "text-[16px]" : "text-[12px]"} text-justify`}>xxxxxxxxxxxxxxxxxxxxxxx</p>
               </div>
            </div>
         </div>
   );
}