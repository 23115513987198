import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
// import BoyImage from "../../Assets/LoginPageIcon/boyimage.svg";
import { useDispatch } from "react-redux";
import { AccountProfile } from "../../Redux/ApiSlice/AccountProfileSlice";
import { HomepageVideo } from "../../Redux/ApiSlice/HomePageVideoSlice";
// import Footer from "../../Component/Footer/Footer";
// import LoginHeader from "../../Component/Login/LoginHeader";
import SecretSauceButton from "../../Components/MobileVersion/SecretSauceButton/SecretSauceButton";
import { Typography } from "@material-tailwind/react";
import { REACT_APP_MAIN_URL } from "../../API/MainUrl";
import { HiEyeSlash, HiMiniEye } from "react-icons/hi2";
import { ccLogo } from "../../Datas/Assests";

const LoginPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [keepMeLogin, setKeepMeLogin] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  /* formik section */
  const loginSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email Required"),
    password: Yup.string().required("Please Enter your password"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: loginSchema,
    onSubmit: (values) => {
      axios({
        method: "POST",
        url: `${REACT_APP_MAIN_URL}api/login/`,

        data: values,
        headers: { "Content-Type": "application/json" },
      })
        .then(function (response) {
          const { data, status } = response;
          if (status === 200) {
            if (keepMeLogin) {
              localStorage.setItem("email", values.email);
              localStorage.setItem("password", values.password);
              localStorage.setItem("keepMeLogin", keepMeLogin);
            } else {
              localStorage.removeItem("email");
              localStorage.removeItem("password");
              localStorage.setItem("keepMeLogin", keepMeLogin);
            }

            localStorage.setItem("accessToken", data?.access);
            localStorage.setItem("new_User", true);
            toast.success("Successfully login ");
            dispatch(HomepageVideo());
            dispatch(AccountProfile());
            setTimeout(() => {
              navigate("/home");
            }, 1000);
          }
        })
        .catch(function (error) {
          toast.error(error.response?.data.detail);
        });
    },
  });

  const handleClick = (value) => {
    navigate(`/${value}`);
  };
  const email = localStorage.getItem("email");
  const password = localStorage.getItem("password");

  const handleChangeKeepLogin = () => {
    setKeepMeLogin(!keepMeLogin);
  };

  useEffect(() => {
    if (email && password) {
      formik.setValues({
        email,
        password,
      });
      setKeepMeLogin(!keepMeLogin);
    }
  }, []);

  return (
    <Fragment>
      <Toaster position="top-right" />
      {/* important code do not remove */}
      {/* <div className="hidden sm:hidden md:block overflow-hidden bg-[#74cbfc] h-[100vh]">
        <p className="absolute uppercase text-[#ff3b3b] right-5 font-semibold">
          CC Login V2 / FE / 16 FEB 2024
        </p>
        <LoginHeader />

        <div className="flex justify-center items-center mt-8">
          <div className="w-[45%] h-[80%] bg-[#fff] rounded-2xl relative flex justify-center items-center shadow-2xl">
            <img
              src={BoyImage}
              alt="img"
              className="absolute h-[20rem] -left-[6rem] top-[12rem] z-0"
            />
            <div className="p-4 w-[70%]">
              <p className="text-center text-[1.8rem] font-bold mt-2 tracking-wide">
                Watch, Enjoy, Connect
              </p>
              <p className="text-center text-[0.9rem] font-semibold mb-3">
                A whole new journey starts right here. Get ready to explore!
              </p>
              <form className="px-14" onSubmit={formik.handleSubmit}>
                <div className="flex flex-col mb-3">
                  <lable>Email</lable>
                  <input
                    required
                    type="email"
                    name="email"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    onBlur={formik.handleBlur}
                    placeholder="Enter your email address"
                    className="border-[1px] border-black px-3 py-2 focus:outline-none rounded-md"
                  />

                  {formik.touched.email && formik.errors.email && (
                    <p className="text-[#ff3b3b] md:text-[15px] mt-1 font-medium">
                      {formik.errors.email}
                    </p>
                  )}
                </div>
                <div className="flex flex-col">
                  <lable>Password</lable>
                  <input
                    required
                    type="password"
                    name="password"
                    onChange={formik.handleChange}
                    value={formik.values.password}
                    onBlur={formik.handleBlur}
                    placeholder="Enter your password"
                    className="border-[1px] border-black px-3 py-2 focus:outline-none rounded-md"
                  />
                  {formik.touched.password && formik.errors.password && (
                    <p className="text-[#ff3b3b] md:text-[15px] mt-1 font-medium">
                      {formik.errors.password}
                    </p>
                  )}
                </div>
                <div className="flex justify-between items-center gap-2 mt-3 mb-5">
                  <div className="flex items-center gap-2">
                    <input
                      type="checkbox"
                      className="h-4 w-4 cursor-pointer"
                      checked={keepMeLogin}
                      onChange={handleChangeKeepLogin}
                    />
                    <p className="font-medium">Keep me logged in</p>
                  </div>
                  <p
                    className="font-medium cursor-pointer text-[#00A3FA]"
                    onClick={() => navigate("/resetpassword")}
                  >
                    I've lost my password
                  </p>
                </div>
                <div className="flex justify-center mb-2">
                  <button
                    type="submit"
                    className="bg-[#00A3FA] cursor-pointer w-[85%] py-2 text-[#fff] rounded-md shadow-lg"
                  >
                    Login
                  </button>
                </div>
                <div className="flex justify-center gap-4 mb-10">
                  <p>Trouble Logging In?</p>
                  <p
                    className="font-medium cursor-pointer text-[#00A3FA]"
                    onClick={() => navigate("/help-web")}
                  >
                    Get help
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
        <Footer />
      </div> */}
      <div className="h-full w-full relative md:overflow-hidden">
        <div className="h-full w-full">
          <div className="grid grid-cols-12 h-[100%] overflow-y-scroll md:overflow-hidden">
            <div className="col-span-12 flex justify-center items-center bg-[white] flex-col">
              <div className="flex justify-center font-semibold text-sm  bg-[#fff] items-center ">
                {/* <p className="text-[#ff3b3b] font-extrabold text-[16px] md:text-[12px]  text-center w-full">
                  CC LOGIN - FE - V6 - DECEMBER 13, 2024
                </p> */}
                <p className="text-[#ff3b3b] font-extrabold text-[16px] md:text-[12px]  text-center w-full">
                  Copyright © 2024 Galileo One Inc
                </p>
              </div>
              <img src={ccLogo} className="h-28 w-28" alt="logo" />

              {/* <h3 className=" font-extrabold text-md text-center">
                Watch Enjoy Connect
              </h3> */}
              <p className=" text-lg md:text-[16px] font-bold text-center w-full px-[5%] md:pb-2 pt-1 ">
                A whole new journey starts right here.
              </p>
              <p className="text-[24px] font-bold w-full text-center">
                Chaa Chingg
              </p>

            </div>
            <div className="col-span-12 w-full px-5 bg-[#fff] my-4">
              <form onSubmit={formik.handleSubmit}>
                <Typography variant="h6" color="blue-gray" className="mb-1 text-[12px]">
                  Your Email
                </Typography>
                <input
                  required
                  type="email"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  placeholder="Enter your email address"
                  className="w-full border-[1px] border-gray-700 focus:outline-none p-2 rounded"
                />
                <Typography
                  variant="h6"
                  color="blue-gray"
                  className="mb-1 mt-3 text-[12px]"
                >
                  Password
                </Typography>
                <div className="relative">
                  <input
                    required
                    type={showPassword ? "text" : "password"}
                    name="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    placeholder="Enter your password"
                    className="w-full border-[1px] border-gray-700 focus:outline-none p-2 pr-8 rounded"
                  />
                  <p className="absolute top-3 z-50 right-2 cursor-pointer">
                    {
                      showPassword ? <HiEyeSlash className="text-gray-500" onClick={() => setShowPassword(!showPassword)} /> : <HiMiniEye className="text-gray-500" onClick={() => setShowPassword(!showPassword)} />
                    }

                  </p>
                </div>
                <div className="flex justify-between items-center">
                  <div className="flex items-center gap-2 my-3 text-sm font-normal">
                    <div class="inline-flex items-center">
                      <label
                        htmlFor="green"
                        class="relative flex items-center rounded-full cursor-pointer"
                      >
                        <input
                          id="green"
                          type="checkbox"
                          checked={keepMeLogin}
                          onChange={handleChangeKeepLogin}
                          class="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-green-500 checked:bg-green-500 checked:before:bg-green-500 hover:before:opacity-10"
                        />
                        <span class="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-3.5 w-3.5"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            stroke="currentColor"
                            stroke-width="1"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                              clip-rule="evenodd"
                            ></path>
                          </svg>
                        </span>
                      </label>
                    </div>
                    <p>Keep me logged in</p>
                  </div>
                  <p
                    onClick={() => handleClick("resetpassword")}
                    className="text-[14px] font-bold mt-3 mb-1 text-end text-[#00a3ff] cursor-pointer "
                  >
                    Lost Password
                  </p>
                </div>
                <div className="w-full flex justify-center items-center flex-col mt-4">
                  <button className="bg-black text-white disabled:text-[black] px-6 p-2 md:p-1 md:px-4 rounded-lg font-bold">
                    Login
                  </button>
                  <p className="text-[12px] flex justify-start font-semibold mt-3">
                    Trouble logging in?
                    <span
                      onClick={() => handleClick("help")}
                      className="underline text-[#00a3ff] cursor-pointer pl-2"
                    >
                      Get help
                    </span>
                  </p>
                  <p
                    className="text-[#00a3ff] text-[16px]  font-semibold mt-4 cursor-pointer hover:text-red-500"
                    onClick={() => handleClick("signup")}
                  >
                    Create New Chaa Chingg Account
                  </p>
                </div>
              </form>
            </div>
          </div>
          {/* <SecretSauceButton /> */}
          {/* <div className="h-[8%] md:h-[7%] bg-black">
            <div className="flex items-center justify-center h-full">
              <button
                className="rounded-[2rem] px-4 py-1 bg-[#D9D9DE] font-bold text-base"
                onClick={() => handleClick("register")}
              >
                Register
              </button>
            </div>
          </div> */}
        </div>
      </div>
    </Fragment>
  );
};
export default LoginPage;
