import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { HiDotsVertical } from "react-icons/hi";
import { IoArrowBackCircle } from "react-icons/io5";
import { FaImages } from "react-icons/fa6";
import { MdGifBox } from "react-icons/md";
import { IoSend } from "react-icons/io5";
import { BiSolidVolumeMute } from "react-icons/bi";
import { FaUserPlus } from "react-icons/fa";

const CreatedGroupChatRoom = () => {
   const navigate = useNavigate();
   const [isShowMenu, setIsShowMenu] = useState(false);
   const menuRef = useRef(null);

   const demoChats = [
      {
         id: 1,
         userName: "Michael Smith",
         time: "3:02 PM",
         isSender: false,
         message: "What's everyone's favorite Ferrari model? Let's discuss!",
         userImg: "https://images.unsplash.com/photo-1522556189639-b150ed9c4330?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
      },
      {
         id: 2,
         userName: "You",
         time: "3:10 PM",
         isSender: true,
         message: "Hey everyone! I'm new here. I've always dreamed of owning a Ferrari. What model do you recommend for a first-time Ferrari owner?",
         userImg: "https://images.unsplash.com/photo-1639149888905-fb39731f2e6c?q=80&w=1964&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
      },
      {
         id: 3,
         userName: "Ashley Williams",
         time: "3:11 PM",
         isSender: false,
         message: "The Testarossa has my heart! That iconic design and those side strakes are unbeatable.",
         userImg: "https://images.unsplash.com/photo-1556157382-97eda2d62296?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
      },
      {
         id: 4,
         userName: "You",
         time: "3:12 PM",
         isSender: true,
         message: "The Testarossa sounds amazing! I love that classic 80s style. Any particular year or edition you recommend?",
         userImg: "https://images.unsplash.com/photo-1639149888905-fb39731f2e6c?q=80&w=1964&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
      },
      {
         id: 5,
         userName: "You",
         time: "3:15 PM",
         isSender: true,
         message: "Those are all iconic choices! I've always had a soft spot for the Dino series. Such beautiful cars.",
         userImg: "https://images.unsplash.com/photo-1639149888905-fb39731f2e6c?q=80&w=1964&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
      },
      {
         id: 6,
         userName: "Christopher Davis",
         time: "3:20 PM",
         isSender: false,
         message: "The Dino is a classic choice! Ferrari really knows how to make timeless designs.",
         userImg: "https://images.unsplash.com/photo-1492562080023-ab3db95bfbce?q=80&w=2048&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
      },
      {
         id: 7,
         userName: "You",
         time: "3:25 PM",
         isSender: true,
         message: "Absolutely! The Dino 246 GT is a masterpiece. Ferrari's design language is unparalleled.",
         userImg: "https://images.unsplash.com/photo-1639149888905-fb39731f2e6c?q=80&w=1964&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
      },
      {
         id: 8,
         userName: "Sarah Brown",
         time: "3:30 PM",
         isSender: false,
         message: "Couldn't agree more! Ferrari's blend of performance and beauty is unmatched.",
         userImg: "https://images.unsplash.com/photo-1544005313-94ddf0286df2?q=80&w=1888&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
      },
      {
         id: 9,
         userName: "You",
         time: "3:35 PM",
         isSender: true,
         message: "Absolutely, Ferrari's dedication to excellence shines through in every model.",
         userImg: "https://images.unsplash.com/photo-1639149888905-fb39731f2e6c?q=80&w=1964&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
      },
      {
         id: 10,
         userName: "Christopher Davis",
         time: "3:40 PM",
         isSender: false,
         message: "Indeed! Ferrari's legacy is built on passion and innovation.",
         userImg: "https://images.unsplash.com/photo-1492562080023-ab3db95bfbce?q=80&w=2048&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
      },
      {
         id: 11,
         userName: "You",
         time: "3:45 PM",
         isSender: true,
         message: "Absolutely! The passion for excellence is what sets Ferrari apart from the rest.",
         userImg: "https://images.unsplash.com/photo-1639149888905-fb39731f2e6c?q=80&w=1964&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
      },
      {
         id: 12,
         userName: "Sarah Brown",
         time: "3:50 PM",
         isSender: false,
         message: "Absolutely, it's the passion that makes owning a Ferrari more than just having a car.",
         userImg: "https://images.unsplash.com/photo-1544005313-94ddf0286df2?q=80&w=1888&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
      },
      {
         id: 13,
         userName: "You",
         time: "3:55 PM",
         isSender: true,
         message: "Exactly! It's an emotional connection, a piece of automotive art.",
         userImg: "https://images.unsplash.com/photo-1639149888905-fb39731f2e6c?q=80&w=1964&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
      },
      {
         id: 14,
         userName: "Christopher Davis",
         time: "4:00 PM",
         isSender: false,
         message: "Absolutely, it's a lifestyle. Once you drive a Ferrari, nothing else compares.",
         userImg: "https://images.unsplash.com/photo-1492562080023-ab3db95bfbce?q=80&w=2048&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
      },
      {
         id: 15,
         userName: "You",
         time: "4:05 PM",
         isSender: true,
         message: "Absolutely! It's a feeling you can't put into words.",
         userImg: "https://images.unsplash.com/photo-1639149888905-fb39731f2e6c?q=80&w=1964&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
      }
   ];

   useEffect(() => {
      const handleClickOutside = (event) => {
         if (menuRef.current && !menuRef.current.contains(event.target)) {
            setIsShowMenu(false);
         }
      };

      document.addEventListener("mousedown", handleClickOutside);

      return () => {
         document.removeEventListener("mousedown", handleClickOutside);
      };
   }, []);

   return (
      <div className="bg-black h-full pb-10 overflow-y-scroll">
         <div className="sticky top-0 bg-black z-10 ">
            <div className="grid grid-cols-12 px-2 pt-1 items-center">
               <div className="col-span-12 pb-[2px]">
                  <p className="text-[#ff3b3b] text-center font-bold text-[12px] md:text-[8px]">
                  Copyright © 2024 Galileo One Inc
                  </p>
                  {/* <p className="text-[#ff3b3b] text-center font-bold text-[12px] md:text-[8px]">
                     CC GROUP OWNER CHATROOM - FE - V2 - JUNE 25, 2024
                  </p> */}
               </div>
               <div className="col-span-2">
                  <IoArrowBackCircle 
                     className="text-white text-[30px] md:text-[24px] cursor-pointer" 
                     onClick={() => navigate(-1)}   
                     />
               </div>
               <div className="col-span-8 text-center">
                  <p className="text-white text-[18px] md:text-[14px]">Ferrari Group Chatroom</p>
               </div>
               <div className="col-span-2 flex justify-end">
                  <HiDotsVertical 
                     className="text-white text-[30px] md:text-[24px] cursor-pointer" 
                     onClick={() => setIsShowMenu(true)}
                  />
               </div>
            </div>

            <hr className="w-full mt-[6px] my-[6px] h-[0.5px] bg-gray-800 border-0" />
         </div>

         {isShowMenu &&
            <div ref={menuRef} className="bg-[#ffffff] py-1 w-[4.5rem] absolute right-2 top-11 rounded-md z-50">
               <div className="px-2 flex justify-between items-center border-b-[0.5px] border-gray-700">
                  <p className="text-black text-[13px] py-[2px] cursor-pointer">Mute</p>
                  <BiSolidVolumeMute className="cursor-pointer text-[15px]" />
               </div>
               <div className="px-2 flex justify-between items-center">
                  <p className="text-black text-[13px] py-[2px]">Invite</p>
                  <FaUserPlus className="cursor-pointer text-[15px]" />
               </div>
            </div>
         }

         {demoChats.map((item) => (
            <div className="px-2" key={item.id}>
               {item.isSender ?
                  <div className="grid grid-cols-12 pb-[8px] justify-end">
                     <div className="col-span-12">
                        <div className="flex items-center gap-[5px] justify-end">
                           <p className="text-[#3ea8d8] text-[12px] md:text-[9px]">{item.time}</p>
                           <p className="text-white text-[16px] md:text-[14px]">{item.userName}</p>
                           <img
                              alt={item.userName}
                              src={item.userImg}
                              className="h-6 w-6 md:h-5 md:w-5 object-cover rounded-full"
                           />
                        </div>
                     </div>
                     <div className="col-span-3"></div>
                     <div className="col-span-9 flex justify-end mt-[3px]">
                        <p className="text-[14px] md:text-[12px] text-white bg-[#343333] leading-[14px] p-[5px] rounded-md">
                           {item.message}
                        </p>
                     </div>
                  </div>
                  :
                  <div className="grid grid-cols-12 pb-[8px]">
                     <div className="col-span-1">
                        <img
                           alt={item.userName}
                           src={item.userImg}
                           className="h-6 w-6 md:h-5 md:w-5 object-cover rounded-full"
                        />
                     </div>
                     <div className="col-span-8">
                        <div className="flex items-center gap-2">
                           <p className="text-white text-[16px] md:text-[14px]">{item.userName}</p>
                           <p className="text-[#3ea8d8] text-[12px] md:text-[9px]">{item.time}</p>
                        </div>
                        <div className="flex mt-[1px]">
                           <p className="text-[14px] md:text-[12px] text-white bg-[#343333] leading-[14px] p-[5px] rounded-md">
                              {item.message}
                           </p>
                        </div>
                     </div>
                  </div>
               }
            </div>
         ))}

         <div className="absolute bottom-0 bg-black w-full">
            <div className="flex justify-between items-center gap-1 py-1 px-1">
               <FaImages className="text-white text-[45px] md:text-[30px] cursor-pointer" />
               <MdGifBox className="text-white text-[45px] md:text-[30px] cursor-pointer" />
               <input
                  type="text"
                  placeholder="Message"
                  className="px-2 py-1 w-full rounded-md focus:outline-none bg-white"
               />
               <IoSend className="text-white text-[45px] md:text-[20px] cursor-pointer" />
            </div>
         </div>
      </div>
   );
};

export default CreatedGroupChatRoom;
