import React from "react";
import TitleSection from "../../Component/Help/TitleSection";
import { IoArrowBackCircle } from "react-icons/io5";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
  Card,
  CardBody,
  CardFooter,
  Typography,
  Button,
  Checkbox,
} from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { helpPageData } from "../../Datas/Datas";
import image1 from "../../Assets/HelpPage/questions 1.svg";
import contentMarketing from "../../Assets/HelpPage/content-marketing 1.svg";

export default function Questions({ isFullScreen }) {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(0);
  const { id } = useParams();
  const handleOpen = (value) => setOpen(open === value ? 0 : value);
  function Icon({ id, open }) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={2}
        stroke="currentColor"
        className={`${
          id === open ? "rotate-180" : ""
        } h-5 w-5 transition-transform`}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M19.5 8.25l-7.5 7.5-7.5-7.5"
        />
      </svg>
    );
  }
  const selectedHelp = helpPageData.find((data) => data.id === Number(id));
  return (
    <>
      <div
        className={`sticky top-0 z-50 bg-white  ${
          isFullScreen ? "px-2  md:px-28" : "px-2"
        } pt-1`}
      >
        {/* <p className="text-[red] text-[8px] uppercase w-full text-center font-bold">
          CC HELP AND SUPPORT Questions PAGE / FE / V3 / JULY 10, 2024
        </p> */}
        <p className="text-[red] text-[8px] uppercase w-full text-center font-bold">
        Copyright © 2024 Galileo One Inc
        </p>
        <div>
          <IoArrowBackCircle
            onClick={() => navigate(-1)}
            className="text-[1.5rem] cursor-pointer"
          />
        </div>
        <div className="flex flex-col justify-center items-center">
          <TitleSection isFullScreen={isFullScreen} />
        </div>
        <h2
          className={`text-[#00A3FF] ${
            isFullScreen ? "text-[2rem]" : "text-[14px]"
          } w-full text-center font-bold my-2`}
        >
          {selectedHelp.title}
        </h2>
      </div>
      <div className={`${isFullScreen ? "px-3 md:px-28 py-10" : "px-3 py-4"}`}>
        <div>
          <>
            {selectedHelp.data?.map((data) => (
              <div className="border border-gray-500 rounded-[10px] mt-2">
                <Accordion
                  open={open === data.id}
                  icon={<Icon id={data.id} open={open} className="p-0" />}
                >
                  <AccordionHeader
                    onClick={() => handleOpen(data.id)}
                    className="text-[12px] px-2 border-none"
                  >
                    {data.qus}
                  </AccordionHeader>
                  <AccordionBody className="border px-2 text-[12px]">
                    {data.ans.slice(0, 50)}
                    {data.ans.length > 50 && (
                      <span
                        onClick={() =>
                          navigate(
                            isFullScreen
                              ? "/help/readmore-answer"
                              : "/readmore-answer"
                          )
                        }
                        className="text-[#00A3FF] text-[10px] cursor-pointer"
                      >
                        ...Read more
                      </span>
                    )}
                  </AccordionBody>
                </Accordion>
              </div>
            ))}
          </>
        </div>
        <div
          className={`grid grid-cols-12 mt-4 ${
            isFullScreen ? "md:gap-12 gap-y-10" : "gap-y-10"
          }   px-3`}
        >
          <div
            className={`${
              isFullScreen ? "col-span-12 md:col-span-6 md:h-52" : "col-span-12"
            }  mt-2 flex flex-col justify-center items-center w-full`}
          >
            <Card className="rounded-lg p-2 w-[100%] h-full shadow-[8px_1px_10px_12px_#4299e1]">
              <CardBody className="p-2 flex flex-col justify-center items-center">
                <Typography
                  color="blue-gray"
                  className="mb-1 text-[12px] font-bold"
                >
                  <img src={image1} className="h-12 w-12" alt="" />
                </Typography>
                <Typography
                  color="blue-gray"
                  className={`mb-1 ${
                    isFullScreen ? "text-[14px]" : "text-[10px]"
                  } text-center `}
                >
                  Do you have more questions or any tips to share? Please send
                  us your comments or report any problems you experienced. We
                  read all feedback and address them as soon as possible.
                </Typography>
              </CardBody>
              <CardFooter className="flex justify-center items-center gap-4 md:p-1">
                <Button
                  className={`${
                    isFullScreen ? "px-4 py-2" : "py-1 px-2"
                  } rounded-full text-[8px] bg-[#00A3FF] text-[#fff]`}
                >
                  Give Feedback
                </Button>
              </CardFooter>
            </Card>
          </div>

          <div
            className={`${
              isFullScreen
                ? "col-span-12 md:col-span-6 md:h-52 "
                : "col-span-12"
            }  mt-2 flex flex-col justify-center items-center w-full`}
          >
            <Card className="rounded-lg p-2 w-full h-full shadow-[8px_1px_10px_12px_#4299e1]">
              <CardBody className="p-2 flex flex-col justify-center items-center">
                <Typography
                  color="blue-gray"
                  className="mb-1 text-[12px] font-bold"
                >
                  <img src={contentMarketing} className="h-12 w-12" alt="" />
                </Typography>
                <Typography
                  color="blue-gray"
                  className={`mb-1 ${
                    isFullScreen ? "text-[14px]" : "text-[10px]"
                  } text-center `}
                >
                  Want to receive a monthly email in your inbox with awesome
                  tips on how to use CC resources and more?
                </Typography>

                <Typography>
                  <Checkbox
                    color="blue"
                    label={
                      <Typography
                        color="blue-gray"
                        className={`mb-1 ${
                          isFullScreen ? "text-[14px]" : "text-[10px]"
                        } `}
                      >
                        It's <span className={`text-[#00A3FF]`}>100% free</span>{" "}
                        and we will never send more than one email per month
                      </Typography>
                    }
                  />
                </Typography>
              </CardBody>
              <CardFooter className="flex justify-center items-center gap-4 md:p-1">
                <div className="col-span-8 grid grid-cols-6 relative justify-end items-center h-full rounded-full border-gray-400 bg-white shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px]">
                  <input
                    placeholder="Enter your email"
                    className={`h-full rounded-full outline-none ${
                      isFullScreen
                        ? "py-2 px-4 placeholder:text-[10px]"
                        : "p-2 placeholder:text-[8px]"
                    } p-2 col-span-4 px-3 text-[12px] `}
                  />
                  <Button className="bg-[#00A3FF] rounded-full text-[10px] px-2 py-1 col-span-2 mr-1">
                    Subscribe
                  </Button>
                </div>
              </CardFooter>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
}
