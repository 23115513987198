import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AccountTab from "../../../Component/AccountPage/AccountTab";
import EmptyPrivate from "../../../Component/AccountPage/PrivateTab/EmptyPrivate";
import AccountProfileSection from "../../../Component/AccountPage/AccountProfileSection";

export default function PrivateEmpty() {
   const navigate = useNavigate();

   useEffect(() => {
      if (localStorage.getItem("Privatepassword")) {
         navigate("/account/private");
      }
   }, []);

   return (
      <div className="bg-[#000] overflow-hidden h-full relative ">
         {/* <p className="text-[#ff3b3b] text-center w-full mb-2  text-[12px] font-[700] flex justify-center z-50 lg:text-[10px] uppercase">
            CC ACCOUNT PRIVATE PAGE-FE - V11 / JULY 01, 2024
         </p> */}
         <p className="text-[#ff3b3b] text-center w-full mb-2  text-[12px] font-[700] flex justify-center z-50 lg:text-[10px] uppercase">
         Copyright © 2024 Galileo One Inc
         </p>
         <div className="">
            <AccountProfileSection />
         </div>
         <AccountTab activeTab={"Private"} />
         <div className="overflow-y-scroll no-scrollbar absolute w-full h-[-webkit-fill-available]">
            <EmptyPrivate />
         </div>
      </div>
   );
}
