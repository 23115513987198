import React, { useEffect, useRef, useState } from "react";
import { IoArrowBackCircle } from "react-icons/io5";
import TitleSection from "../../Component/Help/TitleSection";
import {
  Card,
  CardBody,
  CardFooter,
  Typography,
  Button,
} from "@material-tailwind/react";
import { BiSolidDislike, BiSolidLike } from "react-icons/bi";
import InputBoxSection from "../../Component/Help/InputBoxSection";
import AddReviewCard from "./AddReviewCard";
import { useNavigate } from "react-router-dom";

export default function ReadMoreAnswers({ isFullScreen }) {
  const navigate = useNavigate();
  const [showReviewModal, SetReviewModal] = useState(false);
  const handleShowModal = () => SetReviewModal(!showReviewModal);
  const menuRef = useRef(null);

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      SetReviewModal(null); // Close menu if clicked outside
    }
  };

  const questionsData = [
    {
      id: 1,
      question:
        "My email address is not recognized when I try to reset my password?",
      answer: [
        "If your email address is not recognized when trying to send the password reset email, it’s possible you may have used a different email address to register your account.",
        "If you’re certain that you’re entering the correct email,it could be that the email address entered may have had a typo or a variant spelling. In this case, you’ll need to get in touch with us to answer a few security questions in order for us to locate your CC account.",
        "Please contact our Support team and we’ll be happy to help you with this process as quickly as possible Please have the details of your most recent account detail before contacting us.",
        " When our system detects suspicious or inconsistent behavior, there are a few ways our system will respond, including sending an alert notification to confirm if a login was authorized by you. For example, if our system detects a login from a new device, or a login in a new region, CC will send you an alert notification to let you know. Email is a process we're working on.It's complicated, and traverses many systems.",
        "Occasionally our emails get blocked by email providers even before they go to yourspam folders, so you may need to try these steps several times over a few days: When our system detects suspicious or inconsistent behavior, there are a few ways our system will respond, including sending an alert notification to confirm if a login was authorized by you. For example, if our system detects a login from a new device, or a login in a new region, CC will send you an alert notification to let you know. If you have lost access to the email listed on your account, please head to our Contact Page",
        "   On this page, submit your ticket by selecting Account/Login Issues , Logging In on the Category , Sub Category drop-down menus. Please be sure to provide us with the email address listed  on your account, as well as the necessary information we ask to help us verify your account details.",
      ],
    },
  ];
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div
        className={`top-0 sticky z-50 bg-white w-full ${
          isFullScreen ? "px-3 md:px-28  pb-[10px]" : "px-3 pb-[2px]"
        } `}
      >
        {/* <p className="text-[red] text-[8px] w-full text-center font-bold">
          CC HELP AND SUPPORT READ MORE PAGE / FE / V3 / JULY 10, 2024
        </p> */}
        <p className="text-[red] text-[8px] w-full text-center font-bold">
        Copyright © 2024 Galileo One Inc
        </p>
        <div>
          <IoArrowBackCircle
            className="text-[1.5rem] cursor-pointer"
            onClick={() => navigate(-1)}
          />
        </div>
        <div className="flex flex-col justify-center items-center">
          <TitleSection isFullScreen={isFullScreen} />
        </div>
        <h2
          className={`text-[#00A3FF] ${
            isFullScreen ? "text-[2rem]" : "text-[14px]"
          } w-full text-center font-bold mt-1`}
        >
          Get Started
        </h2>
      </div>
      <div className={`${isFullScreen ? "md:px-28 px-3" : "px-3"} pt-1`}>
        <div>
          {questionsData.map((data) => (
            <Card className="p-0">
              <CardBody className="p-2">
                <Typography
                  color="blue-gray"
                  className={`${
                    isFullScreen ? "text-[20px]" : "text-[14px] "
                  } mb-2 font-semibold`}
                >
                  {data.question}
                </Typography>
                {data.answer.map((answer) => (
                  <Typography className={`${isFullScreen ?"text-[14px]":"text-[11px]"} text-justify mt-2`}>
                    {answer}
                  </Typography>
                ))}
              </CardBody>
              <CardFooter className="flex flex-col justify-center items-center  relative">
                <Typography className="absolute -right-2 flex justify-end bottom-10 z-30">
                  {showReviewModal && (
                    <AddReviewCard cancel={handleShowModal} menuRef={menuRef} isFullScreen={isFullScreen} />
                  )}
                </Typography>
                <Typography className="text-[12px]">
                  Did you find this helpful?
                </Typography>
                <div className="flex justify-center items-center gap-4  w-full mt-2">
                  <Button className="rounded-full p-1 bg-gray-200">
                    <BiSolidLike
                      className="text-[1rem]"
                      color="green"
                      onClick={handleShowModal}
                    />
                  </Button>
                  <Button className="rounded-full p-1 bg-gray-200 ">
                    <BiSolidDislike
                      className="text-[1rem]"
                      color="red"
                      onClick={handleShowModal}
                    />
                  </Button>
                </div>
              </CardFooter>
            </Card>
          ))}
        </div>
        <div className={`my-4 ${isFullScreen?"md:px-20":""}`}>
          <InputBoxSection handleShowModal={handleShowModal} isFullScreen={isFullScreen} />
        </div>
      </div>
    </>
  );
}
