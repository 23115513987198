import React from "react";
import CreatedGroupTab from "./CreatedGroupTab";
import { UpdatesTab } from "../AccountPage/UpdatesTab";
import CreatedGroupProfileSection from "./CreatedGroupProfileSection";

export default function CreatedGroupUpdates() {
   return (
      <section className="h-full overflow-hidden">
         <CreatedGroupProfileSection />
         <span className="absolute top-0 flex justify-center  text-[#ff3b3b] font-bold text-[12px] w-full ">
            Copyright © 2024 Galileo One Inc
         </span>
         {/* <span className="absolute top-0 flex justify-center  text-[#ff3b3b] font-bold text-[12px] w-full ">
            CC GROUP UPDATES - FE - V2 - JUNE 25, 2024
         </span> */}
         <CreatedGroupTab tabName={"Updates"} />
         <div className="bg-black md:h-[40%] h-[43%] px-4 pt-2 overflow-scroll no-scrollbar" >
            <UpdatesTab grouppage={true} />
         </div>
      </section>
   );
}
