import React from "react";
import { IoArrowBackCircle } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import AccountProfileSection from "../../../Component/AccountPage/AccountProfileSection";
import AccountTab from "../../../Component/AccountPage/AccountTab";

export default function EnterOtpContainer() {
   const navigate = useNavigate();
   return (
      <>
         <div className="bg-[#000] overflow-hidden h-full relative ">
            {/* <p className="text-[#ff3b3b] text-center w-full mb-2  text-[12px] font-[700] flex justify-center z-50 lg:text-[10px] uppercase">
               CC ACCOUNT PRIVATE ENTEROTP  PAGE-FE - V11 /JULY 01, 2024
            </p> */}
            <p className="text-[#ff3b3b] text-center w-full mb-2  text-[12px] font-[700] flex justify-center z-50 lg:text-[10px] uppercase">
            Copyright © 2024 Galileo One Inc
            </p>
            <div className="">
               <AccountProfileSection />
            </div>
            <AccountTab activeTab={"Private"} />
            <div className="p-2 overflow-y-scroll no-scrollbar absolute w-full h-[-webkit-fill-available]">
               <div className="flex justify-start text-start">
                  <IoArrowBackCircle
                     onClick={() => navigate(-1)}
                     className="text-[1.5rem] text-white hover:text-[#ffffffe3] cursor-pointer"
                  />
               </div>
               <div class="max-w-md mx-auto mt-5">
                  <form class="shadow-md px-4 py-6">
                     <div class="flex justify-center gap-2 mb-6">
                        <input
                           required
                           type="text"
                           maxlength="1"
                           pattern="[0-9]"
                           inputmode="numeric"
                           autocomplete="one-time-code"
                           className="w-12 h-12 text-center border rounded-md shadow-sm focus:outline-none"
                        />
                        <input
                           required
                           type="text"
                           maxlength="1"
                           pattern="[0-9]"
                           inputmode="numeric"
                           autocomplete="one-time-code"
                           className="w-12 h-12 text-center border rounded-md shadow-sm focus:outline-none"
                        />
                        <input
                           required
                           type="text"
                           maxlength="1"
                           pattern="[0-9]"
                           inputmode="numeric"
                           autocomplete="one-time-code"
                           className="w-12 h-12 text-center border rounded-md shadow-sm focus:outline-none"
                        />
                        <input
                           required
                           type="text"
                           maxlength="1"
                           pattern="[0-9]"
                           inputmode="numeric"
                           autocomplete="one-time-code"
                           className="w-12 h-12 text-center border rounded-md shadow-sm focus:outline-none"
                        />
                     </div>
                     <div class="flex items-center justify-center">
                        <button
                           class="bg-orange-700 hover:bg-orange-800 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                           type="button"
                           onClick={() => navigate("/account/private/setPassword")}
                        >
                           Verify
                        </button>
                        <a
                           class="inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-600 ml-4"
                           href="#"
                        >
                           Resend OTP
                        </a>
                     </div>
                  </form>
               </div>
            </div>
         </div>
      </>
   );
}
