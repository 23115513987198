// import { useState } from "react";
// import CardContent from "../Components/Webversion/CardContent/CardContent";
// import LeftSideBar from "../Components/Webversion/LeftSideBar/LeftSideBar";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import MobileLayout from "../Components/MobileVersion/MobilePanel/Layout";

export default function WebLayout({ children }) {
  // const navigate = useNavigate();
  const location = useLocation();
  const param = useParams();
  const { id } = param;
  // const [myPlayerData, setMyplayer] = useState({});
  // const title =
  //   "Note to React coders - This area will either be Videos or promotional advert or text or numerical boxes or anything that we decide later such as image buttonsor  or text This area repsents our SECRET SAUCE";

  return (
    <section className="w-full md:h-[100vh] flex justify-center items-center md:pt-[10px] bg-gradient-to-r from-blue-600 to-violet-600">
      {/* <div className="w-[30%] h-[88vh]  hidden">
        <LeftSideBar myPlayerData={myPlayerData} />
      </div> */}

      <div className="flex justify-center overflow-auto no-scrollbar w-full md:w-[40%]  md:h-[88vh] h-screen">
        {location.pathname == "/home" ||
          location.pathname == `/watchvideo/${id}` ? (
          children
        ) : (
          <MobileLayout>{children}</MobileLayout>
        )}
      </div>
      {/* <div className=" w-full hidden md:flex md:w-[40%]  flex-col   relative  ">
        <div className="home-container">
          <CardContent color={"gray"} title={title} />
          <div className="yellow-card-container">
            <CardContent color={"yellow"} title={title} />
          </div>
        </div>
        <div className="absolute top-[77%] left-0">
          <h1 className="text-[red] text-lg font-bold w-full text-center">
            CC WEB HOME PAGE V1 /FE/MARCH 1 / 2024
          </h1>
          <p className="text-base font-bold">
            Copyright © 2024 by Vikas Rai and Sudha Rai and Galileo One Inc
          </p>
        </div>
        <div className=" absolute bottom-4 grid grid-cols-10  gap-x-2 gap-y-2">
          <div
            onClick={() => navigate("/terms-of-use")}
            className="col-span-2 font-bold text-[14px] cursor-pointer underline  hover:text-green-400 hover:underline "
          >
            Terms Of Use
          </div>
          <div
            onClick={() => navigate("/privacy-policy")}
            className="col-span-2 font-bold text-[14px] cursor-pointer underline hover:text-green-400 hover:underline"
          >
            Privacy Policy
          </div>
          <div
            onClick={() => navigate("/cookies")}
            className="col-span-2 font-bold text-[14px] cursor-pointer underline hover:text-green-400 hover:underline"
          >
            Cookies Policy
          </div>
          <div
            onClick={() => navigate("/commingsoon")}
            className="col-span-1 font-bold text-[14px] cursor-pointer underline hover:text-green-400 hover:underline"
          >
            About
          </div>
          <div
            onClick={() => navigate("/help")}
            className="col-span-1 font-bold text-[14px] cursor-pointer underline hover:text-green-400 hover:underline"
          >
            Help
          </div>
          <div
            onClick={() => navigate("/commingsoon")}
            className="col-span-1 font-bold text-[14px] cursor-pointer underline hover:text-green-400 hover:underline"
          >
            Blog
          </div>
          <div
            onClick={() => navigate("/contact-us")}
            className="col-span-2 font-bold text-[14px] cursor-pointer underline hover:text-green-400 hover:underline"
          >
            Contact Us
          </div>
          <div className="col-span-8 font-bold text-[14px] cursor-pointer underline hover:text-green-400 hover:underline">
            Copyright © 2024 CC All rights reserved to Vikas Rai and Sudha Rai.
          </div>
        </div>
      </div> */}
    </section>
  );
}
