import React, { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { Avatar } from "@material-tailwind/react";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import { useNavigate } from "react-router-dom";

import { MdReportProblem } from "react-icons/md";
import { BiSolidVolumeMute } from "react-icons/bi";
import { MdBlock } from "react-icons/md";
import { BiSolidHide } from "react-icons/bi";
import { useSelector, useDispatch } from "react-redux";
import { getMessagesChatList } from "../../Redux/ApiSlice/MessagesSlice";
import Skeleton from "react-loading-skeleton";

export default function MessagesRecent({ search }) {
   const navigate = useNavigate();
   const dispatch = useDispatch();
   const [menuCommentId, setMenuCommentId] = useState(null);
   const menuRef = useRef(null);
   const [recentData, setRecentData] = useState([])
   const MessagesData = useSelector((state) => state.messages);
   const { messagesList, loading } = MessagesData


   const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
         setMenuCommentId(null); // Close menu if clicked outside
      }
   };

   const toggleMenu = (commentId) => {
      if (menuCommentId === commentId) {
         setMenuCommentId(null); // Close menu if already open
      } else {
         setMenuCommentId(commentId); // Open menu for this comment
      }
   };

   useEffect(() => {
      if (search) {
         const filteredData = messagesList.filter((item) => {
            return (item.users[0]?.first_name.toLowerCase().includes(search.toLowerCase()) || item.users[0]?.last_name.toLowerCase().includes(search.toLowerCase()))
         })
         setRecentData(filteredData)
      } else {
         setRecentData(messagesList)
      }
   }, [search])

   useEffect(() => {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
         document.removeEventListener("mousedown", handleClickOutside);
      };
   }, []);

   const fetchData = useCallback(async () => {
      await dispatch(
         getMessagesChatList()
      )
   }, [dispatch])

   useEffect(() => {
      fetchData()
   }, [fetchData])

   return (
      <Fragment>
         {loading && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((item) => (
            <div className="grid grid-cols-12 p-2 py-0 mt-1" key={item}>
               <div className="col-span-2">
                  <Skeleton baseColor="#202020" highlightColor="#444" height={30} width={30} circle={true} />

               </div>
               <div className="col-span-6 flex flex-col  items-start">
                  <div>
                     <Skeleton baseColor="#202020" highlightColor="#444" height={10} width={150} />

                  </div>
                  <div>
                     <Skeleton baseColor="#202020" highlightColor="#444" height={10} width={100} />

                  </div>

               </div>
               <div className="col-span-4 flex justify-end items-center">
                  <Skeleton baseColor="#202020" highlightColor="#444" height={10} width={30} />

               </div>


            </div>
         ))
         }
         {recentData?.map((item) => (
            <div className="grid grid-cols-12 bg-white px-2 py-1 border-b-[1px] border-black relative hover:bg-gray-300 cursor-pointer">
               <div
                  className="grid grid-cols-7 col-span-7"
                  onClick={() => navigate(`/mobileMessages/userChat/${item.id}`)}
               >
                  <div className="col-span-2 flex items-center justify-start">
                     <Avatar
                        src={item.users[0]?.avatar_url}
                        alt="avatar"
                        className="h-9 w-9 shadow-2xl object-cover"
                     />
                  </div>
                  <div className="col-span-5 flex flex-col justify-center items-start leading-[20px]">
                     <p className="font-semibold text-[14px]">
                        {item.users[0]?.first_name} {item.users[0]?.last_name}
                     </p>
                     <p className="text-[12px] line-clamp-1">
                        {item?.latest_message?.body}
                     </p>
                  </div>
               </div>
               <div className="col-span-5 flex flex-col justify-between items-end">
                  <div>
                     {menuCommentId === item?.id ? (
                        ""
                     ) : (
                        <BiDotsHorizontalRounded
                           className="text-[1.5rem] cursor-pointer"
                           onClick={() => toggleMenu(item?.id)}
                        />
                     )}
                  </div>
                  <div className="flex justify-end items-end">
                     {menuCommentId === item?.id ?
                        "" :
                        <p className="text-[11px] text-gray-600 mr-[3px] font-extralight">{item?.latest_message?.sent_time}</p>
                     }

                     {menuCommentId === item?.id && (
                        <div
                           ref={menuRef}
                           className="h-[2.4rem] w-[5rem] bg-white grid grid-cols-2 gap-[1px] justify-center items-center transition-opacity translate-x-0 transform duration-1000 ease-in-out opacity-100"
                        >
                           <p className="text-[8px] text-white flex items-center justify-center gap-[2px] bg-black h-full w-full text-center col-span-1 hover:bg-[#1e1e1e] cursor-pointer ">
                              <MdReportProblem /> Report
                           </p>
                           <p className="text-[8px] text-white flex items-center justify-center gap-[2px] bg-black h-full w-full text-center col-span-1 hover:bg-[#1e1e1e] cursor-pointer">
                              <BiSolidVolumeMute /> Mute
                           </p>
                           <p className="text-[8px] text-white flex items-center justify-center gap-[2px] bg-black h-full w-full text-center col-span-1 hover:bg-[#1e1e1e] cursor-pointer">
                              <MdBlock /> Block
                           </p>
                           <p className="text-[8px] text-white flex items-center justify-center gap-[2px] bg-black h-full w-full text-center col-span-1 hover:bg-[#1e1e1e] cursor-pointer">
                              <BiSolidHide /> Hide
                           </p>
                        </div>
                     )}
                  </div>
               </div>
            </div>
         ))}
         {recentData?.length === 0 && !loading && <div className="flex justify-center items-center h-[50vh]">
            <p className="text-[14px] text-gray-400">No Messages Found</p>
         </div>
         }
      </Fragment>
   );
}
