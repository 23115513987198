import React, { useCallback, useEffect, useRef } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "react-multi-carousel/lib/styles.css";
import HomeVideoView from "../../Component/Home/HomeVideoView";
import { getSavedFolders } from "../../Redux/ApiSlice/SavedVideosSlice";
import { useDispatch, useSelector } from "react-redux";
import VideoCarousel from "../../Component/Home/VideoCarousel";
import { useNavigate } from "react-router-dom";
import toast , { Toaster } from "react-hot-toast";
// import InfiniteScrollVideos from "../../Component/Home/InfiniteScrollVideos";

export default function HomePagePanel(props) {
   const { spilThashTags, setPreButton, setNextButton, AllPosts, id } = props;

   const dispatch = useDispatch();
   // const { spilThashTags, setPreButton, setNextButton, AllPosts } = props;
   const ProfileDetails = useSelector((state) => state.AccountProfile);
   const { User_profile } = ProfileDetails;

   const fetchData = useCallback(async () => {
      await dispatch(getSavedFolders(User_profile.id));
   }, [dispatch, User_profile.id]);

   const handleReshared = () => {
   toast.success("RESHARED")
   }

   useEffect(() => {
      fetchData();
   }, [fetchData]);

   return (
      <>
         <div className="video-item overflow-hidden h-full w-full hidden md:block">
            <Toaster position="top-right" />

            <VideoCarousel
               videos={AllPosts}
               setPreButton={setPreButton}
               setNextButton={setNextButton}
               spilThashTags={spilThashTags}
               fetchData={fetchData}
               id={id}
               handleReshared={handleReshared}
            />
         </div>
         <div className="h-[85vh] overflow-scroll overflow-x-hidden w-full md:hidden no-scrollbar">
            <InfiniteScrollVideos
               spilThashTags={spilThashTags}
               AllPosts={AllPosts}
               fetchData={fetchData}
               id={id}
               handleReshared={handleReshared}
            />
         </div>
      </>
   );
}

const InfiniteScrollVideos = ({
   spilThashTags,
   summary,
   AllPosts,
   fetchData,
   id,
   videoRef,
   handleReshared
}) => {
   const navigate = useNavigate();
   const mainContRef = useRef(null);

   useEffect(() => {
      AOS.init({
         duration: 10,
         once: true,
         easing: "ease-in-out",
      });
      window.scrollTo({ behavior: "smooth" });
   }, [AllPosts]);

   // Scroll to the selected video
   useEffect(() => {
      if (id) {
         const element = document?.getElementById(`sect ${id}`);
         if (element) {
            element.scrollIntoView({ behavior: "smooth", block: "nearest" });
         }
      }
   }, [id]);

   useEffect(() => {
      const handleScroll = () => {
         // Example condition: if scrolled more than 100px
         if (mainContRef.current && mainContRef.current.scrollTop > 100 && id) {
            // Navigate without disrupting the current scroll
            navigate(`/watchVideo/${id}`, { replace: true }); // Consider using 'replace' to avoid pushing a new entry into the history stack
         }
      };

      const mainCont = mainContRef.current;
      if (mainCont) {
         mainCont.addEventListener("scroll", handleScroll);
      }

      // Cleanup
      return () => {
         if (mainCont) {
            mainCont.removeEventListener("scroll", handleScroll);
         }
      };
   }, [navigate, id]);


   return (
      <div ref={mainContRef} className="mainCont no-scrollbar">
         {AllPosts?.map((data) => (
            <div id={`sect ${data.id}`} className={`sect ${data.id}`} key={data.id}>
               <HomeVideoView
                  id={data.id}
                  src={data.video_url}
                  summary={summary}
                  comments={data.comments}
                  spilThashTags={spilThashTags}
                  created_by={data.created_by}
                  dec={data.post_description}
                  videoRef={videoRef}
                  comments_count={data.comments_count}
                  like_count={data.likes_count}
                  fetchData={fetchData}
                  handleReshared={handleReshared}
               />
            </div>
         ))}
      </div>
   );
};
