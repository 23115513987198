import React from "react";
import ErrorImg from "../../Assets/ErrorPage/400 Error Bad Request.svg";

export default function Error400() {
  return (
    <main className="h-screen flex justify-center items-center">
      <div className="absolute top-0">
        <p className="text-[red] font-extrabold text-[16px] w-full text-center">
          CC -400 BAD REQUEST ERROR V1 / MAR 15, 2024
        </p>
        <p className="text-[#000] font-bold  text-[11px] w-full text-center">
          Copyright © 2024 by Galileo One Inc
        </p>
      </div>
      <div className="flex justify-center items-center flex-col">
        <img src={ErrorImg} />
        <div className="w-3/4">
          <h3 className="text-[16px] font-bold mt-4 text-center">
            The requested URL was not found on this server
          </h3>
        </div>
        <button
          className="mt-4 bg-[#000] w-28 h-15  text-[#fff] p-1 font-bold text-base"
          style={{ boxShadow: "rgb(0, 255, 71) 6px 6px 0px 2px" }}
        >
          Back
        </button>
      </div>
    </main>
  );
}
