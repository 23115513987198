import React, { useState } from "react";
import { GiBinoculars } from "react-icons/gi";

export default function TitleSection({ isFullScreen }) {
   const [text, setText] = useState("");
   const handleChangeText = (e) => {
      setText(e.target.value);
   };
   return (
      <>
         <div className="w-full flex justify-center flex-col ">
            <h3
               className={`${isFullScreen ? " text-[20px] md:text-[3rem]" : "text-[20px]"
                  }  text-blue-600 font-semibold text-center`}
            >
               Help and Support
            </h3>
            <p
               className={` ${isFullScreen ? "text-[12px] md:text-[2rem]" : "text-[12px]"
                  }  text-center font-semibold`}
            >
               Have any questions? We are here to assist you.
            </p>
         </div>
         <div
            class={`${isFullScreen ? "w-full md:w-[50%]" : "w-full"
               } flex justify-center item-center h-full relative mt-2`}
         >
            <input
               type="text"
               value={text}
               id="grid-last-name"
               placeholder="Search for help"
               onChange={handleChangeText}
               className="border-[1px] border-gray-300 focus:outline-none w-full h-10 pl-9 rounded-md bg-gray-100"
            />
            <GiBinoculars className="text-[20px] absolute top-1/2 left-2 transform -translate-y-1/2 cursor-pointer" />
         </div>
      </>
   );
}
