import React, { useState } from "react";
import {
    List,
    ListItem,
    ListItemPrefix,
    ListItemSuffix,
    Drawer,
    Card,
    Switch,
    AccordionHeader,
    Typography,
    Accordion,
    AccordionBody,
} from "@material-tailwind/react";

import {
    MagnifyingGlassIcon,
    Bars3Icon,
    XMarkIcon,
      ChevronDownIcon,
} from "@heroicons/react/24/outline";
import { MdDarkMode } from "react-icons/md";
import { CiLight } from "react-icons/ci";
import { RiAdvertisementFill } from "react-icons/ri";
import { FaPlayCircle } from "react-icons/fa";
import { IoTextSharp } from "react-icons/io5";
import { MdReportProblem } from "react-icons/md";
import { BiSolidVolumeMute } from "react-icons/bi";
import { SiApostrophe } from "react-icons/si";
import {
    HiOutlineCursorArrowRipple,
    HiSpeakerWave,
} from "react-icons/hi2";

const CreatedGroupSliderMenu = () => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [darkMode, setDarkMode] = useState(false);
    const [autoMute, setAutoMute] = useState(false);
    const [open, setOpen] = useState(0);

    const handleOpen = (value) => {
        setOpen(open === value ? 0 : value);
    };

    const openDrawer = () => setIsDrawerOpen(true);
    const closeDrawer = () => setIsDrawerOpen(false);
    const handleChangeMode = () => setDarkMode(!darkMode);
    const handleChangeAutoMute = () => setAutoMute(!autoMute);

    return (
        <>
            <button onClick={openDrawer}>
                {isDrawerOpen ? (
                    <XMarkIcon className={`h-8 w-8 md:h-7 md:w-7 stroke-2 text-[#fff]`} />
                ) : (
                    <Bars3Icon className={`h-8 w-8 md:h-7 md:w-7 stroke-2 text-[#fff]`} />
                )}
            </button>
            <Drawer
                open={isDrawerOpen}
                onClose={closeDrawer}
                placement="right"
                className="absolute overflow-hidden w-[75%]"
            >
                <Card
                    color="transparent"
                    shadow={false}
                    className="overflow-hidden no-scrollbar"
                    style={{ zIndex: 1, height: "100vh", maxHeight: "100vh" }}
                >
                    <p className="text-[red] text-[8px] font-bold absolute top-0 w-full text-center">
                    Copyright © 2024 Galileo One Inc
                    </p>
                    {/* <p className="text-[red] text-[8px] font-bold absolute top-0 w-full text-center">
                        CC GROUP OWNER SLIDER MENU / FE / V3 / JULY 08, 2024
                    </p> */}
                    <List>
                        <div className="p-2 mt-3 relative">
                            <MagnifyingGlassIcon className="h-5 w-5 absolute top-[17px] left-[12px] cursor-pointer" />
                            <input
                                type="text"
                                placeholder="Search"
                                className="border border-[#d9d9d9] w-full pl-7 pr-2 py-[0.4rem] focus:outline-none rounded-[5px]"
                            />
                        </div>
                        <hr className="my-2 border-blue-gray-50" />

                        <ListItem>
                            <ListItemPrefix>
                                <SiApostrophe className="h-5 w-5" />
                            </ListItemPrefix>
                            Posts
                        </ListItem>
                        <ListItem>
                            <ListItemPrefix>
                                <RiAdvertisementFill className="h-5 w-5" />
                            </ListItemPrefix>
                            Advertise
                        </ListItem>

                        <ListItem>
                            <ListItemPrefix>
                                <FaPlayCircle className="h-5 w-5" />
                            </ListItemPrefix>
                            Auto Play
                        </ListItem>

                        <Accordion
                            open={open === 1}
                            icon={
                                <ChevronDownIcon
                                    strokeWidth={2.5}
                                    className={`mx-auto h-4 w-4 transition-transform ${open === 1 ? "rotate-180" : ""
                                        }`}
                                />
                            }
                        >
                            <ListItem className="p-0" selected={open === 1}>
                                <AccordionHeader
                                    onClick={() => handleOpen(1)}
                                    className="border-b-0 p-3"
                                >
                                    <ListItemPrefix>
                                        <IoTextSharp className="h-5 w-5" />
                                    </ListItemPrefix>
                                    <Typography color="blue-gray" className="mr-auto font-normal">
                                        Text Size
                                    </Typography>
                                </AccordionHeader>
                            </ListItem>
                            <AccordionBody className="py-1">
                                <List className="p-0">
                                    <ListItem>
                                        <ListItemPrefix>
                                            <HiOutlineCursorArrowRipple
                                                strokeWidth={3}
                                                className="h-3 w-5"
                                            />
                                        </ListItemPrefix>
                                        Menu1
                                    </ListItem>
                                </List>
                            </AccordionBody>
                        </Accordion>
                        <ListItem>
                            <ListItemPrefix>
                                {darkMode ? (
                                    <MdDarkMode className="h-5 w-5" />
                                ) : (
                                    <CiLight className="h-5 w-5" />
                                )}
                            </ListItemPrefix>
                            Dark Mode
                            <ListItemSuffix>
                                <Switch checked={darkMode} onChange={handleChangeMode} />
                            </ListItemSuffix>
                        </ListItem>
                        <ListItem>
                            <ListItemPrefix>
                                <MdReportProblem className="h-5 w-5" />
                            </ListItemPrefix>
                            Report
                        </ListItem>
                        <ListItem>
                            <ListItemPrefix>
                                {autoMute ? (
                                    <BiSolidVolumeMute className="h-5 w-5" />
                                ) : (
                                    <HiSpeakerWave className="h-5 w-5" />
                                )}
                            </ListItemPrefix>
                            Auto Mute
                            <ListItemSuffix>
                                <Switch checked={autoMute} onChange={handleChangeAutoMute} />
                            </ListItemSuffix>
                        </ListItem>
                    </List>
                </Card>
            </Drawer>
        </>
    );
};

export default CreatedGroupSliderMenu;
