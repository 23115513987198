import React from "react";
import { StoreTitle } from "../../Component/HashTag/StoreTitle";
import ProductListSection3 from "../../Component/HashTag/ProductListSection3";

export default function HashTagStore() {
   return (
      <div className="h-full bg-[#000] relative overflow-hidden">
         <StoreTitle />
         <div className="w-full h-[0.3px] bg-[#3a3a3a] mt-[6px]" />
         <div className="mt-1 absolute w-full h-[-webkit-fill-available] overflow-scroll no-scrollbar">
            <ProductListSection3 />
         </div>
         {/* <p className="text-[9px] text-red-500 absolute top-0 w-full flex justify-center font-bold">
            CC HASHTAG STORE, FE, V8, JUNE 21, 2024
         </p> */}
         <p className="text-[9px] text-red-500 absolute top-0 w-full flex justify-center font-bold">
         Copyright © 2024 Galileo One Inc
         </p>
      </div>
   );
}


