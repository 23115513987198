import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import axios from "axios";
import moment from "moment";
import toast, { Toaster } from "react-hot-toast";
// import { CiCircleQuestion } from "react-icons/ci";
// import {
//   Popover,
//   PopoverHandler,
//   PopoverContent,
// } from "@material-tailwind/react";
import SecretSauceButton from "../../Components/MobileVersion/SecretSauceButton/SecretSauceButton";
// import Footer from "../../Component/Footer/Footer";
// import HeaderTab from "../../Component/Login/LoginHeader";
// import { FaEye, FaEyeSlash } from "react-icons/fa";
import { REACT_APP_MAIN_URL } from "../../API/MainUrl";
import { HiEyeSlash, HiMiniEye } from "react-icons/hi2";
import { ccLogo } from "../../Datas/Assests";

export default function Register() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClick = (value) => {
    navigate(`/${value}`);
  };

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      password1: "",
      password2: "",
      date_of_birth: "",
      gender: "",
    },
    onSubmit: (values) => {
      const date = moment(values.date_of_birth);

      // Original date string
      const originalDateString = date._i;

      // Parse the original date string with moment.js
      const originalDate = moment(originalDateString);

      // Format the date in the desired format
      const rearrangedDateString = originalDate.format("YYYY-MM-DD");

      // Output: "2001-05-06"
      if (
        values.date_of_birth &&
        values.email &&
        values.first_name &&
        values.gender &&
        values.password2 &&
        values.last_name &&
        values.password1
      ) {
        const loadingToastId = toast.loading('Signing up...');
        axios({
          method: "post",
          url: `${REACT_APP_MAIN_URL}api/signup/`,
          data: { ...values, date_of_birth: rearrangedDateString },
          headers: { "Content-Type": "application/json" },
        })
          .then(function (response) {
            //handle success
            const { status } = response;
            if (status === 200) {
              toast.success(
                "Sign up is Completed, Please activate your account in the given mail",
                { id: loadingToastId }
              );
              setTimeout(() => {
                navigate("/login");
              }, 4000);
            }
          })
          .catch(function (response) {
            console.log(response);
            toast.error("Invalid", { id: loadingToastId });
          });
      } else {
        toast.error(`Please fill the Field`);
      }
    },
  });

  return (
    <>
      {toast && <Toaster position="top-right" />}
      {/* important code do not remove */}

      {/* <div className="hidden max-h-screen overflow-hidden bg-[#74cbfc] h-[100vh]">
        <p className="absolute uppercase text-[#ff3b3b] right-5 font-semibold">
          CC Register V2 / FE / 16 FEB 2024
        </p>

        <HeaderTab isSign={true} />

        <div className="grid grid-cols-12 -mt-[2rem]">
          <section className="col-span-4">
          </section>
          <section className="col-span-4 w-full bg-[#fff] rounded-2xl">
            <p className="text-center text-[1.8rem] font-bold mt-2 tracking-wide">
              Let’s get started!
            </p>
            <p className="text-center text-[0.8rem] font-semibold mb-3 px-8">
              Well, this is exciting! We’re ready, are you? Take the first
              steps. Create your Cardinal Cars account, its free!
            </p>
            <form
              onSubmit={formik.handleSubmit}
              className="grid grid-cols-12 px-[4.55rem] gap-x-[12px] gap-y-[10px]"
            >
              <div className="col-span-6">
                <lable>First Name</lable>
                <input
                  type="text"
                  name="first_name"
                  onChange={formik.handleChange}
                  placeholder="Enter your first name"
                  className="border-[1px] border-black px-3 py-2 focus:outline-none rounded-md outline-none w-full"
                />
              </div>
              <div className="col-span-6">
                <lable>Last Name</lable>
                <input
                  type="text"
                  name="last_name"
                  onChange={formik.handleChange}
                  placeholder="Enter your last name"
                  className="border-[1px] border-black px-3 py-2 focus:outline-none rounded-md outline-none w-full"
                />
              </div>
              <div className="col-span-12">
                <lable>Email</lable>
                <input
                  type="text"
                  name="email"
                  onChange={formik.handleChange}
                  placeholder="Enter your email addresA"
                  className="border-[1px] border-black px-3 py-2 focus:outline-none rounded-md outline-none w-full"
                />
              </div>
              <div className="col-span-6">
                <lable>Password</lable>
                <div className="relative">
                  <input
                    type={showPassword ? "text" : "password"}
                    name="password1"
                    placeholder="Enter password"
                    onChange={formik.handleChange}
                    maxLength={10}
                    className="border-[1px] border-black px-3 pr-5 py-2 focus:outline-none rounded-md outline-none w-full"
                  />
                  <p className="absolute top-[0.9rem] right-1">
                    {showPassword ? (
                      <FaEye onClick={() => setShowPassword(false)} />
                    ) : (
                      <FaEyeSlash onClick={() => setShowPassword(true)} />
                    )}
                  </p>
                </div>
              </div>
              <div className="col-span-6">
                <lable>Confirm Password</lable>
                <div className="relative w-full">
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    name="password2"
                    onChange={formik.handleChange}
                    placeholder="Confirm password"
                    maxLength={10}
                    className="border-[1px] border-black px-3 pr-5 py-2 focus:outline-none rounded-md outline-none w-full"
                  />
                  <p className="absolute top-[0.9rem] right-1">
                    {showConfirmPassword ? (
                      <FaEye
                        onClick={() => setShowConfirmPassword(false)}
                        className="text-[1rem]"
                      />
                    ) : (
                      <FaEyeSlash
                        onClick={() => setShowConfirmPassword(true)}
                        className="text-[1rem]"
                      />
                    )}
                  </p>
                </div>
              </div>
              <div className="col-span-6">
                <div className="flex items-center gap-1">
                  <lable>Date of Birth</lable>
                  <Popover>
                    <PopoverHandler>
                      <span className="cursor-pointer">
                        <CiCircleQuestion className="font-bold" />
                      </span>
                    </PopoverHandler>
                    <PopoverContent className="w-[25rem]">
                      <p>
                        You'll need to enter your age to determine who may use
                        the site without restriction, who requires their
                        parents' permission, and who is off-limits.
                      </p>
                    </PopoverContent>
                  </Popover>
                </div>
                <input
                  type="date"
                  name="date_of_birth"
                  onChange={formik.handleChange}
                  placeholder="yyy/dd/mm"
                  pattern="\d{4}-\d{2}-\d{2}"
                  className="border-[1px] border-black px-3 py-2 focus:outline-none rounded-md outline-none w-full"
                />
              </div>
              <div className="col-span-6">
                <div className="flex items-center gap-1">
                  <lable>Gender {"(optional)"}</lable>
                  <Popover>
                    <PopoverHandler>
                      <span className="cursor-pointer">
                        <CiCircleQuestion className="font-bold" />
                      </span>
                    </PopoverHandler>
                    <PopoverContent className="w-[25rem]">
                      <p>
                        We can learn more about you if you choose a gender. It
                        tells us who you are and how you prefer to be addressed.
                        If you do not want to share this information, you can
                        still create an account. Simply pick "prefer not to
                        answer" from the drop-down menu.
                      </p>
                    </PopoverContent>
                  </Popover>
                </div>
                <select
                  name="gender"
                  onChange={formik.handleChange}
                  className="border-[1px] border-black rounded-md outline-none w-full focus:outline-none py-[10px]"
                >
                  <option>Prefer not to answer</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="others">Others</option>
                </select>
              </div>
              <div className="col-span-12">
                <p className="text-[13px] font-medium">
                  By creating an account, I agree to the
                  <span
                    onClick={() => navigate("/terms-of-uses")}
                    className="text-[#00A3FA] cursor-pointer px-2"
                  >
                    Terms of Use,
                  </span>
                  <span
                    className="text-[#00A3FA] cursor-pointer px-2"
                    onClick={() => navigate("/privacy-policys")}
                  >
                    Privacy Policy
                  </span>
                  and
                  <span
                    className="text-[#00A3FA] cursor-pointer px-2"
                    onClick={() => navigate("/cookie")}
                  >
                    Cookies Policy.
                  </span>
                </p>
              </div>
              <div className="col-span-12">
                <button
                  type="submit"
                  className="bg-[#00A3FA] cursor-pointer w-full py-2 text-[#fff] rounded-md outline-none shadow-lg"
                >
                  Register
                </button>
                <div className="bg-black h-[0.5px] w-full my-2"></div>
                <p className="text-[15px] text-center font-semibold mb-4">
                  Already have an account ?
                  <span
                    className="text-[#00A3FA] cursor-pointer px-1"
                    onClick={() => navigate("/login")}
                  >
                    Log in
                  </span>
                </p>
              </div>
            </form>
          </section>
        </div>

        <Footer />
      </div> */}

      <div className="w-full h-[100%] flex justify-center items-center md:overflow-scroll">
        <div className="h-[100%] w-full relative">
          <div className="h-[100%] grid grid-cols-12 w-full overflow-x-hidden  px-2 overflow-scroll">
            <div className="col-span-12 flex justify-center w-full bg-[#fff] items-center  border-black">
              {/* <p className="text-[#ff3b3b] font-bold text-[12px]">
                CC REGISTER-2 / FE / V7 / DEC 13, 2024
              </p> */}
              <p className="text-[#ff3b3b] font-bold text-[12px]">
              Copyright © 2024 Galileo One Inc
              </p>
            </div>
            <div className=" col-span-12 w-full flex justify-center items-center bg-[#ffffff] flex-col">
              <img src={ccLogo} className="h-24 w-24" alt="logo"/>

              <h3 className="font-bold text-base"> Let’s get started!</h3>
              <p className="font-medium text-sm text-center">
                Well, this is exciting! We’re ready, are you? Take the first
                steps. Create your Chaa Chingg account, its free!
              </p>
            </div>
            <div className="col-span-12 w-full h-[75%] bg-[#ffffff] no-scrollbar pt-0 py-2">
              <div className="h-full flex items-center justify-center">
                <div className=" w-full h-full">
                  <div className="mobile-register-form-container  p-2 -mt-2">
                    <form onSubmit={formik.handleSubmit}>
                      <div className="flex flex-col h-[3.7rem] mb-2">
                        <label className="text-[13px] ">First name</label>
                        <input
                          className="h-full border border-black  placeholder:text-[14px] rounded-md outline-none px-3  w-full"
                          name="first_name"
                          type="text"
                          placeholder="Enter your first name"
                          required
                          onChange={formik.handleChange}
                        />
                      </div>
                      <div className="flex flex-col h-[3.7rem] mb-2">
                        <label className="text-[13px] ">Last name</label>
                        <input
                          name="last_name"
                          className="h-full border border-black  placeholder:text-[14px] rounded-md outline-none px-3  w-full"
                          type="text"
                          placeholder="Enter your last name"
                          required
                          onChange={formik.handleChange}
                        />
                      </div>

                      <div className="flex flex-col h-[3.7rem] mb-2">
                        <label className="text-[13px] ">Email</label>
                        <input
                          type="email"
                          name="email"
                          placeholder="Enter your Email"
                          onChange={formik.handleChange}
                          required
                          className="px-3  w-full h-full placeholder:text-[14px]"
                        />
                      </div>
                      <div className="flex flex-col h-[3.7rem] mb-2">
                        <label className="text-[13px] ">Password</label>
                        <div className="relative h-full w-full">
                          <input
                            type={showPassword ? "text" : "password"}
                            name="password1"
                            placeholder="Enter your Password"
                            onChange={formik.handleChange}
                            className="px-3  w-full h-full placeholder:text-[14px]"
                            required
                          />
                          <p className="absolute top-[0.7rem] right-2 cursor-pointer">
                            {
                              showPassword ? <HiEyeSlash className="text-gray-500" onClick={() => setShowPassword(!showPassword)} /> : <HiMiniEye className="text-gray-500" onClick={() => setShowPassword(!showPassword)} />
                            }
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-col h-[3.7rem] mb-2">
                        <label className="text-[13px] ">Confirm Password</label>

                        <div className="relative h-full w-full">
                          <input
                            type={showConfirmPassword ? "text" : "password"}
                            name="password2"
                            placeholder="Re-Enter Password"
                            onChange={formik.handleChange}
                            className="px-3 h-full w-full placeholder:text-[14px]"
                            required
                          />
                          <p className="absolute top-[0.7rem] right-2 cursor-pointer">
                            {
                              showConfirmPassword ? <HiEyeSlash className="text-gray-500" onClick={() => setShowConfirmPassword(!showConfirmPassword)} /> : <HiMiniEye className="text-gray-500" onClick={() => setShowConfirmPassword(!showConfirmPassword)} />
                            }
                          </p>
                        </div>
                      </div>
                      <div className="grid grid-cols-4 gap-3">
                        <div className="col-span-2">
                          <div className="flex flex-col h-[3.7rem]">
                            <label className="text-[13px] ">Date of Birth </label>
                            <input
                              type="date"
                              placeholder="mm/dd/yyyy"
                              name="date_of_birth"
                              className="h-full w-full px-2 placeholder:text-[14px]"
                              required
                              onChange={formik.handleChange}
                            />
                          </div>
                        </div>
                        <div className="mb-2 col-span-2">
                          <div className="flex flex-col w-full h-[3.7rem]">
                            <label className="text-[13px] ">Gender (optional) </label>
                            <select
                              className="border border-black h-full w-full px-2 appearance-auto placeholder:text-[14px] "
                              type="password"
                              placeholder="Prefer not to answer"
                              name="gender"
                              required
                              onChange={formik.handleChange}
                            >
                              <option value="" selected>Select Gender</option>
                              <option value="F">Female</option>
                              <option value="M">Male</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="mb-4 md:mb-2 flex space-x-2 items-center">
                        <input type="checkbox" required className="border-gray-300 rounded h-3 w-3" />

                        <p className="text-[12px]">
                          By creating an account, I agree to the
                          <span className="ml-[3px] text-[#00a3ff]">
                            Terms of Use, Privacy Policy
                          </span>
                          and <span className="ml-[3px] text-[#00a3ff]">Cookies Policy.</span>
                        </p>
                      </div>
                      <div className="sixth-form-div">
                        <div className="start-button h-12 ">
                          <button type="submit" className="p-2 mt-2 mb-2 rounded-md">
                            Create Account
                          </button>
                        </div>
                        <div className="underline-start-now-button"></div>
                      </div>
                      <div className="alreadyregistertext mb-4">
                        <p className="text-[14px]">
                          Already have an account?
                          <span className="font-bold text-[12px]  ml-1 cursor-pointer underline" onClick={() => handleClick("login")}>
                            Log in
                          </span>
                        </p>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <SecretSauceButton />
          <div className="flex justify-center items-center h-[8%] md:h-[7%] bg-[#000]">
            <button
              className="w-[30%] rounded-[2rem] bg-[#D9D9DE] border-none font-bold text-[16px] cursor-pointer px-3 py-1"
              style={{ background: "rgb(213, 217, 221)" }}
              onClick={() => handleClick("login")}
            >
              Login
            </button>
          </div> */}
        </div>
      </div>
    </>
  );
}
