import React, { useState } from "react";
import { FaDownload } from "react-icons/fa";
import { ImSad2 } from "react-icons/im";
import { BsFillFileBarGraphFill } from "react-icons/bs";
import { BiRepost } from "react-icons/bi";
import { IoSpeedometerSharp } from "react-icons/io5";
import { MdDownloading, MdOutlineDownloadDone } from "react-icons/md";
import { FaThumbsUp } from "react-icons/fa6";



const RightSide = ({ videoUrl }) => {


  const [like, setLike] = useState(false)
  const [downloaded, setDownloaded] = useState(false)


  const downloadVideo = async (videoUrl) => {

    try {
      const response = await fetch(videoUrl);
      if (!response.ok) throw new Error("Network response was not ok");

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = "video.mp4";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      setDownloaded(true)
    } catch (error) {
      console.error("Error downloading the video:", error);
    }
  };




  return (
    <div className="flex flex-col justify-center items-center gap-[23px]">
      <div>
        {downloaded ? <MdOutlineDownloadDone
          className="text-[35px] md:text-[25px] text-green-500 cursor-pointer"
        /> : <MdDownloading
          className="text-[35px] md:text-[25px] cursor-pointer"
          onClick={() => downloadVideo(videoUrl)}
        />}
      </div>
      <div>
        <IoSpeedometerSharp className="text-[35px] md:text-[25px] cursor-pointer" />
      </div>
      <div>
        <FaThumbsUp className={`text-[35px] md:text-[25px] cursor-pointer ${like ? "text-yellow-600" : "text-black "}`} onClick={() => setLike(!like)} />
      </div>
      <div>
        <BiRepost className="text-[30px] cursor-pointer" />
      </div>
      <div>
        <BsFillFileBarGraphFill className="text-[35px] md:text-[25px] cursor-pointer" />
      </div>
    </div>
  );
};

export default RightSide;
