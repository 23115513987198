import React, { useState } from "react";
import { FaCamera } from "react-icons/fa";
import { IoMenu } from "react-icons/io5";
import { IoArrowBackCircle } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { FaHeart } from "react-icons/fa";
import { IoSend } from "react-icons/io5";
import { HiOutlineFaceSmile } from "react-icons/hi2";
import { IoIosCloseCircle } from "react-icons/io";
import GroupChatSideBar from "../../Component/MobileMessagesTabs/MessagesSub/GroupChatSideBar";

const GroupChat = () => {
   const navigate = useNavigate();
   const [showEmoji1, setShowEmoji1] = useState(false);
   const [showEmoji2, setShowEmoji2] = useState(false);
   const [showEmoji3, setShowEmoji3] = useState(false);
   const [isInputFocused, setIsInputFocused] = useState(false);

   const handleInputFocus = () => {
      setIsInputFocused(true);
   };

   const handleInputBlur = () => {
      setIsInputFocused(false);
   };
   const [open, setOpen] = useState(0);
   const [isDrawerOpen, setIsDrawerOpen] = useState(false);

   const handleOpen = (value) => {
      setOpen(open === value ? 0 : value);
   };

   const openDrawer = () => setIsDrawerOpen(true);
   const closeDrawer = () => setIsDrawerOpen(false);

   return (
      <div className="h-full">
         <div className="sticky top-0 bg-white z-40">
            {/* <p className="text-[9px] text-red-600 font-bold uppercase text-center">
               CC USER GROUP CHAT / FE / V4 / MAY 04, 2024
            </p> */}
            <p className="text-[9px] text-red-600 font-bold uppercase text-center">
            Copyright © 2024 Galileo One Inc
            </p>
            <div className="grid grid-cols-12 px-1">
               <div className="col-span-2 flex items-center justify-start">
                  <IoArrowBackCircle
                     className="text-[25px] cursor-pointer"
                     onClick={() => navigate(-1)}
                  />
               </div>
               <div className="col-span-8 flex items-center justify-center gap-1">
                  <img
                     className="h-6 w-6 rounded-full object-cover"
                     src="https://images.unsplash.com/photo-1606983340126-99ab4feaa64a?q=80&w=1926&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                  />
                  <p className="text-black">Birthday Party</p>
               </div>
               <div className="col-span-2 flex items-center justify-end">
                  <IoMenu
                     onClick={openDrawer}
                     className="text-[25px] cursor-pointer"
                  />
               </div>
            </div>
            <hr className="w-full my-[2px] h-[0.1px] bg-gray-800 border-0" />
         </div>

         <div className="px-[5px] bg-white h-[64vh] overflow-y-scroll">
            <div className="grid grid-cols-12">
               <div className="col-span-12 flex items-center justify-center">
                  <p className="text-black text-[12px] font-bold">Friday 5:00pm</p>
               </div>
               {/* others send meaasge start */}
               <div className="col-span-1 flex flex-col justify-end items-center">
                  <img
                     className="h-5 w-5 rounded-full object-cover"
                     src="https://images.unsplash.com/photo-1603415526960-f7e0328c63b1?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                  />
               </div>
               <div className="col-span-6 relative">
                  <div className="flex flex-col">
                     <p className="text-black text-[12px] font-bold">Jack</p>
                     <div className="relative">
                        <img
                           className="h-[11rem] w-[7.5rem] object-cover rounded-lg"
                           src="https://images.unsplash.com/photo-1481671703460-040cb8a2d909?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                        />
                        <div className="absolute right-2 -bottom-2">
                           <div className="flex items-center justify-center gap-[4px] bg-black px-2 py-[2px] rounded-full cursor-pointer">
                              <p className="text-white text-[10px]">400</p>
                              <FaHeart className="text-red-500 hover:text-green-500 text-[12px] mx-[1px]" />
                           </div>
                        </div>
                        <div className="absolute top-1 left-1">
                           <div className="flex items-center justify-start gap-[2px] bg-[#0000008d] px-[4px] py-[2px] rounded-2xl">
                              <img
                                 className="h-3 w-3 rounded-full object-cover"
                                 src="https://images.unsplash.com/photo-1556157382-97eda2d62296?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                              />
                              <p className="text-[10px] text-white">Mark</p>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="absolute top-[50%] right-1">
                     {showEmoji1 === true ? null : (
                        <HiOutlineFaceSmile
                           className="text-[20px] cursor-pointer"
                           onClick={() => setShowEmoji1(true)}
                        />
                     )}
                  </div>
               </div>
               <div className="col-span-5"></div>
               <div className="col-span-1"></div>
               <div className="col-span-11">
                  {showEmoji1 && (
                     <div className="flex flex-row items-center gap-1 text-[20px] border border-gray-500 rounded-full bg-[#ffffff27] px-1 py-[2px] mt-3 w-fit">
                        <p
                           className="cursor-pointer transform transition-transform hover:scale-125"
                           onClick={() => setShowEmoji1(false)}
                        >
                           👍
                        </p>
                        <p
                           className="cursor-pointer transform transition-transform hover:scale-125"
                           onClick={() => setShowEmoji1(false)}
                        >
                           ❤️
                        </p>
                        <p
                           className="cursor-pointer transform transition-transform hover:scale-125"
                           onClick={() => setShowEmoji1(false)}
                        >
                           😃
                        </p>
                        <p
                           className="cursor-pointer transform transition-transform hover:scale-125"
                           onClick={() => setShowEmoji1(false)}
                        >
                           😥
                        </p>
                        <p
                           className="cursor-pointer transform transition-transform hover:scale-125"
                           onClick={() => setShowEmoji1(false)}
                        >
                           👎
                        </p>
                        <p
                           className="cursor-pointer transform transition-transform hover:scale-125"
                           onClick={() => setShowEmoji1(false)}
                        >
                           😡
                        </p>
                        <IoIosCloseCircle
                           className="cursor-pointer transform transition-transform hover:scale-125"
                           onClick={() => setShowEmoji1(false)}
                        />
                     </div>
                  )}
                  <p
                     className={`text-black text-[14px] ${showEmoji1 === true ? "mt-[1px]" : "mt-[7px]"
                        }`}
                  >
                     Check this out, Do you like this!
                  </p>
                  <button className="text-white text-[8px] bg-black px-[6px] py-[1.5px] rounded-full float-start">
                     Seen by 548/809
                  </button>
               </div>
               {/* others send meaasge end */}

               <div className="col-span-12 flex items-center justify-center py-4">
                  <p className="text-black text-[12px] font-bold">Yesterday 4:12pm</p>
               </div>

               {/* my send meaasge start */}
               <div className="col-span-6"></div>
               <div className="col-span-6 relative">
                  <div className="flex flex-col float-end">
                     <p className="text-black text-[12px] text-end font-bold">You</p>
                     <div className="relative">
                        <img
                           className="h-[11rem] w-[7.5rem] object-cover rounded-lg"
                           src="https://images.unsplash.com/photo-1609421141185-8a4f37a5d063?q=80&w=1920&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                        />
                        <div className="absolute -left-7 -bottom-2">
                           <div className="flex items-center justify-center gap-[4px] bg-black px-2 py-[2px] rounded-full cursor-pointer">
                              <p className="text-white text-[10px]">73</p>
                              <FaHeart className="text-red-500 hover:text-green-500 text-[12px] mx-[1px]" />
                           </div>
                        </div>
                        <div className="absolute top-1 left-1">
                           <div className="flex items-center justify-start gap-[2px] bg-[#0000008d] px-[4px] py-[2px] rounded-2xl cursor-pointer">
                              <img
                                 className="h-3 w-3 rounded-full object-cover"
                                 src="https://plus.unsplash.com/premium_photo-1664199486587-37f325d15182?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                              />
                              <p className="text-[10px] text-white">Matthew</p>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="absolute top-[50%] left-1">
                     {showEmoji2 === true ? null : (
                        <HiOutlineFaceSmile
                           className="text-[20px] cursor-pointer"
                           onClick={() => setShowEmoji2(true)}
                        />
                     )}
                  </div>
               </div>
               <div className="col-span-2"></div>
               <div className="col-span-10">
                  {showEmoji2 && (
                     <div className="flex flex-row items-center gap-1 text-[20px] border border-gray-500 rounded-full bg-[#ffffff27] px-1 py-[2px] mt-3 w-fit float-end">
                        <p
                           className="cursor-pointer transform transition-transform hover:scale-125"
                           onClick={() => setShowEmoji2(false)}
                        >
                           👍
                        </p>
                        <p
                           className="cursor-pointer transform transition-transform hover:scale-125"
                           onClick={() => setShowEmoji2(false)}
                        >
                           ❤️
                        </p>
                        <p
                           className="cursor-pointer transform transition-transform hover:scale-125"
                           onClick={() => setShowEmoji2(false)}
                        >
                           😃
                        </p>
                        <p
                           className="cursor-pointer transform transition-transform hover:scale-125"
                           onClick={() => setShowEmoji2(false)}
                        >
                           😥
                        </p>
                        <p
                           className="cursor-pointer transform transition-transform hover:scale-125"
                           onClick={() => setShowEmoji2(false)}
                        >
                           👎
                        </p>
                        <p
                           className="cursor-pointer transform transition-transform hover:scale-125"
                           onClick={() => setShowEmoji2(false)}
                        >
                           😡
                        </p>
                        <IoIosCloseCircle
                           className="cursor-pointer transform transition-transform hover:scale-125"
                           onClick={() => setShowEmoji2(false)}
                        />
                     </div>
                  )}
                  <p
                     className={`text-black text-[14px] text-end ${showEmoji2 === true ? "mt-[1px]" : "mt-[7px]"
                        }`}
                  >
                     Look at this
                  </p>
                  <button className="text-white text-[8px] bg-black px-[6px] py-[1.5px] rounded-full float-end">
                     Seen by 158/809
                  </button>
               </div>
               {/* my send meaasge end */}

               {/* others send meaasge start */}
               <div className="col-span-1 flex flex-col justify-end items-center">
                  <img
                     className="h-5 w-5 rounded-full object-cover"
                     src="https://images.unsplash.com/photo-1603415526960-f7e0328c63b1?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                  />
               </div>
               <div className="col-span-6 relative">
                  <div className="flex flex-col">
                     <p className="text-black text-[12px] font-bold">Michael</p>
                     <div className="relative">
                        <img
                           className="h-[11rem] w-[7.5rem] object-cover rounded-lg"
                           src="https://images.unsplash.com/photo-1527481138388-31827a7c94d5?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                        />
                        <div className="absolute right-2 -bottom-2">
                           <div className="flex items-center justify-center gap-[4px] bg-black px-2 py-[2px] rounded-full cursor-pointer">
                              <p className="text-white text-[10px]">58</p>
                              <FaHeart className="text-red-500 hover:text-green-500 text-[12px] mx-[1px]" />
                           </div>
                        </div>
                        <div className="absolute top-1 left-1">
                           <div className="flex items-center justify-start gap-[2px] bg-[#0000008d] px-[4px] py-[2px] rounded-2xl">
                              <img
                                 className="h-3 w-3 rounded-full object-cover"
                                 src="https://images.unsplash.com/photo-1506277886164-e25aa3f4ef7f?q=80&w=1935&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                              />
                              <p className="text-[10px] text-white">David</p>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="absolute top-[50%] right-1">
                     {showEmoji3 === true ? null : (
                        <HiOutlineFaceSmile
                           className="text-[20px] cursor-pointer"
                           onClick={() => setShowEmoji3(true)}
                        />
                     )}
                  </div>
               </div>
               <div className="col-span-5"></div>
               <div className="col-span-1"></div>
               <div className="col-span-11">
                  {showEmoji3 && (
                     <div className="flex flex-row items-center gap-1 text-[20px] border border-gray-500 rounded-full bg-[#ffffff27] px-1 py-[2px] mt-3 w-fit">
                        <p
                           className="cursor-pointer transform transition-transform hover:scale-125"
                           onClick={() => setShowEmoji3(false)}
                        >
                           👍
                        </p>
                        <p
                           className="cursor-pointer transform transition-transform hover:scale-125"
                           onClick={() => setShowEmoji3(false)}
                        >
                           ❤️
                        </p>
                        <p
                           className="cursor-pointer transform transition-transform hover:scale-125"
                           onClick={() => setShowEmoji3(false)}
                        >
                           😃
                        </p>
                        <p
                           className="cursor-pointer transform transition-transform hover:scale-125"
                           onClick={() => setShowEmoji3(false)}
                        >
                           😥
                        </p>
                        <p
                           className="cursor-pointer transform transition-transform hover:scale-125"
                           onClick={() => setShowEmoji3(false)}
                        >
                           👎
                        </p>
                        <p
                           className="cursor-pointer transform transition-transform hover:scale-125"
                           onClick={() => setShowEmoji3(false)}
                        >
                           😡
                        </p>
                        <IoIosCloseCircle
                           className="cursor-pointer transform transition-transform hover:scale-125"
                           onClick={() => setShowEmoji3(false)}
                        />
                     </div>
                  )}
                  <p
                     className={`text-black text-[14px] ${showEmoji3 === true ? "mt-[1px]" : "mt-[7px]"
                        }`}
                  >
                     Ready to race !
                  </p>
                  <button className="text-white text-[8px] bg-black px-[6px] py-[1.5px] rounded-full float-start">
                     Seen by 5/809
                  </button>
               </div>
               {/* others send meaasge end */}
            </div>
         </div>

         <div className="absolute bottom-0 w-full px-[5px]">
            <div className="flex items-center justify-between gap-1 my-1">
               <div className="bg-black p-2 rounded-full cursor-pointer">
                  <FaCamera className="text-white" />
               </div>
               <input
                  placeholder="Send a message"
                  className="w-full h-8 bg-black text-white px-3 focus:outline-none rounded-xl"
                  onFocus={handleInputFocus}
                  onBlur={handleInputBlur}
               />
               {isInputFocused && (
                  <div className="bg-black p-2 rounded-full cursor-pointer">
                     <IoSend className="text-white" />
                  </div>
               )}
            </div>
         </div>
         <GroupChatSideBar
            open={open}
            handleOpen={handleOpen}
            closeDrawer={closeDrawer}
            isDrawerOpen={isDrawerOpen}
         />
      </div>
   );
};

export default GroupChat;
