import React, { useEffect, useRef, useState } from "react";
import { Avatar } from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";
import { BiDotsHorizontalRounded } from "react-icons/bi";

import { MdReportProblem } from "react-icons/md";
import { BiSolidVolumeMute } from "react-icons/bi";
import { MdBlock } from "react-icons/md";
import { BiSolidHide } from "react-icons/bi";


export default function MessagesGroup({ MessagesList , search }) {
   const navigate = useNavigate();
   const [menuCommentId, setMenuCommentId] = useState(null);
   const menuRef = useRef(null);
   const [groupData, setGroupData] = useState([])

   const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
         setMenuCommentId(null); // Close menu if clicked outside
      }
   };
   const toggleMenu = (commentId) => {
      if (menuCommentId === commentId) {
         setMenuCommentId(null); // Close menu if already open
      } else {
         setMenuCommentId(commentId); // Open menu for this comment
      }
   };
   const handleClick = (event, value) => {
      event.preventDefault();
      navigate(`/mobileMessages/groupchat/${value}`);
   };
useEffect(()=>{

   if (search) {
      const filteredData = MessagesList.filter((item) => {
         return (item.name.toLowerCase().includes(search.toLowerCase()))
      })
      setGroupData(filteredData)
   } else {
      setGroupData(MessagesList)
   }
},[search])

   useEffect(() => {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
         document.removeEventListener("mousedown", handleClickOutside);
      };
   }, []);
   return (
      <>
         {groupData.map((item) => (
            <div
               className="grid grid-cols-12 bg-bg-black px-2 py-[3px] border-b-[1px] border-black bg-white relative hover:bg-gray-300 cursor-pointer"

            >
               <div className="grid grid-cols-6 col-span-7">
                  <div className="col-span-2 items-center flex justify-center rounded-full">
                     <div className="h-12 w-12 grid grid-cols-1 rounded-full bg-white flex-wrap justify-center items-center overflow-hidden">
                        <div className="grid grid-cols-12" onClick={(e) => handleClick(e, item?.id)}>
                           {[
                              1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16,
                           ].map(() => (
                              <Avatar
                                 src={item.img}
                                 alt="avatar"
                                 className="h-3 w-3 object-cover col-span-3"
                              />
                           ))}
                        </div>
                     </div>
                  </div>
                  <div className="col-span-4 ml-2 flex flex-col justify-center items-start">
                     <p className="text-black font-bold text-[14px]" onClick={(e) => handleClick(e, item?.id)}>{item.name}</p>
                     <p className="text-black text-[10px]" onClick={(e) => handleClick(e, item?.id)}>10 Members</p>
                     <p className="text-black text-[11px]" onClick={(e) => handleClick(e, item?.id)}>{item.dec}</p>
                  </div>
               </div>
               <div className="col-span-5 flex flex-col justify-between items-end">
                  <div>
                     {menuCommentId === item?.id ? (
                        ""
                     ) : (
                        <BiDotsHorizontalRounded
                           color="black"
                           className="text-[2rem] cursor-pointer"
                           onClick={() => toggleMenu(item?.id)}
                        />
                     )}
                  </div>
                  <div className="flex justify-end items-end ">
                     <p className="text-gray-600 text-[11px] mr-[4px]">2 hours ago</p>
                     {menuCommentId === item?.id && (
                        <div
                           ref={menuRef}
                           className="h-[2.4rem] w-[5rem] bg-white grid grid-cols-2 gap-[1px] justify-center items-center transition-opacity translate-x-0 transform duration-1000 ease-in-out opacity-100"
                        >
                           <p className="text-[8px] text-white flex items-center justify-center gap-[2px] bg-black h-full w-full text-center col-span-1 hover:bg-[#1e1e1e] cursor-pointer ">
                              <MdReportProblem /> Report
                           </p>
                           <p className="text-[8px] text-white flex items-center justify-center gap-[2px] bg-black h-full w-full text-center col-span-1 hover:bg-[#1e1e1e] cursor-pointer">
                              <BiSolidVolumeMute /> Mute
                           </p>
                           <p className="text-[8px] text-white flex items-center justify-center gap-[2px] bg-black h-full w-full text-center col-span-1 hover:bg-[#1e1e1e] cursor-pointer">
                              <MdBlock /> Block
                           </p>
                           <p className="text-[8px] text-white flex items-center justify-center gap-[2px] bg-black h-full w-full text-center col-span-1 hover:bg-[#1e1e1e] cursor-pointer">
                              <BiSolidHide /> Hide
                           </p>
                        </div>
                     )}
                  </div>
               </div>
            </div>
         ))}
      </>
   );
}
