import React, { useEffect, useRef, useState } from "react";
import Draggable from "react-draggable";
import Carousel from "react-multi-carousel";
import { useNavigate } from "react-router-dom";
import { FaCameraRotate } from "react-icons/fa6";
import { Button } from "@material-tailwind/react";
import { RiVideoUploadFill } from "react-icons/ri";
import { IoFlashlight, IoSpeedometer } from "react-icons/io5";
import { FaArrowAltCircleLeft, FaFilter } from "react-icons/fa";
import { MdRecordVoiceOver, MdTypeSpecimen, MdMovieEdit } from "react-icons/md";

const Create = () => {
     const navigate = useNavigate();
     const [count, setCount] = useState(0);
     const fileRef = useRef(null);
     const [showVoice, setShowVoice] = useState(false);
     const [showText, setShowText] = useState(false);
     const [showVideoCroper, setShowVideoCroper] = useState(false);
     const [showSpeed, setShowSpeed] = useState(false);
     const [showFilter, setShowFilter] = useState(false);
     const [flashOn, setFlashOn] = useState(false);
     const [filter, setFilter] = useState("none");
     const [thumbnail, setThumbnail] = useState(null);
     const [filterValue, setFilterValue] = useState(1.0);

     const handleChange = (event) => {
          const inputText = event.target.value;
          if (inputText.length <= 50) {
               setCount(inputText.length);
          }
     };

     const handleShowVoice = () => {
          setShowVoice(!showVoice);
          setShowText(false);
          setShowVideoCroper(false);
          setShowSpeed(false);
          setShowFilter(false);
     };

     const handlesShowText = () => {
          setShowVoice(false);
          setShowText(!showText);
          setShowVideoCroper(false);
          setShowSpeed(false);
          setShowFilter(false);
          setCount(null)
     };

     const handleShowVideoCroper = () => {
          setShowVoice(false);
          setShowText(false);
          setShowVideoCroper(!showVideoCroper);
          setShowSpeed(false);
          setShowFilter(false);
     };

     const handleShowSpeed = () => {
          setShowVoice(false);
          setShowText(false);
          setShowVideoCroper(false);
          setShowSpeed(!showSpeed);
          setShowFilter(false);
     };

     const handleShowFilter = () => {
          setShowVoice(false);
          setShowText(false);
          setShowVideoCroper(false);
          setShowSpeed(false);
          setShowFilter(!showFilter);
     };

     const [permission, setPermission] = useState(false);
     const mediaRecorder = useRef(null);
     const liveVideoFeed = useRef(null);
     const [recordingStatus, setRecordingStatus] = useState("inactive");
     const [stream, setStream] = useState(null);
     const [videoChunks, setVideoChunks] = useState([]);
     const [recordedVideo, setRecordedVideo] = useState(null);
     const videoRef = useRef();
     const [longDurationVideo, setLongDurationVideo] = useState(false);

     const getCameraPermission = async () => {
          setRecordedVideo(null);
          if ("MediaRecorder" in window) {
               try {
                    const videoConstraints = {
                         audio: false,
                         video: true,
                    };
                    const audioConstraints = { audio: true };
                    // create audio and video streams separately
                    const audioStream = await navigator.mediaDevices.getUserMedia(
                         audioConstraints
                    );
                    const videoStream = await navigator.mediaDevices.getUserMedia(
                         videoConstraints
                    );
                    setPermission(true);
                    //combine both audio and video streams
                    const combinedStream = new MediaStream([
                         ...videoStream.getVideoTracks(),
                         ...audioStream.getAudioTracks(),
                    ]);
                    setStream(combinedStream);
                    //set videostream to live feed player
                    liveVideoFeed.current.srcObject = videoStream;
                    setRecordedVideo(combinedStream);
               } catch (err) {
                    alert(err.message);
               }
          } else {
               alert("The MediaRecorder API is not supported in your browser.");
          }
     };

     const mimeType = "video/webm";
     const startRecording = async () => {
          setRecordingStatus("recording");
          const media = new MediaRecorder(stream, { mimeType });
          mediaRecorder.current = media;
          mediaRecorder.current.start();
          let localVideoChunks = [];
          mediaRecorder.current.ondataavailable = (event) => {
               if (typeof event.data === "undefined") return;
               if (event.data.size === 0) return;
               localVideoChunks.push(event.data);
          };
          const videoBlob = new Blob(videoChunks, { type: mimeType });
          const videoUrl = URL.createObjectURL(videoBlob);
          setRecordedVideo(videoUrl);

          setVideoChunks(localVideoChunks);
     };

     const stopRecording = () => {
          setPermission(false);
          setRecordingStatus("inactive");
          mediaRecorder.current.stop();
          mediaRecorder.current.onstop = () => {
               const videoBlob = new Blob(videoChunks, { type: mimeType });
               const videoUrl = URL.createObjectURL(videoBlob);
               setRecordedVideo(videoUrl);
               setVideoChunks([]);
          };
     };

     const handleclick = (event) => {
          const file = event.target.files[0];

          if (!file) return;
          const fileUrl = URL.createObjectURL(file);
          setRecordedVideo(fileUrl);
     };

     const handleLoadedMetadata = () => {
          if (videoRef?.current?.duration > 60) {
               setLongDurationVideo(true);
          } else {
               setLongDurationVideo(false);
          }
     };

     const responsive = {
          desktop: {
               breakpoint: { max: 3000, min: 1024 },
               items: 3.5,
               slidesToSlide: 1,
          },
          tablet: {
               breakpoint: { max: 1024, min: 464 },
               items: 2,
               slidesToSlide: 1,
          },
          mobile: {
               breakpoint: { max: 464, min: 0 },
               items: 2.5,
               slidesToSlide: 1,
          },
     };

     const applyFilter = (filterValue) => {
          setFilter(filterValue);
          if (videoRef.current) {
               videoRef.current.style.filter = filterValue;
          }
     };

     function createThumbnail(videoSrc, seekTime = 1) {
          const video = document.createElement("video");
          const canvas = document.createElement("canvas");
          const context = canvas.getContext("2d");

          video.src = videoSrc;
          video.addEventListener("loadeddata", () => {
               video.currentTime = seekTime;
          });

          video.addEventListener("seeked", () => {
               canvas.width = video.videoWidth;
               canvas.height = video.videoHeight;
               context.drawImage(video, 0, 0, canvas.width, canvas.height);
               const thumbnailUrl = canvas.toDataURL("image/png");
               setThumbnail(thumbnailUrl);
          });
     }

     const filters = [
          { name: "None", value: "none" },
          { name: "Sepia", value: "sepia(100%)" },
          { name: "Grayscale", value: "grayscale(100%)" },
          { name: "High Contrast", value: "contrast(200%)" },
          { name: "Blur", value: "blur(1px)" },
          { name: "Hue Rotate", value: "hue-rotate(90deg)" },
          { name: "Invert", value: "invert(100%)" },
          { name: "Brightness", value: "brightness(150%)" },
          { name: "Saturate", value: "saturate(200%)" },
          { name: "Opacity", value: "opacity(50%)" },
          { name: "Contrast", value: "contrast(200%)" },
          {
               name: "Brightness and Contrast",
               value: "brightness(150%) contrast(150%)",
          },
          {
               name: "Hue Rotate and Saturate",
               value: "hue-rotate(180deg) saturate(300%)",
          },
     ];

     useEffect(() => {
          handleLoadedMetadata();
     }, [recordedVideo]);

     useEffect(() => {
          if (videoRef.current) {
               videoRef.current.playbackRate = filterValue;
          }
     }, [filterValue]);

     return (
          <>
               <div className="h-full w-full">
                    {longDurationVideo ? (
                         <div className="h-full w-full flex justify-center items-center ">
                              <p className="text-[10px] text-red-800 font-extrabold">
                                   This Video Is Too Long Please select below 1 min Video.
                              </p>
                         </div>
                    ) : (
                         <video
                              ref={videoRef}
                              src={recordedVideo}
                              controls={true}
                              style={{ filter }}
                              onCanPlay={() => createThumbnail(recordedVideo, 1)}
                              className="video-filter h-full w-full object-cover"
                              onLoadedMetadata={handleLoadedMetadata}
                         />
                    )}
                    {/* <p className="absolute top-0 text-red-500 uppercase w-full text-[10px] font-bold flex justify-center">
                         cc / create video / fe / v4 / oct 11 / 2024
                    </p> */}
                    <p className="absolute top-0 text-red-500 uppercase w-full text-[10px] font-bold flex justify-center">
                    Copyright © 2024 Galileo One Inc
                    </p>
               </div>
               <div className="absolute top-6 px-4 flex justify-center w-full">
                    <div className="flex items-center">
                         <div className="absolute left-0 px-4">
                              <FaArrowAltCircleLeft
                                   className="text-[20px] cursor-pointer text-[#000000] hover:text-[#0e0e0e]"
                                   onClick={() => navigate(-1)}
                              />
                         </div>
                         <div className="absolute left-10 px-3">
                              <Button className="w-auto px-2 py-[5px] text-[11px] font-light">
                                   #clips
                              </Button>
                         </div>
                         <div>
                              {videoRef.current?.duration && (
                                   <Button className="w-auto px-2 py-[5px] text-[11px] font-light">
                                        {videoRef.current?.duration?.toFixed(2)}
                                   </Button>
                              )}
                         </div>
                    </div>
                    <div className="absolute right-1 flex flex-col">
                         <div className="mb-3">
                              <Button className="rounded-full w-8 h-8 p-0 flex justify-center items-center hover:bg-[#0e0e0e]">
                                   <FaCameraRotate color="white" className="text-[16px]" />
                              </Button>
                         </div>
                         <div className="w-10 h-10">
                              <Button
                                   className="rounded-full w-8 h-8 p-0 flex justify-center items-center hover:bg-[#0e0e0e]"
                                   onClick={() => setFlashOn(!flashOn)}
                              >
                                   <IoFlashlight
                                        color={flashOn ? "green" : "white"}
                                        className="text-[16px]"
                                   />
                              </Button>
                         </div>
                    </div>
               </div>
               <div className="absolute bottom-2 w-full">
                    {showVoice && (
                         <div className="flex justify-center items-center bg-[#c0c0c0b1] mb-10 py-6">
                              <div className="grid grid-cols-10 gap-5 md:gap-4">
                                   <div className="col-span-5 bg-black flex justify-center items-center p-8 md:p-5 w-full rounded-sm cursor-pointer">
                                        <p className="text-[13px] text-[#fff]">Gallery Voice</p>
                                   </div>
                                   <div className="col-span-5 bg-black flex justify-center items-center p-8 md:p-4 w-full rounded-sm cursor-pointer">
                                        <p className="text-[13px] text-[#fff]">Find Video Voice</p>
                                   </div>
                              </div>
                         </div>
                    )}

                    {showText && (
                         <div className="bg-[#d9d9d9] mb-10">
                              <textarea
                                   rows="3"
                                   maxLength={50}
                                   onChange={handleChange}
                                   placeholder="Type Something"
                                   className="w-full bg-[#d9d9d9] px-[10px] py-[10px] focus:outline-none resize-none"
                              />
                              <p className="absolute right-2 text-gray-900 bg-[#d9d9d9] z-10 text-[14px] -mt-6">
                                   {count}/50
                              </p>

                              <div className="absolute mt-2 flex justify-center items-center text-gray-900  w-full z-50 text-[14px] ">
                                   <button className="bg-black text-[#fff] font-bold w-2/5 px-4 py-2 rounded-full">
                                        {count ? "Upload" : "Add Text"}
                                   </button>
                              </div>
                         </div>
                    )}

                    {showVideoCroper && (
                         <div className="bg-[#c0c0c0b1] mb-10 relative">
                              <Draggable axis="x" bounds={60}>
                                   <div className="absolute left-2 top-0 bottom-0 w-1 bg-[#fff] z-50"></div>
                              </Draggable>
                              <Draggable axis="x" bounds={100}>
                                   <div className="absolute right-2 top-0 bottom-0 w-1 bg-[#fff] z-50"></div>
                              </Draggable>

                              <p className="text-center py-1">Trim video</p>
                         </div>
                    )}

                    {showSpeed && (
                         <div className="bg-[#c0c0c0b1] mb-10 px-3 py-6">
                              <div className="flex justify-between gap-2 overflow-x-auto no-scrollbar">
                                   <button
                                        className={`${filterValue === 0.25
                                             ? "bg-red-400 py-[2px]"
                                             : "bg-black py-[2px]"
                                             } text-[9px] rounded-sm text-[#fff]  px-[6px]`}
                                        onClick={() => setFilterValue(0.25)}
                                   >
                                        0.25
                                   </button>
                                   <button
                                        className={`${filterValue === 0.5
                                             ? "bg-red-400 py-[2px]"
                                             : "bg-black py-[2px]"
                                             } text-[9px] rounded-sm text-[#fff]  px-[6px]`}
                                        onClick={() => setFilterValue(0.5)}
                                   >
                                        0.50
                                   </button>
                                   <button
                                        className={`${filterValue === 0.75
                                             ? "bg-red-400 py-[2px]"
                                             : "bg-black py-[2px]"
                                             } text-[9px] rounded-sm text-[#fff]  px-[6px]`}
                                        onClick={() => setFilterValue(0.75)}
                                   >
                                        0.75
                                   </button>
                                   <button
                                        className={`${filterValue === 1.0
                                             ? "bg-red-400 py-[2px]"
                                             : "bg-black py-[2px]"
                                             } text-[9px] rounded-sm text-[#fff]  px-[6px]`}
                                        onClick={() => setFilterValue(1.0)}
                                   >
                                        Normal
                                   </button>
                                   <button
                                        className={`${filterValue === 1.5
                                             ? "bg-red-400 py-[2px]"
                                             : "bg-black py-[2px]"
                                             } text-[9px] rounded-sm text-[#fff] bg-black py-[2px] px-[6px]`}
                                        onClick={() => setFilterValue(1.5)}
                                   >
                                        1.50
                                   </button>
                                   <button
                                        className={`${filterValue === 1.75
                                             ? "bg-red-400 py-[2px]"
                                             : "bg-black py-[2px]"
                                             } text-[9px] rounded-sm text-[#fff] bg-black py-[2px] px-[6px]`}
                                        onClick={() => setFilterValue(1.75)}
                                   >
                                        1.75
                                   </button>
                                   <button
                                        className={`${filterValue === 2
                                             ? "bg-red-400 py-[2px]"
                                             : "bg-black py-[2px]"
                                             } text-[9px] rounded-sm text-[#fff] bg-black py-[2px] px-[6px]`}
                                        onClick={() => setFilterValue(2)}
                                   >
                                        2
                                   </button>
                              </div>
                         </div>
                    )}

                    {showFilter && (
                         <div className="bg-[#c0c0c0b1] mb-10 py-4 px-1">
                              <Carousel
                                   swipeable={true}
                                   draggable={true}
                                   showDots={false}
                                   responsive={responsive}
                                   ssr={true}
                                   infinite={false}
                                   arrows={false}
                                   renderButtonGroupOutside={true}
                                   renderDotsOutside={false}
                                   className="w-full mt-2"
                              >
                                   {filters.map((data) => (
                                        <div
                                             className={`h-24 w-20 grid grid-cols-12  rounded-md text-[10px] text-center p-1 ${filter === data.value
                                                  ? "bg-[#5527d3]"
                                                  : "bg-[#191919] hover:bg-[#2b2b2b]"
                                                  }   text-white  font-light flex items-center justify-center p-1 cursor-pointer`}
                                             key={data.name}
                                             onClick={() => applyFilter(data.value)}
                                        >
                                             <img
                                                  alt="filter"
                                                  src={thumbnail ? thumbnail : "https://wallpapers.com/images/hd/happy-pictures-skl6bc0jxabte0j3.jpg"}
                                                  style={{ filter: data.value }}
                                                  className="h-20 w-20 object-cover col-span-12 rounded-md"
                                             />

                                             <p className="text-[8px] col-span-12 ">
                                                  {data.value === "none" ? "Normal" : data.name}
                                             </p>
                                        </div>
                                   ))}
                              </Carousel>
                         </div>
                    )}

                    <div className="flex justify-center px-4 mb-4">
                         {!recordedVideo && (
                              <div className="bg-red-800 w-16 h-16 rounded-full flex justify-center items-center">
                                   {!permission ? (
                                        <button
                                             onClick={getCameraPermission}
                                             type="button"
                                             className="text-[10px] p-2 font-bold hover:text-white"
                                        >
                                             Get Camera
                                        </button>
                                   ) : null}
                                   {permission ? (
                                        <p
                                             className="col-span-10  text-[#000] text-[12px] py-3 md:py-2 text-center font-bold "
                                             onClick={
                                                  recordingStatus === "recording"
                                                       ? stopRecording
                                                       : startRecording
                                             }
                                        >
                                             {recordingStatus === "recording" ? "Stop" : "Record"}
                                        </p>
                                   ) : null}
                              </div>
                         )}
                    </div>
                    {/* {showIcons && ( */}
                    <div className="flex justify-between px-3 bg-white py-2">
                         <div
                              onClick={handleShowFilter}
                              className={`${showFilter ? "bg-[#00ff47]" : "bg-[#c9c9c9] hover:bg-[#c0c0c0]"
                                   } p-2 rounded-full h-14 w-14 md:h-10 md:w-10 flex justify-center items-center cursor-pointer`}
                         >
                              <FaFilter className="text-[1rem]" />
                         </div>
                         <div
                              className={`bg-[#c9c9c9] hover:bg-[#c0c0c0] p-2 rounded-full h-14 w-14 md:h-10 md:w-10 flex justify-center items-center cursor-pointer`}
                         >
                              <RiVideoUploadFill
                                   className="text-[1.2rem]"
                                   onClick={() => {
                                        fileRef.current?.click();
                                   }}
                              />
                              <input
                                   type="file"
                                   accept="video/*"
                                   ref={fileRef}
                                   className="hidden"
                                   onChange={handleclick}
                              />
                         </div>
                         <div
                              onClick={handleShowVoice}
                              className={`${showVoice ? "bg-[#00ff47]" : "bg-[#c9c9c9] hover:bg-[#c0c0c0]"
                                   } p-2 rounded-full h-14 w-14 md:h-10 md:w-10 flex justify-center items-center cursor-pointer`}
                         >
                              <MdRecordVoiceOver className="text-[1.2rem]" />
                         </div>
                         <div
                              onClick={handleShowVideoCroper}
                              className={`${showVideoCroper
                                   ? "bg-[#00ff47]"
                                   : "bg-[#c9c9c9] hover:bg-[#c0c0c0]"
                                   } p-2 rounded-full h-14 w-14 md:h-10 md:w-10 flex justify-center items-center cursor-pointer`}
                         >
                              <MdMovieEdit className="text-[1.2rem]" />
                         </div>
                         <div
                              onClick={handleShowSpeed}
                              className={`${showSpeed ? "bg-[#00ff47]" : "bg-[#c9c9c9] hover:bg-[#c0c0c0]"
                                   } p-2 rounded-full h-14 w-14 md:h-10 md:w-10 flex justify-center items-center cursor-pointer`}
                         >
                              <IoSpeedometer className="text-[1.2rem]" />
                         </div>
                         <div
                              onClick={handlesShowText}
                              className={`${showText ? "bg-[#00ff47]" : "bg-[#c9c9c9] hover:bg-[#c0c0c0]"
                                   } p-2 rounded-full h-14 w-14 md:h-10 md:w-10 flex justify-center items-center cursor-pointer`}
                         >
                              <MdTypeSpecimen className="text-[1.2rem]" />
                         </div>
                    </div>
               </div>
          </>
     );
};

export default Create;
