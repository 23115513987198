import React from "react";
import { TrendingTitle } from "../../Component/HashTag/TrendingTitle";
import { HashtagRecentviews } from "../../Component/HashTag/HashtagRecentviews";

export default function HashTagRecent() {
   return (
      <div className="h-full bg-[#000] relative overflow-hidden">
         <TrendingTitle title={"Recent"} />
         <div className=" mt-2 absolute w-full h-[-webkit-fill-available] overflow-scroll no-scrollbar">
            <HashtagRecentviews />
         </div>
         {/* <p className="text-[9px] text-red-500 absolute top-0 w-full flex justify-center font-bold">
            CC HASHTAG RECENT VIDEOS, FE, V8, JUNE 21, 2024
         </p> */}
         <p className="text-[9px] text-red-500 absolute top-0 w-full flex justify-center font-bold">
         Copyright © 2024 Galileo One Inc
         </p>

      </div>
   );
}
