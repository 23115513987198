import React from "react";
import AccountTab from "../../Component/AccountPage/AccountTab";
import AccountProfileSection from "../../Component/AccountPage/AccountProfileSection";
import { PostedTab } from "../../Component/AccountPage/PostedTab";
import { useSelector } from "react-redux";

export default function Posted() {
   const ProfileDetails = useSelector((state) => state.AccountProfile);
   const { User_profile, User_posts } = ProfileDetails;
   return (
      <div className="bg-[#000] overflow-hidden h-full relative">
         {/* <p className="text-[#ff3b3b] text-center w-full  mb-2 text-[12px] font-[700] flex justify-center z-50 lg:text-[10px] uppercase">
            CC ACCOUNT POSTED PAGE-FE - V11 / JUNE 29, 2024
         </p> */}
         <p className="text-[#ff3b3b] text-center w-full  mb-2 text-[12px] font-[700] flex justify-center z-50 lg:text-[10px] uppercase">
         Copyright © 2024 Galileo One Inc
         </p>
         <div className="">
            <AccountProfileSection />
         </div>
         <AccountTab activeTab={"Posted"} />
         <div className="p-2 overflow-y-scroll no-scrollbar absolute w-full h-[-webkit-fill-available]">
            <PostedTab User_posts={User_posts} />
         </div>
      </div>
   );
}
