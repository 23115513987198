import React from "react";
import { useNavigate } from "react-router-dom";
import { SocialIcon } from "react-social-icons";
// import { CiCircleQuestion } from "react-icons/ci";
// import {
//   Popover,
//   PopoverHandler,
//   PopoverContent,
// } from "@material-tailwind/react";
import { loginPageDomainName } from "../../Datas/Datas";
import "../../Components/Css/LoginPage.css";
// import SecretSauceButton from "../../Components/MobileVersion/SecretSauceButton/SecretSauceButton";
import { ccLogo } from "../../Datas/Assests";
import { IoMdArrowBack } from "react-icons/io";
import chirpLogo from "../../Assets/logo/c_logo.png"
import KvLogo from "../../Assets/logo/kv_logo.png"

export default function SignupPage() {
  const navigate = useNavigate();
  // const [registerPage, setRegisterPage] = useState(false);

  // const handleRegister = () => {
  //   navigate("/register");
  //   setRegisterPage(true);
  // };

  // const handlevavigate = () => {
  //   navigate("/commingsoon");
  // };

  const handleClick = (value) => {
    navigate(`/${value}`);
  };
  return (
    <>
      {/* <div className="hidden sm:hidden md:block max-h-screen overflow-hidden bg-[#74cbfc] h-[100vh]">
            <p className="absolute uppercase text-[#ff3b3b] right-5 font-semibold">
               CC Register V2 / FE / 16 FEB 2024
            </p>
            <HeaderTab isSign={true} />
               <div className="grid grid-cols-12 mt-8">
                  <section className="col-span-4">
                  </section>

                  <section className="col-span-4 w-full bg-[#fff] rounded-2xl">
                     <p className="text-center text-[2rem] font-bold mt-2 tracking-wide">
                        Joy Watch Fun Learn
                     </p>
                     <div className="px-28 mt-5 mb-10">
                        <div
                           className="bg-[#535353] text-center px-4 py-3 mb-1 cursor-pointer"
                           onClick={handleRegister}
                        >
                           <p className="text-[#fff] font-semibold text-[13px]">
                              Email Address / Username
                           </p>
                        </div>
                        {loginPageDomainName.map((data) => (
                           <div
                              className="grid grid-cols-10 bg-[#535353] px-4 py-2 mb-1 cursor-pointer"
                              key={data.id}
                              onClick={handlevavigate}
                           >
                              <div className="col-span-1">
                                 {data.Icon === "apple" ? (
                                    <img
                                       src={
                                          "https://th.bing.com/th/id/R.d8426cb195ac7b393f853110480f0d34?rik=KA%2fqJN5JGzzYQQ&riu=http%3a%2f%2fwww.clipartbest.com%2fcliparts%2fRTA%2fEjA%2fRTAEjAoyc.png&ehk=ekPZKDonspqQaibYUd0APp4mV2bUfx9IuplBvX831cI%3d&risl=&pid=ImgRaw&r=0"
                                       }
                                       style={{
                                          height: "25px",
                                          width: "25px",
                                          objectFit: "cover",
                                          borderRadius: "50%",
                                       }}
                                       alt="media"
                                    />
                                 ) : (
                                    <SocialIcon
                                       network={data.Icon}
                                       url="#"
                                       style={{ height: "25px", width: "25px" }}
                                    />
                                 )}
                              </div>
                              <div className="col-span-8 flex items-center justify-center">
                                 <p className="text-[#fff] font-semibold text-[13px]">
                                    {data.name}
                                 </p>
                              </div>
                           </div>
                        ))}
                     </div>
                  </section>
               </div>
            <Footer />
         </div> */}

      {/* MobileLoginPage */}
      <div className=" bg-white w-full h-full ">
        <div className="h-[100%] py-4">
          <div className="absolute top-0 w-full flex justify-center">
            {/* <p className="text-red-700 fon-bold text-[16px] md:text-[12px]">
              CC REGISTER-1 - FE - V3 - APRIL 30, 2024
            </p> */}
            <p className="text-red-700 fon-bold text-[16px] md:text-[12px]">
              Copyright © 2024 Galileo One Inc
            </p>
          </div>
          <div className="flex flex-col justify-center items-center">
            <p className=" text-[#000] text-[16px] font-bold">
              Signup to Chaa Chingg
            </p>
            <img src={ccLogo} className="h-28 w-28" alt="logo" />
            <p className="text-[16px] font-bold">Watch Create Enjoy</p>
          </div>
          <div className="w-full h-[65%] p-3 md:p-2 flex flex-col items-center justify-center bg-[#fff]">
            <div className=" bg-[#505052] h-[10%] w-[80%] m-[2%] p-1 flex justify-around items-center cursor-pointer relative rounded-md">
              <div
                className=" text-[#fff] text-[14px] md:text-[12px] font-bold"
                onClick={() => handleClick("register")}
              >
                <p>Phone Number / Email / Username</p>
              </div>
            </div>


            <div className="bg-[#505052] h-12 w-[80%] m-[2%] p-1 flex justify-around items-center cursor-pointer relative rounded-md">
              <div className="absolute left-4">

                <img
                  src={
                    chirpLogo
                  }
                  alt="media"

                  className="h-[25px] w-[25px] rounded-[50%] object-cover "
                />

              </div>
              <div className="font-bold text-[14px] md:text-[12px] text-[#fff]">
                <p>

                  Continue with Chirp
                </p>
              </div>
            </div>

            <div className="bg-[#505052] h-12 w-[80%] m-[2%] p-1 flex justify-around items-center cursor-pointer relative rounded-md">
              <div className="absolute left-4">

                <img
                  src={KvLogo}
                  alt="media"

                  className="h-[25px] w-[25px] rounded-[50%] object-cover"
                />

              </div>
              <div className="font-bold text-[14px] md:text-[12px] text-[#fff]">
                <p>

                  Continue with KV
                </p>
              </div>
            </div>


            {loginPageDomainName?.map((item) => (
              <div className="bg-[#505052] h-12 w-[80%] m-[2%] p-1 flex justify-around items-center cursor-pointer relative rounded-md">
                <div className="absolute left-4">
                  {item.Icon === "apple" ? (
                    <img
                      src={
                        "https://th.bing.com/th/id/R.d8426cb195ac7b393f853110480f0d34?rik=KA%2fqJN5JGzzYQQ&riu=http%3a%2f%2fwww.clipartbest.com%2fcliparts%2fRTA%2fEjA%2fRTAEjAoyc.png&ehk=ekPZKDonspqQaibYUd0APp4mV2bUfx9IuplBvX831cI%3d&risl=&pid=ImgRaw&r=0"
                      }
                      alt="media"

                      className="h-[25px] w-[25px] rounded-full  object-cover "
                    />
                  ) : (
                    <SocialIcon
                      network={item.Icon}
                      url="#"
                      style={{ height: "25px", width: "25px" }}
                    />
                  )}
                </div>
                <div className="font-bold text-[14px] md:text-[12px] text-[#fff]">
                  <p>{item.name}</p>
                </div>
              </div>
            ))}

          </div>
          <div className="flex justify-center items-center">
            <p className="text-[13px] text-blue-700 cursor-pointer font-bold flex gap-2 items-center underline" onClick={() => navigate(-1)}> <IoMdArrowBack /> Go Back</p>
          </div>
        </div>
        {/* <SecretSauceButton /> */}
        {/* <div className="flex justify-center items-center  bg-[black] w-full h-[8%] md:h-[7%]">
          <button className="w-[30%] rounded-[2rem] bg-[#D9D9DE] border-none font-bold text-[16px] cursor-pointer px-3 py-1" onClick={() => handleClick("login")}>Login</button>
        </div> */}
      </div>
    </>
  );
}

