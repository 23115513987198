import React from "react";
import LeftSide from "../ShareHomePage/LeftSide";
import RightSide from "../ShareHomePage/RightSide";
import { Drawer } from "@material-tailwind/react";

export default function Share({ closeDrawerBottom, openBottom, videoUrl , downloadVideo }) {

   return (<>
      <Drawer
         placement="bottom"
         open={openBottom}
         onClose={closeDrawerBottom}
         size={270}
         className={`px-4  absolute w-full ${!openBottom && "hidden"}`}
         style={{ zIndex: 1 }}
      >
         <div className="">
            <h2 className="text-[red] felx justify-center text-center font-bold text-sm uppercase">
            Copyright © 2024 Galileo One Inc
            </h2>
            {/* <h2 className="text-[red] felx justify-center text-center font-bold text-sm uppercase">
               CC / SHARE PAGE / FE  /V-1/oct 11 /2024
            </h2> */}
         </div>
         <div className="grid grid-cols-6">
            <div className="col-span-5 h-full border-r-[1px] border-black">
               <LeftSide />
            </div>
            <div className="col-span-1 mt-4 pl-5">
               <RightSide videoUrl={videoUrl} downloadVideo={downloadVideo} />
            </div>
         </div>
      </Drawer>
   </>
   );
}
