import React from "react";
import Ticker from "../../../Component/Ticker";
import footerLogo from "../../../Assets/logo/footer_logo.png";

export default function SecretSauceButton() {
  return (
    // <div className="souce-button h-[8%] relative bg-[#272727] flex justify-evenly items-center  w-full border-b-2 border-t-2">
    <div className="souce-button h-[9%] grid grid-cols-12 items-center text-white bg-[#272727] pl-0 px-2 py-1">
      <div className="col-span-10 flex justify-center items-center -ml-2">
        <Ticker />
      </div>
      <div className="col-span-2 flex items-center justify-end">
        <img
          src={footerLogo}
          alt="logo"
          className="object-cover cursor-pointer w-[3rem] bg-[#38B6FF] rounded-lg px-[4px] pt-[2px] pb-[1px]"
        />
      </div>
      {/* </div> */}
    </div>
  );
}
