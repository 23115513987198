import React, { useEffect, useRef, useState } from "react";

import { Drawer } from "@material-tailwind/react";
import { FaCircleArrowRight } from "react-icons/fa6";
import toast, { Toaster } from "react-hot-toast";
import { FaRegCopy } from "react-icons/fa";
import {
   EmailShareButton,
   FacebookShareButton,
   InstapaperShareButton,
   LinkedinShareButton,
   TelegramShareButton,
   TumblrShareButton,
   TwitterShareButton,
   WhatsappShareButton,
} from "react-share";

import Carousel from "react-multi-carousel";
import { SocialIcon } from "react-social-icons";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";

const responsive = {
   desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1,
   },
   tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1,
   },
   mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
   },
};

export default function ShareModal({ openBottom, closeDrawerBottom }) {
   const [next, setNext] = React.useState(0);
   const carouselRef = useRef(null);
   const [copySuccess, setCopySuccess] = useState(false);
   const textAreaRef = useRef(null);

   const handleNext = () => {
      setNext(next + 1);
      if (carouselRef.current) {
         carouselRef.current.next();
      }
   };
   const handlePrevious = () => {
      if (carouselRef.current) {
         carouselRef.current.previous();
      }
   };

   const copyToClipboard = (text) => {
      navigator.clipboard
         .writeText(text)
         .then(() => {
            setCopySuccess(true);
            toast.success("successful copy");
         })
         .catch((err) => {
            setCopySuccess(false);
            toast.success("failer to copy");
         });
   };

   useEffect(() => {
      setNext(0);
      return () => {
         handlePrevious();
         setCopySuccess(false);
      };
   }, [closeDrawerBottom]);

   return (
      <>
         <Toaster position="top-right" />
         <Drawer
            placement="bottom"
            open={openBottom}
            onClose={closeDrawerBottom}
            size={140}
            className={`px-4 absolute w-full ${!openBottom && "hidden"}`}
            style={{ zIndex: 1 }}
         >
            <div className="">
               <h2 className="text-[red] felx justify-center text-center font-bold text-sm">
               Copyright © 2024 Galileo One Inc
               </h2>
               {/* <h2 className="text-[red] felx justify-center text-center font-bold text-sm">
                  CC SHARE PAGE /FE V-2/JUNE 20/2024
               </h2> */}
            </div>
            <div className="grid grid-cols-6">
               <div className="col-span-6 h-full relative">
                  {next !== 1 && (
                     <div className="absolute z-10 right-0 top-10">
                        <div className="flex justify-between items-center mt-4">
                           <FaCircleArrowRight onClick={handleNext} />
                        </div>
                     </div>
                  )}
                  <Carousel
                     ref={carouselRef}
                     swipeable={true}
                     draggable={true}
                     showDots={false}
                     responsive={responsive}
                     ssr={true}
                     infinite={false}
                     arrows={false}
                     renderButtonGroupOutside={true}
                     renderDotsOutside={false}
                     className="w-full mt-2 flex"
                  >
                     <div className="flex flex-wrap gap-y-4 gap-x-4">
                        {/* {shareSocialIcons.map((data) => ( */}
                        <div className="flex flex-col justify-center items-center  ">
                           <FacebookShareButton
                              url={"https://thtsusa.com/"}
                              quote={"title"}
                           >
                              <SocialIcon
                                 network={"facebook"}
                                 url="#"
                                 style={{ height: "40px", width: "40px" }}
                              />
                           </FacebookShareButton>
                        </div>
                        <div className="flex flex-col justify-center items-center  ">
                           <WhatsappShareButton
                              url={"https://thtsusa.com/"}
                              quote={"title"}
                           >
                              <SocialIcon
                                 network={"whatsapp"}
                                 url="#"
                                 style={{ height: "40px", width: "40px" }}
                              />
                           </WhatsappShareButton>
                        </div>
                        <div className="flex flex-col justify-center items-center  ">
                           <InstapaperShareButton
                              url={"https://thtsusa.com/"}
                              quote={"title"}
                           >
                              <SocialIcon
                                 network={"instagram"}
                                 url="#"
                                 style={{ height: "40px", width: "40px" }}
                              />
                           </InstapaperShareButton>
                        </div>
                        <div className="flex flex-col justify-center items-center  ">
                           <TwitterShareButton
                              url={"https://thtsusa.com/"}
                              title={"title"}
                           >
                              <SocialIcon
                                 network={"x"}
                                 url="#"
                                 style={{ height: "40px", width: "40px" }}
                              />
                           </TwitterShareButton>
                        </div>

                        <div className="flex flex-col justify-center items-center  ">
                           <LinkedinShareButton url={"https://thtsusa.com/"}>
                              <SocialIcon
                                 network={"linkedin"}
                                 url="#"
                                 style={{ height: "40px", width: "40px" }}
                              />
                           </LinkedinShareButton>
                        </div>

                        <div className="flex flex-col justify-center items-center  ">
                           <TelegramShareButton
                              url="https://thtsusa.com/"
                              title={"https://thtsusa.com/"}
                           >
                              <SocialIcon
                                 network={"telegram"}
                                 url="#"
                                 style={{ height: "40px", width: "40px" }}
                              />
                           </TelegramShareButton>
                        </div>

                        <div className="flex flex-col justify-center items-center  ">
                           <EmailShareButton url={"https://thtsusa.com/"}>
                              <SocialIcon
                                 network={"email"}
                                 url="#"
                                 style={{ height: "40px", width: "40px" }}
                              />
                           </EmailShareButton>
                        </div>

                        <div className="flex flex-col justify-center items-center  ">
                           <TumblrShareButton url={"https://thtsusa.com/"}>
                              <SocialIcon
                                 network={"tumblr"}
                                 url="#"
                                 style={{ height: "40px", width: "40px" }}
                              />
                           </TumblrShareButton>
                        </div>
                        <div className=" cols-span-2">
                           <img
                              alt="img01"
                              className="h-[40px] w-[40px]  object-cover rounded-full"
                              src="https://images.unsplash.com/photo-1438761681033-6461ffad8d80?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                           />
                        </div>
                        <div className="cols-span-2 ">
                           <img
                              alt="img02"
                              className="h-[40px] w-[40px]  object-cover rounded-full"
                              src="https://images.unsplash.com/photo-1500336624523-d727130c3328?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                           />
                        </div>
                        {/* ))} */}
                     </div>
                     <div className="flex flex-wrap gap-4">
                        <div className="cols-span-2 ">
                           <img
                              alt="img01"
                              className="h-[40px] w-[40px]  object-cover rounded-full"
                              src="https://images.unsplash.com/photo-1529626455594-4ff0802cfb7e?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                           />
                        </div>
                        <div className="cols-span-2  ">
                           <img
                              alt="img01"
                              className="h-[40px] w-[40px]  object-cover rounded-full"
                              src="https://images.unsplash.com/photo-1615109398623-88346a601842?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fG1hbnxlbnwwfHwwfHx8MA%3D%3D"
                           />
                        </div>
                        <div className="cols-span-2  ">
                           <img
                              alt="img01"
                              className="h-[40px] w-[40px]  object-cover rounded-full"
                              src="https://images.unsplash.com/photo-1568602471122-7832951cc4c5?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8bWFufGVufDB8fDB8fHww"
                           />
                        </div>
                        <div className="cols-span-2  ">
                           <img
                              alt="img01"
                              className="h-[40px] w-[40px]  object-cover rounded-full"
                              src="https://images.unsplash.com/photo-1599566150163-29194dcaad36?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTl8fHBlcnNvbnxlbnwwfHwwfHx8MA%3D%3D"
                           />
                        </div>
                        <div className="cols-span-2  ">
                           <img
                              alt="img01"
                              className="h-[40px] w-[40px]  object-cover rounded-full"
                              src="https://images.unsplash.com/photo-1438761681033-6461ffad8d80?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8cGVyc29ufGVufDB8fDB8fHww"
                           />
                        </div>

                        <div className="cols-span-2 w-full   gap-x-4 ">
                           <div className="flex justify-between">
                              <input
                                 ref={textAreaRef}
                                 disabled
                                 value="https://thtsusa.com/"
                                 className="bg-gray-200 p-2 text-blue-900  rounded-md w-full"
                              />
                              {/* {document.queryCommandSupported("copy") && ( */}
                              <div className="flex justify-center item-center pl-2">
                                 {!copySuccess && (
                                    <button
                                       onClick={() =>
                                          copyToClipboard("https://thtsusa.com/")
                                       }
                                    >
                                       <FaRegCopy className="text-[1.5rem]" />
                                    </button>
                                 )}

                                 {copySuccess && (
                                    <button>
                                       <IoMdCheckmarkCircleOutline className="text-[1.5rem]" />
                                    </button>
                                 )}
                              </div>
                              {/* )} */}
                           </div>
                        </div>
                     </div>
                  </Carousel>
               </div>
            </div>
         </Drawer>
      </>
   );
}
