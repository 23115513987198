import React, { useState } from "react";
import { FaShare } from "react-icons/fa";
import { IoSend } from "react-icons/io5";
import { MdReport } from "react-icons/md";
import { BiSolidLike } from "react-icons/bi";
import { useSelector } from "react-redux";
import { RiUserFollowFill } from "react-icons/ri";
// import { homeVideoFootage } from "../Datas/Datas";
import ShareModal from "../Component/Modals/ShareModal";
import { SlArrowDown, SlArrowUp } from "react-icons/sl";
import Streaming from "../Component/LIveStream/Streaming";

export default function LiveStreamPage() {
   const commentsData = [
      {
         icon: "https://plus.unsplash.com/premium_photo-1664199486587-37f325d15182?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
         text: "what a flower it is nice to",
      },
      {
         icon: "https://images.unsplash.com/photo-1548365108-908055adb1c8?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
         text: "Hey John!",
      },
      {
         icon: "https://images.unsplash.com/photo-1458134580443-fbb0743304eb?q=80&w=1925&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
         text: "what is this ?",
      },
      {
         icon: "https://images.unsplash.com/photo-1529672425113-d3035c7f4837?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
         text: "I really like this .........",
      },
   ];

   const ProfileDetails = useSelector((state) => state.AccountProfile);
   const { User_profile } = ProfileDetails;
   const [commants, setCommants] = useState(commentsData);
   const [openBottom, setOpenButtom] = useState(false);
   const [inputValue, setInputValue] = useState("");
   const handleOpenshareModal = () => setOpenButtom(!openBottom);
   const [showMore, setShowMore] = useState(false);

   const handlecommant = (e) => {
      setInputValue(e.target.value);
   };

   const handleSubmitCommant = () => {
      setCommants([...commants, { text: inputValue }]);
      setInputValue("");
   };

   // const getRandomVideo = () => {
   //   const randomIndex = Math.floor(Math.random() * homeVideoFootage.length);
   //   return homeVideoFootage[randomIndex];
   // };

   // const randomVideo = getRandomVideo();

   return (
      <div className="video-item  w-full h-full absolute overflow-hidden">
         <div className="absolute top-1 w-full flex justify-center items-center">
            <div className="rounded px-[4px] py-[2px] text-[12px] font-bold text-center bg-white ">
               1.5k watching
            </div>
         </div>
         <div className="absolute flex justify-between w-full px-3 top-5 z-50">
            <div className="flex items-center gap-2">
               <img
                  alt="avatar"
                  src={`${User_profile.avatar}`}
                  class="relative inline-block h-7 w-7 !rounded-full  object-cover object-center"
               />
               <p className="text-[12px] font-bold text-[#fff]">
                  @{User_profile.first_name} {User_profile.last_name}
               </p>
            </div>
            <div className="">
               <div className="flex flex-col items-center mb-4" onClick={handleOpenshareModal}>
                  <FaShare className="text-[1rem] text-[#fff] cursor-pointer" />
                  <p className="m-0 text-[#fff] font-bold text-[9px] cursor-pointer">
                     share
                  </p>
               </div>
               <div className="flex flex-col items-center">
                  <RiUserFollowFill className="text-[1rem] text-[#fff] cursor-pointer" />
                  <p className="m-0 text-[#fff] font-bold text-[9px] cursor-pointer">
                     Follow
                  </p>
               </div>
            </div>
         </div>

         {/* <h6 className="text-red-600 absolute top-20 w-full flex justify-center text-[10px] font-bold uppercase">
            CC / LIVE STREAM /V2 /oct 11,2024
         </h6> */}
         <h6 className="text-red-600 absolute top-20 w-full flex justify-center text-[10px] font-bold uppercase">
         Copyright © 2024 Galileo One Inc
         </h6>

         <div className="absolute bottom-2 w-full gap-2 flex justify-between h-2/4 px-3 z-50">
            <div className="flex flex-col justify-end w-[60%] ">
               <div className="flex justify-end mb-2">
                  {showMore ? (
                     <SlArrowUp
                        color="white"
                        className="text-[14px]"
                        onClick={() => setShowMore(!showMore)}
                     />
                  ) : (
                     <SlArrowDown
                        color="white"
                        className="text-[14px]"
                        onClick={() => setShowMore(!showMore)}
                     />
                  )}
               </div>
               <div className={`${showMore ? "h-8 overflow-hidden" : "overflow-scroll"} items-end  no-scrollbar`}>
                  {!showMore ? (
                     <>
                        {commants?.map((item) => (
                           <div className="flex items-center mb-[6px] gap-[4px]">
                              <img
                                 alt="avatar"
                                 src={item.icon ? item.icon : `${User_profile.avatar}`}
                                 class="relative inline-block h-7 w-7 rounded-full object-cover object-center"
                              />
                              <p className="text-[11px] text-[#505050]">
                                 {item.text}
                              </p>
                           </div>
                        ))}
                     </>
                  ) : (
                     <div className="flex items-center mb-[6px] gap-[4px]">
                        <img
                           alt="avatar"
                           src={commants[0].icon ? commants[0].icon : `${User_profile.avatar}`}
                           class="relative inline-block h-7 w-7 rounded-full object-cover object-center"
                        />
                        <p className="text-[11px] text-[#505050]">
                           {commants[0].text}
                        </p>
                     </div>
                  )}
               </div>
               <div className="relative">
                  <input
                     type="text"
                     placeholder="comments..."
                     onChange={handlecommant}
                     value={inputValue}
                     className="focus:border-0 outline-none rounded-[6px] w-full pl-2 pr-7 py-[2px]"
                  />
                  <IoSend
                     color="black"
                     onClick={handleSubmitCommant}
                     className="absolute right-[4px] text-[1rem] top-1/2 transform -translate-y-1/2 cursor-pointer"
                  />
               </div>
            </div>
            <div className="flex justify-center items-end gap-4">
               <BiSolidLike className="text-[1.5rem] text-white cursor-pointer" />
               <MdReport className="text-[1.5rem] text-white cursor-pointer" />
            </div>
         </div>
         <div className="h-full w-full object-cover">
            {/* <Streaming /> */}
            {/* <video
               autoPlay
               controls
               controlslist="nodownload noplaybackrate"
               type={"video/mp4"}
               aria-hidden
               disablePictureInPicture
               disablePlayback
               disableRemotePlayback
            >
               <source src={randomVideo.footage}></source>
            </video> */}
         </div>
         <div className="">
            <ShareModal
               openBottom={openBottom}
               closeDrawerBottom={handleOpenshareModal}
            />
         </div>
      </div>
   );
}
