import React, { useState } from "react";
import { Button, Select, Option, Typography } from "@material-tailwind/react";
import { useDispatch, useSelector } from "react-redux";
import {
  createFolder,
  SavedTheVideoInsideFolder,
} from "../../../Redux/ApiSlice/SavedVideosSlice";

export default function SaveModal(props) {
  const {
    handleClose,
    videoId,
    fetchData,
    saveRef,
  } = props
  const dispatch = useDispatch();
  const [createFolderShow, setCreateFolderShow] = useState(false);
  const [createFolderName, setCreateFolderName] = useState("");
  const [selectedFolder, setSelectedFolder] = useState("");
  const [selectId, setSelectId] = useState(null);
  const ProfileDetails = useSelector((state) => state.AccountProfile);
  const savedVideo = useSelector((state) => state.savedVideos);
  const { SavedFolderList } = savedVideo;
  const [error, setError] = useState(null);
  // const { User_profile } = ProfileDetails;

  const handleChange = (e) => {
    setCreateFolderName(e.target?.value);
  };
  const handleSelect = (e) => {
    console.log(e.target?.value);
    if (e.target?.value) {
      setSelectedFolder(e.target?.value);
      setError(null)
    }
  };

  const handleCreateFolder = async (value) => {
    if (value) {
      await dispatch(createFolder({ name: value, userId: ProfileDetails?.User_profile?.id }));
      fetchData();
      setCreateFolderName("");
      setCreateFolderShow(false);
    } else {
      return null;
    }
  };
  const handleSubmit = async () => {
    if (createFolderShow) {
      handleCreateFolder(createFolderName);
    } else {
      if (selectId) {
        const responce = await dispatch(SavedTheVideoInsideFolder({ folderId: selectId, videoId }));
        console.log(responce, "responce");
        if (responce.error) {
          setError("already saved");
        } else {
          handleClose();
        }
      }
    }
  };

  return (
    <div
      className={`${error ? "h-44" : "h-40"} w-72 bg bg-white px-6 py-2 z-50 rounded-md relative`}
      ref={saveRef}
    >
      <p className="text-[12px] text-red-800 font-bold absolute uppercase top-0 w-full text-start">
      Copyright © 2024 Galileo One Inc      </p>
      {/* <p className="text-[12px] text-red-800 font-bold absolute uppercase top-0 w-full text-start">
        CC /SAVE VIDEO POPUP /FE / V1 / oct 11 , 2024
      </p> */}
      <div className="flex justify-between mt-2">
        <p className="font-bold text-[12px] md:text-[14px]">
          {" "}
          {createFolderShow ? "Enter Folder Name" : "Select Folder"}
        </p>
        <p
          className="text-[#00A3FF] text-[12px] md:text-[14px] font-bold cursor-pointer"
          onClick={() => setCreateFolderShow(!createFolderShow)}
        >
          {createFolderShow ? (
            <span className="text-[12px] text-red-700 font-bold cursor-pointer">
              Cancel
            </span>
          ) : (
            "Create Folder"
          )}
        </p>
      </div>
      <div className="pb-0 relative mt-4">
        {createFolderShow ? (
          <Typography className="w-full mt-1">
            <input
              placeholder=""
              className="outline-none bg-[#D9D9D9] p-2 w-full rounded-md"
              onChange={handleChange}
              value={createFolderName}
            />
          </Typography>
        ) : (
          <>
            <select
              value={selectedFolder}
              onChange={(e) => {
                handleSelect(e);
                const selectedItem = SavedFolderList.find(item => item.name === e.target.value);
                if (selectedItem) {
                  setSelectId(selectedItem.id);
                }
              }}
              id="underline_select"
              className="block py-2.5 px-0 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-200 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
            >
              <option value="" disabled>Select Folder</option>
              {(SavedFolderList || []).map((item) => (
                <option
                  className="font-bold text-[12px] text-black px-2"
                  key={item.id}
                  value={item.name}
                >
                  {item.name}
                </option>
              ))}
              {SavedFolderList?.length === 0 && (
                <option className="font-bold text-base">
                  No folder found
                </option>
              )}
            </select>
            <p className="text-red-700 text-[12px]">
              {error}
            </p>
          </>



          // <Select
          //   label="Folder List "
          //   className="font-bold text-base"
          //   value={selectedFolder}
          //   onChange={handleSelect}
          // >
          //   {SavedFolderList?.map((item) => (
          //     <Option
          //       className="font-bold text-base"
          //       key={item.id}
          //       onClick={() => setSelectId(item.id)}
          //     >
          //       {item.name}
          //     </Option>

          //   ))}
          //   {SavedFolderList?.length === 0 &&
          //     <Option className="font-bold text-base">
          //       No folder found
          //     </Option>}
          // </Select>
        )}
      </div>
      <div className="flex justify-around mt-4">
        <Button
          onClick={() => handleSubmit()}
          className="bg-[#D9D9D9] text-[black] p-2 w-2/5"
        >
          <span>Save</span>
        </Button>
        <Button
          className="bg-[#D9D9D9] text-[black] p-2 w-2/5"
          onClick={() => handleClose()}
        >
          <span>Cancel</span>
        </Button>
      </div>
    </div>
  );
}
