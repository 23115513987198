import { Fragment } from 'react'

export default function WelcomeModal({ onClose }) {
    const handleClose = () => {
        onClose(false)
        localStorage.setItem("new_User", false);
    }

    return (
        <div className="relative full h-full flex justify-center items-center">
            <div className="bg-gray-600 opacity-10 absolute top-0 h-full w-full" onClick={() => handleClose()} >
            </div>
            <div className='h-auto w-[60%]'>
                <div className='bg-white h-auto rounded-lg flex justify-center items-center flex-col p-4'>
                    <h2 className='text-[16px] font-bold'>
                        Welcome To CC
                    </h2>

                    <p className='text-[12px] text-center'>
                        Welcome to CC! 🚀 Your journey starts here. Discover endless possibilities, crafted just for you. Let’s make something amazing together!
                    </p>
                </div>
            </div>
        </div>
    )
}


