import React, { useState, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { IoArrowBackCircleSharp } from "react-icons/io5";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { IconButton, Drawer, Card } from "@material-tailwind/react";
import { FiGrid, FiHeart, FiFilm, FiTool } from 'react-icons/fi';

const SearchSideBar = () => {
   const [isDrawerOpen, setIsDrawerOpen] = useState(false);
   const navigate = useNavigate();

 
   const openDrawer = () => setIsDrawerOpen(true);
   const closeDrawer = () => setIsDrawerOpen(false);

   const categories = [
      { name: 'All', icon: <FiGrid /> },
      { name: 'Favorites', icon: <FiHeart /> },
      { name: 'Movies', icon: <FiFilm /> },
      { name: 'Technology', icon: <FiTool /> }
   ];

   return (
      <Fragment>
         <div className="flex items-center w-full justify-between px-2 -mt-1">
            <button>
               <IoArrowBackCircleSharp
                  color="white"
                  onClick={() => navigate(-1)}
                  className="h-[1.4rem] w-[1.4rem] z-30"
               />
            </button>
            <IconButton variant="text" size="sm" onClick={openDrawer}>
               {isDrawerOpen ? (
                  <XMarkIcon className="h-6 w-6 stroke-2 text-[#fff] " />
               ) : (
                  <Bars3Icon className="h-6 w-6 stroke-2 text-[#fff] " />
               )}
            </IconButton>
         </div>
         <div className="bg-gray-800 w-full h-[0.5px]"></div>
         <Drawer
            open={isDrawerOpen}
            onClose={closeDrawer}
            placement="right"
            className="absolute overflow-y-scroll w-[70%]"
         >
            <Card
               color="transparent"
               shadow={false}
               className="overflow-y-scroll no-scrollbar rounded-none"
            >
               <div className="pt-8">
                  {
                     categories.map((category) => (
                        <div onClick={() => closeDrawer()} className="px-3 py-2 flex justify-start items-center gap-[6px] transition ease-in-out border-b border-gray-300 hover:scale-105 duration-300 hover:bg-[#d9d9d9] hover:text-black">
                           {category.icon}
                           <p className="cursor-pointer">{category.name}</p>
                        </div>
                     ))
                  }
               </div>
            </Card>
            <p className="text-[red] text-[9px] font-bold absolute top-1 w-full text-center">
            Copyright © 2024 Galileo One Inc
            </p>
            {/* <p className="text-[red] text-[9px] font-bold absolute top-1 w-full text-center">
               CC SEARCH SIDEBAR, FE, V4, SEP 15, 2024
            </p> */}
         </Drawer >
      </Fragment >
   );
};

export default SearchSideBar;
