import React, { useEffect, useRef, useState } from "react";
import AccountProfileSection from "../../Component/AccountPage/AccountProfileSection";
import AccountTab from "../../Component/AccountPage/AccountTab";
import { FaRegEye, FaSmile } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  deleteSavedVideo,
  getIndividualFolderData,
} from "../../Redux/ApiSlice/SavedVideosSlice";
import { Avatar } from "@material-tailwind/react";
import { IoArrowBackCircle } from "react-icons/io5";
import ThreeDotsSpinner from "../../Common/ThreeDotsSpinner";
import { TbDotsVertical } from "react-icons/tb";
import VideoCard from "../../Common/VideoCard";
import useSelectVideo from "../../Hooks/useSelectVideo";

export default function SavedFolderData() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectVideo = useSelectVideo();
  const { id } = useParams();
  const savedVideo = useSelector((state) => state.savedVideos);
  const { loading, FolderData } = savedVideo;
  const { videos } = FolderData;

  const menuRef = useRef(null);
  const [postId, setpostId] = useState(null);

  const fetchData = async () => {
    await dispatch(getIndividualFolderData(id));
  };
  const deleteFolderVideo = async (postId) => {
    await dispatch(deleteSavedVideo({ folder_id: id, post_id: postId }));
    fetchData();
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setpostId(null); // Close menu if clicked outside
    }
  };

  const handleSelectVideo = async (id) => {
    selectVideo(id, videos);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="bg-[#000] overflow-hidden h-full relative ">
      {/* <p className="text-[#ff3b3b] text-center w-full mb-2  text-[12px] font-[700] flex justify-center z-50 lg:text-[10px] uppercase">
        CC ACCOUNT saved folder data PAGE-FE - V10 /MAR 25,2024
      </p> */}
      <p className="text-[#ff3b3b] text-center w-full mb-2  text-[12px] font-[700] flex justify-center z-50 lg:text-[10px] uppercase">
      Copyright © 2024 Galileo One Inc
      </p>
      <div className="">
        <AccountProfileSection />
      </div>
      <AccountTab activeTab={"Saved"} />
      <div className="flex items-center p-2">
        <IoArrowBackCircle
          color="white"
          className="cursor-pointer text-[1.5rem]"
          onClick={() => navigate(-1)}
        />
      </div>
      <div className="p-2 pt-0 overflow-y-scroll no-scrollbar absolute w-full h-[-webkit-fill-available]">
        {loading && <ThreeDotsSpinner />}
        {videos?.length ? (
          <div className="flex pt-[2%] flex-wrap overflow-scroll no-scrollbar  p-1 ">
            {/* {videos?.map((item) => (
              <div className="w-[47%]" key={item.id}>
                <div className="h-[23vh] w-[100%] relative ">
                  <div className="w-full h-full">
                    <video
                      autoPlay
                      src={`https://thtsusa.com/user_data/media${item.video}`}
                      className="h-full w-full object-cover"
                    />

                    <TbDotsVertical
                      className="absolute right-0 top-2 font-bold cursor-pointer z-50 w-6 h-86"
                      color="white"
                      onClick={() => setpostId(item.id)}
                    />
                    {postId === item.id && (
                      <div
                        ref={menuRef}
                        className="absolute text-[#000] font-bold bg-[#D9D9D9] px-2 py-1  text-[15px] right-2 top-6 z-50"
                      >
                        <p
                          className="flex items-center text-[10px] p-1 cursor-pointer"
                          onClick={() => deleteFolderVideo(item.id)}
                        >
                          Delete
                        </p>
                      </div>
                    )}
                  </div>
                  <div className="post-inner-div grid ">
                    <div className="total-likes-views ">
                      <FaRegEye color="white" />
                      <span>{item.comments_count}</span>
                    </div>
                    <div className="total-likes-views ">
                      <FaSmile color="yellow" />
                      <span>{item.likes_count}</span>
                    </div>
                  </div>
                </div>
                <div className="flex items-center ">
                  <div className="h-5 w-5 bg-neutral-500 rounded-full">
                    <Avatar
                      src={`${item.created_by.avatar_url}`}
                      className="h-5 w-5 object-cover"
                    />
                  </div>
                  <p className="font-medium text-[8px] text-[#fff] pl-1 line-clamp-2">
                    {" "}
                    {item?.body}
                  </p>
                </div>
              </div>
            ))} */}
            <div className="grid grid-cols-2 pt-1 gap-3 overflow-scroll no-scrollbar  px-2">
              {videos?.map((item) => (
                <VideoCard
                  // videosList={hashTagList}
                  belowDecs={true}
                  threeDots={false}
                  id={item.id}
                  video={item.video_url}
                  likes_count={item.likes_count}
                  avatar_url={item.created_by.avatar_url}
                  // handleDeletePost={handleDeletePost}
                  body={item.body}
                  views={item.views}
                  selectVideo={handleSelectVideo}
                />
              ))}
            </div>
          </div>
        ) : (
          <div className="h-full w-full flex justify-center items-center">
            <p className="text-[12px] text-gray-500 font-bold">EMPTY FOLDER</p>
          </div>
        )}
      </div>
    </div>
  );
}
