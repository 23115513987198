import React, { useState } from "react";
import { IconButton, Typography, List, ListItem, ListItemPrefix, ListItemSuffix, Chip, Accordion, AccordionHeader, AccordionBody, Drawer, Card } from "@material-tailwind/react";
import { PresentationChartBarIcon, ShoppingBagIcon, UserCircleIcon, Cog6ToothIcon, InboxIcon, PowerIcon } from "@heroicons/react/24/solid";
import { ChevronRightIcon, ChevronDownIcon, MagnifyingGlassIcon, Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { useLocation, useNavigate } from "react-router-dom";
import { PiBasketFill } from "react-icons/pi";

import { GiEternalLove } from "react-icons/gi";
import { MdFavorite } from "react-icons/md";
import { IoIosSave } from "react-icons/io";
import { MdOutlinePayment } from "react-icons/md";
import { IoIosHelpCircle } from "react-icons/io";

const SideBar = () => {
   const [open, setOpen] = useState(0);
   const [isDrawerOpen, setIsDrawerOpen] = useState(false);
   const navigate = useNavigate();
   const location = useLocation();

   const handleOpen = (value) => {
      setOpen(open === value ? 0 : value);
   };

   const openDrawer = () => setIsDrawerOpen(true);
   const closeDrawer = () => setIsDrawerOpen(false);

   return (
      <>
         <button onClick={openDrawer}>
            {isDrawerOpen ? (
               <XMarkIcon className={`h-6 w-6 stroke-2 ${location.pathname === "/wishlist" ? "text-[#000]" : "text-[#fff]"}`} />
            ) : (
               <Bars3Icon className={`h-6 w-6 stroke-2 ${location.pathname === "/wishlist" ? "text-[#000]" : "text-[#fff]"}`} />
            )}
         </button>
         <Drawer
            open={isDrawerOpen}
            onClose={closeDrawer}
            placement="right"
            className="absolute overflow-y-scroll w-4/5"
            style={{ zIndex: 1, height: "100vh", maxHeight: "100vh" }}
         >
            <Card
               color="transparent"
               shadow={false}
               className="overflow-scroll no-scrollbar"
               style={{ zIndex: 1, height: "100vh", maxHeight: "100vh" }}
            >
               <p className="text-[red] text-[8px] font-bold absolute top-0 w-full text-center">
               Copyright © 2024 Galileo One Inc               </p>
               {/* <p className="text-[red] text-[8px] font-bold absolute top-0 w-full text-center">
                  CC SHOP HOME PAGE SIDEBAR , FE , V2 , APR 10 ,2024
               </p> */}
               <div className="mb-2 flex items-center gap-4 p-4 overflow-scroll no-scrollbar">
                  <Typography
                     variant="h5"
                     color="blue-gray"
                     onClick={() => navigate("/shop")}
                  >
                     LOGO
                  </Typography>
               </div>
               <div className="p-2 relative">
                  <MagnifyingGlassIcon className="h-5 w-5 absolute top-[17px] left-[12px] cursor-pointer" />
                  <input
                     type="text"
                     placeholder="Search"
                     className="border border-[#d9d9d9] w-full pl-7 pr-2 py-[0.4rem] focus:outline-none rounded-[5px]"
                  />
               </div>
               <List>
                  <Accordion
                     open={open === 1}
                     icon={
                        <ChevronDownIcon
                           strokeWidth={2.5}
                           className={`mx-auto h-4 w-4 transition-transform ${open === 1 ? "rotate-180" : ""}`}
                        />
                     }
                  >
                     <ListItem className="p-0" selected={open === 1}>
                        <AccordionHeader
                           onClick={() => handleOpen(1)}
                           className="border-b-0 p-3"
                        >
                           <ListItemPrefix>
                              <PresentationChartBarIcon className="h-5 w-5" />
                           </ListItemPrefix>
                           <Typography color="blue-gray" className="mr-auto font-normal">
                              Dashboard
                           </Typography>
                        </AccordionHeader>
                     </ListItem>
                     <AccordionBody className="py-1">
                        <List className="p-0">
                           <ListItem>
                              <ListItemPrefix>
                                 <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                              </ListItemPrefix>
                              SubMenu 01
                           </ListItem>
                           <ListItem>
                              <ListItemPrefix>
                                 <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                              </ListItemPrefix>
                              SubMenu 02
                           </ListItem>
                           <ListItem>
                              <ListItemPrefix>
                                 <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                              </ListItemPrefix>
                              SubMenu 03
                           </ListItem>
                        </List>
                     </AccordionBody>
                  </Accordion>
                  <Accordion
                     open={open === 2}
                     icon={
                        <ChevronDownIcon
                           strokeWidth={2.5}
                           className={`mx-auto h-4 w-4 transition-transform ${open === 2 ? "rotate-180" : ""
                              }`}
                        />
                     }
                  >
                     <ListItem className="p-0" selected={open === 2}>
                        <AccordionHeader
                           onClick={() => handleOpen(2)}
                           className="border-b-0 p-3"
                        >
                           <ListItemPrefix>
                              <ShoppingBagIcon className="h-5 w-5" />
                           </ListItemPrefix>
                           <Typography color="blue-gray" className="mr-auto font-normal">
                              Shop Details
                           </Typography>
                        </AccordionHeader>
                     </ListItem>
                     <AccordionBody className="py-1">
                        <List className="p-0">
                           <ListItem onClick={() => navigate("/order")}>
                              <ListItemPrefix>
                                 <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                              </ListItemPrefix>
                              My Orders
                           </ListItem>
                           <ListItem>
                              <ListItemPrefix>
                                 <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                              </ListItemPrefix>
                              SubMenu 02
                           </ListItem>
                           <ListItem>
                              <ListItemPrefix>
                                 <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                              </ListItemPrefix>
                              SubMenu 03
                           </ListItem>
                        </List>
                     </AccordionBody>
                  </Accordion>
                  <hr className="my-2 border-blue-gray-50" />
                  <ListItem onClick={() => navigate("/basket")}>
                     <ListItemPrefix>
                        <PiBasketFill className="h-5 w-5" />
                     </ListItemPrefix>
                     My Basket
                  </ListItem>
                  <ListItem onClick={() => navigate("/wishlist")}>
                     <ListItemPrefix>
                        <GiEternalLove className="h-5 w-5" />
                     </ListItemPrefix>
                     My Wishlist
                  </ListItem>
                  <ListItem>
                     <ListItemPrefix>
                        <MdFavorite className="h-5 w-5" />
                     </ListItemPrefix>
                     Favorites
                  </ListItem>
                  <ListItem>
                     <ListItemPrefix>
                        <IoIosSave className="h-5 w-5" />
                     </ListItemPrefix>
                     Saved
                  </ListItem>
                  <ListItem>
                     <ListItemPrefix>
                        <MdOutlinePayment className="h-5 w-5" />
                     </ListItemPrefix>
                     Payment Details
                  </ListItem>
                  <ListItem>
                     <ListItemPrefix>
                        <IoIosHelpCircle className="h-5 w-5" />
                     </ListItemPrefix>
                     Help
                  </ListItem>

                  <ListItem>
                     <ListItemPrefix>
                        <InboxIcon className="h-5 w-5" />
                     </ListItemPrefix>
                     Inbox
                     <ListItemSuffix>
                        <Chip
                           value="14"
                           size="sm"
                           variant="ghost"
                           color="blue-gray"
                           className="rounded-full"
                        />
                     </ListItemSuffix>
                  </ListItem>
                  <ListItem>
                     <ListItemPrefix>
                        <UserCircleIcon className="h-5 w-5" />
                     </ListItemPrefix>
                     Profile
                  </ListItem>
                  <ListItem>
                     <ListItemPrefix>
                        <Cog6ToothIcon className="h-5 w-5" />
                     </ListItemPrefix>
                     Settings
                  </ListItem>
                  <ListItem>
                     <ListItemPrefix>
                        <PowerIcon className="h-5 w-5" />
                     </ListItemPrefix>
                     Log Out
                  </ListItem>
               </List>
            </Card>
         </Drawer>
      </>
   );
};

export default SideBar;
