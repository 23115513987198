import React, { useEffect } from "react";
import VideoCard from "../Common/VideoCard";
import useSelectVideo from "../Hooks/useSelectVideo";
import { useDispatch, useSelector } from "react-redux";
import AccountTab from "../Component/AccountPage/AccountTab";
import emptyposts from "../../src/Assets/YourAccountIcons/emptyPosts.png";
import { followsuggestions } from "../Redux/ApiSlice/FollowSuggestionSlice";
import AccountProfileSection from "../Component/AccountPage/AccountProfileSection";
import { AccountProfile, AccountProfilePosts } from "../Redux/ApiSlice/AccountProfileSlice";

export default function AccountPage() {
   const dispatch = useDispatch();
   const ProfileDetails = useSelector((state) => state.AccountProfile);
   const { User_profile, User_posts } = ProfileDetails;
   const selectVideo = useSelectVideo();

   const handleSelectVideo = async (id) => {
      selectVideo(id, User_posts);
   };

   useEffect(() => {
      dispatch(AccountProfile());
      dispatch(followsuggestions());
      dispatch(AccountProfilePosts(User_profile.id));
   }, [dispatch, User_profile.id]);

   return (
      <div className="bg-[#000] h-full  overflow-hidden relative">
         <p className="text-[#ff3b3b] text-center w-full text-[12px] font-[700] flex justify-center z-50 lgtext-[10px] uppercase">
         Copyright © 2024 Galileo One Inc
         </p>
         {/* <p className="text-[#ff3b3b] text-center w-full text-[12px] font-[700] flex justify-center z-50 lgtext-[10px] uppercase">
            CC / ACCOUNT PAGE / FE / V12 / Oct 11 /2024
         </p> */}
         <div className="">
            <AccountProfileSection />
         </div>
         <div className="">
            <AccountTab activeTab={""} />
         </div>
         <div className="flex pt-2 flex-wrap gap-4 overflow-scroll no-scrollbar absolute w-full h-[-webkit-fill-available]">
            {User_posts?.length > 0 ? (
               <>
                  <div className="grid grid-cols-2 gap-2 overflow-scroll no-scrollbar px-2 w-full">
                     {User_posts?.map((item) => (
                        <VideoCard
                           id={item.id}
                           body={item.body}
                           belowDecs={true}
                           threeDots={false}
                           views={item.views}
                           video={item.video_url}
                           likes_count={item.likes_count}
                           selectVideo={handleSelectVideo}
                           avatar_url={item.created_by.avatar_url}
                        />
                     ))}
                  </div>
               </>
            ) : (
               <div className="flex justify-center flex-col items-center w-full h-full">
                  <img src={emptyposts} className="h-32 w-32" alt="emptyImage" />
                  <p className="text-[#a9a8a8] text-[8px] font-bold m-0">
                     No Posts Yet
                  </p>
               </div>
            )}
         </div>
      </div>
   );
}
