import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { TbCaptureFilled } from "react-icons/tb";
import { IoArrowBackCircle, IoSave } from "react-icons/io5";
import { FaCommentDots, FaCreativeCommonsRemix, FaLock, FaShareAltSquare } from "react-icons/fa";

export default function StreamSettings() {
   const navigate = useNavigate();
   const [isCheckedPublic, setIsCheckedPublic] = useState(false);
   const [isCheckedPrivate, setIsCheckedPrivate] = useState(false);
   const [isCheckedComment, setIsCheckedComment] = useState(false);
   const [isCheckedRemix, setIsCheckedRemix] = useState(false);
   const [isCheckedShare, setIsCheckedShare] = useState(false);
   const [isCheckedSave, setIsCheckedSave] = useState(false);
   const [Delay, setDely] = useState("3s");
   const [showDelay, setShowDelay] = useState(null);

   const [activeStep, setActiveStep] = useState(0);
   const [isLastStep, setIsLastStep] = useState(false);
   const [isFirstStep, setIsFirstStep] = useState(false);

   const handleCheckboxPrivate = () => {
      setIsCheckedPrivate(!isCheckedPrivate);
      setIsCheckedPublic(false);
   };

   const handleCheckboxComment = () => {
      setIsCheckedComment(!isCheckedComment);
   };

   const handleCheckboxRemix = () => {
      setIsCheckedRemix(!isCheckedRemix);
   };

   const handleCheckboxShare = () => {
      setIsCheckedShare(!isCheckedShare);
   };

   const handleCheckboxSave = () => {
      setIsCheckedSave(!isCheckedSave);
   };

   const allStepsCompleted = activeStep >= 3;
   const handleSubmit = () => {
      // handleSubmi/tVideo();
   };
   const handleNavigate = () => {
      navigate(-1);
   };
   const handleClickDely = () => {
      setShowDelay(!showDelay);
   };

   return (
      <div className="bg-black h-full pb-5  overflow-hidden">
         <div className="sticky top-0 bg-black z-10">
            <div className="grid grid-cols-12 px-3 pt-1 items-center">
               <div className="col-span-12 pb-[2px]">
                  {/* <p className="text-[#ff3b3b] text-center font-bold text-[8px]">
                     CC LIVE STREAM SETTINGS, FE, V2, JUNE 25, 2024
                  </p> */}
                  <p className="text-[#ff3b3b] text-center font-bold text-[8px]">
                  Copyright © 2024 Galileo One Inc
                  </p>
               </div>
               <div className="col-span-2">
                  <IoArrowBackCircle
                     className="text-white text-[24px] cursor-pointer"
                     onClick={() => handleNavigate()}
                  />
               </div>
               <div className="col-span-8 text-center">
                  <p className="text-white text-[14px] font-bold">Live Stream Settings</p>
               </div>
            </div>

            <hr className="w-full my-[6px] h-[0.5px] bg-gray-800 border-0" />
         </div>

         <div className="absolute w-full mt-2 h-[-webkit-fill-available]  overflow-hidden ">
            <div className="flex justify-between px-3 mb-3">
               <div className="flex items-center gap-2">
                  <FaLock className="text-[22px] text-white" />
                  <p className="text-[12px] font-semibold text-white">Private</p>
               </div>
               <label className="autoSaverSwitch relative inline-flex cursor-pointer select-none items-center">
                  <input
                     type="checkbox"
                     name="autoSaver"
                     className="sr-only"
                     checked={isCheckedPrivate}
                     onChange={handleCheckboxPrivate}
                  />
                  <span className={`slider flex h-[19px] w-[40px] items-center rounded-full p-1 duration-200 ${isCheckedPrivate ? "bg-[#3ccc2f]" : "bg-[#b4b4b5]"}`}>
                     <span className={`dot h-[13px] w-[13px] rounded-full bg-[#fff] duration-200 ${isCheckedPrivate ? "translate-x-5" : ""}`}></span>
                  </span>
               </label>
            </div>
            <div className="flex justify-between px-3 mb-3">
               <div className="flex items-center gap-2">
                  <FaCommentDots className="text-[22px] text-white" />
                  <p className="text-[12px] font-semibold text-white">Comments</p>
               </div>
               <label className="autoSaverSwitch relative inline-flex cursor-pointer select-none items-center">
                  <input
                     type="checkbox"
                     name="autoSaver"
                     className="sr-only"
                     checked={isCheckedComment}
                  // onChange={handleCheckboxComment}
                  />
                  <span className={`slider flex h-[19px] w-[40px] items-center rounded-full p-1 duration-200 ${isCheckedComment ? "bg-[#3ccc2f]" : "bg-[#b4b4b5]"}`}>
                     <span className={`dot h-[13px] w-[13px] rounded-full bg-[#fff] duration-200 ${isCheckedComment ? "translate-x-5" : ""}`}></span>
                  </span>
               </label>
            </div>
            <div className="flex justify-between px-3 mb-3">
               <div className="flex items-center gap-2">
                  <FaCreativeCommonsRemix className="text-[22px] text-white" />
                  <p className="text-[12px] font-semibold text-white">
                     Allow viewer to remix
                  </p>
               </div>
               <label className="autoSaverSwitch relative inline-flex cursor-pointer select-none items-center">
                  <input
                     type="checkbox"
                     name="autoSaver"
                     className="sr-only"
                     checked={isCheckedRemix}
                  />
                  <span className={`slider flex h-[19px] w-[40px] items-center rounded-full p-1 duration-200 ${isCheckedRemix ? "bg-[#3ccc2f]" : "bg-[#b4b4b5]"}`}>
                     <span className={`dot h-[13px] w-[13px] rounded-full bg-[#fff] duration-200 ${isCheckedRemix ? "translate-x-5" : ""}`}></span>
                  </span>
               </label>
            </div>
            <div className="flex justify-between px-3 mb-3">
               <div className="flex items-center gap-2">
                  <FaShareAltSquare className="text-[22px] text-white" />
                  <p className="text-[12px] font-semibold text-white">
                     Allow viewer to share video
                  </p>
               </div>
               <label className="autoSaverSwitch relative inline-flex cursor-pointer select-none items-center">
                  <input
                     type="checkbox"
                     name="autoSaver"
                     className="sr-only"
                     checked={isCheckedShare}
                  />
                  <span className={`slider flex h-[19px] w-[40px] items-center rounded-full p-1 duration-200 ${isCheckedShare ? "bg-[#3ccc2f]" : "bg-[#b4b4b5]"}`}>
                     <span className={`dot h-[13px] w-[13px] rounded-full bg-[#fff] duration-200 ${isCheckedShare ? "translate-x-5" : ""}`}></span>
                  </span>
               </label>
            </div>
            <div className="flex justify-between px-3 mb-3">
               <div className="flex items-center gap-2">
                  <IoSave className="text-[22px] text-white" />
                  <p className="text-[12px] font-semibold text-white">
                     Allow viewer to save video
                  </p>
               </div>
               <label className="autoSaverSwitch relative inline-flex cursor-pointer select-none items-center">
                  <input
                     type="checkbox"
                     name="autoSaver"
                     className="sr-only"
                  // checked={isCheckedSave}
                  // onChange={handleCheckboxSave}
                  />
                  <span className={`slider flex h-[19px] w-[40px] items-center rounded-full p-1 duration-200 ${isCheckedSave ? "bg-[#3ccc2f]" : "bg-[#b4b4b5]"}`}>
                     <span className={`dot h-[13px] w-[13px] rounded-full bg-[#fff] duration-200 ${isCheckedSave ? "translate-x-5" : ""}`}></span>
                  </span>
               </label>
            </div>
            <div className="flex justify-between px-3 mb-3">
               <div className="flex items-center gap-2">
                  <TbCaptureFilled className="text-[22px] text-white" />
                  <p className="text-[12px] font-semibold text-white">
                     Delay Capture
                  </p>
               </div>
               <label className="autoSaverSwitch relative inline-flex cursor-pointer select-none items-center">
                  <div class="relative inline-block text-left">
                     <div>
                        <button
                           type="button"
                           className="bg-white text-black px-[10px] text-[12px] py-[2px] rounded-md inline-flex items-center"
                           onClick={handleClickDely}
                        >
                           {Delay}
                        </button>
                     </div>
                     {showDelay &&
                        <div
                           class="absolute right-0 z-10 mt-2 w-36 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                           role="menu"
                           aria-orientation="vertical"
                           aria-labelledby="menu-button"
                           tabindex="-1"
                        >
                           <div class="py-1" role="none">
                              <a
                                 href="#"
                                 class="text-gray-700  px-3 py-2 text-sm flex hover:bg-gray-600 hover:text-white"
                                 role="menuitem"
                                 tabindex="-1"
                                 id="menu-item-0"
                              >
                                 3s
                              </a>
                              <a
                                 href="#"
                                 class="text-gray-700 block px-3 py-2 text-sm hover:bg-gray-600 hover:text-white"
                                 role="menuitem"
                                 tabindex="-1"
                                 id="menu-item-1"
                              >
                                 5s
                              </a>
                              <a
                                 href="#"
                                 class="text-gray-700 block px-3 py-2 text-sm hover:bg-gray-600 hover:text-white"
                                 role="menuitem"
                                 tabindex="-1"
                                 id="menu-item-2"
                              >
                                 10s
                              </a>
                              <a
                                 href="#"
                                 class="text-gray-700 block px-3 py-2 text-sm hover:bg-gray-600 hover:text-white"
                                 role="menuitem"
                                 tabindex="-1"
                                 id="menu-item-2"
                              >
                                 Off
                              </a>
                              <form method="POST" action="#" role="none">
                                 <button
                                    type="submit"
                                    class="text-gray-700 block w-full px-3 py-2 text-left text-sm"
                                    role="menuitem"
                                    tabindex="-1"
                                    id="menu-item-3"
                                 ></button>
                              </form>
                           </div>
                        </div>
                     }
                  </div>
               </label>
            </div>
         </div>
      </div>
   );
}
