import React from "react";
import AccountProfileSection from "../../../Component/AccountPage/AccountProfileSection";
import AccountTab from "../../../Component/AccountPage/AccountTab";
import ForgotPassword from "../../../Component/AccountPage/PrivateTab/ForgotPassword";

export default function ForgotPasswordContainer() {
   return (
      <div className="bg-[#000] overflow-hidden h-full relative ">
         {/* <p className="text-[#ff3b3b] text-center w-full mb-2  text-[12px] font-[700] flex justify-center z-50 lg:text-[10px] uppercase">
            CC ACCOUNT PRIVATE FORGOTPASSWORD PAGE-FE - V11 / JULY 1, 2024
         </p> */}
         <p className="text-[#ff3b3b] text-center w-full mb-2  text-[12px] font-[700] flex justify-center z-50 lg:text-[10px] uppercase">
         Copyright © 2024 Galileo One Inc
         </p>
         <div className="">
            <AccountProfileSection />
         </div>
         <AccountTab activeTab={"Private"} />
         <div className="p-2 overflow-y-scroll no-scrollbar absolute w-full h-[-webkit-fill-available]">
            <ForgotPassword />
         </div>
      </div>
   );
}
