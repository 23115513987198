import React, { useState, useRef } from "react";
import { IoMdCloseCircle } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { BsThreeDots, BsThreeDotsVertical } from "react-icons/bs";
import { FaArrowCircleUp } from "react-icons/fa";
import { Card } from "@material-tailwind/react";
import { useOutsideClick } from "../../Hooks/useOutsideClick";
import { IoHeartCircle } from "react-icons/io5";

export default function GroupPostComments() {
   const navigate = useNavigate();
   const ref = useRef(null);
   const [showId, setShowId] = useState(0);
   const [showReply, setShowReply] = useState(0);
   const [likeReply, setLikeReply] = useState(0);
   const handleLikeReply = (id) => likeReply ? setLikeReply(null) : setLikeReply(id);
   const handleShow = (id) => setShowId(id);
   const handleShowReply = (id) => showReply ? setShowReply(null) : setShowReply(id);
   const hideElement = () => {
      setShowId(null);
   };

   useOutsideClick(ref, hideElement);

   const demoChats = [
      {
         id: 1,
         userName: "Michael Smith",
         time: "3:02 PM",
         isSender: false,
         message: "What's everyone's favorite Ferrari model? Let's discuss!",
         userImg:
            "https://images.unsplash.com/photo-1522556189639-b150ed9c4330?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",

         replies: [
            {
               replyId: 212,
               userName: "Ashley Williams",
               time: "3:10 PM",
               message:
                  "The Testarossa has my heart! That iconic design and those side strakes are unbeatable.",
               userImg:
                  "https://images.unsplash.com/photo-1556157382-97eda2d62296?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            },
         ],
      },

      {
         id: 2,
         userName: "Ashley Williams",
         time: "3:11 PM",
         isSender: false,
         message:
            "The Testarossa has my heart! That iconic design and those side strakes are unbeatable.",
         userImg:
            "https://images.unsplash.com/photo-1556157382-97eda2d62296?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      },

      {
         id: 3,
         userName: "Christopher Davis",
         time: "3:20 PM",
         isSender: false,
         message:
            "The Dino is a classic choice! Ferrari really knows how to make timeless designs.",
         userImg:
            "https://images.unsplash.com/photo-1492562080023-ab3db95bfbce?q=80&w=2048&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      },
      {
         id: 4,
         userName: "Sarah Brown",
         time: "3:30 PM",
         isSender: false,
         message:
            "Couldn't agree more! Ferrari's blend of performance and beauty is unmatched.",
         userImg:
            "https://images.unsplash.com/photo-1544005313-94ddf0286df2?q=80&w=1888&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",

         replies: [
            {
               replyId: 211,
               userName: "Ashley Williams",
               time: "3:10 PM",
               message:
                  "The Testarossa has my heart! That iconic design and those side strakes are unbeatable.",
               userImg:
                  "https://images.unsplash.com/photo-1556157382-97eda2d62296?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            },
            {
               replyId: 212,
               userName: "You",
               time: "3:35 PM",
               isSender: true,
               message:
                  "Absolutely, Ferrari's dedication to excellence shines through in every model.",
               userImg:
                  "https://images.unsplash.com/photo-1639149888905-fb39731f2e6c?q=80&w=1964&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            },
         ],
      },
      {
         id: 5,
         userName: "You",
         time: "3:35 PM",
         isSender: true,
         message:
            "Absolutely, Ferrari's dedication to excellence shines through in every model.",
         userImg:
            "https://images.unsplash.com/photo-1639149888905-fb39731f2e6c?q=80&w=1964&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      },
      {
         id: 6,
         userName: "Christopher Davis",
         time: "3:40 PM",
         isSender: false,
         message: "Indeed! Ferrari's legacy is built on passion and innovation.",
         userImg:
            "https://images.unsplash.com/photo-1492562080023-ab3db95bfbce?q=80&w=2048&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      },
      {
         id: 7,
         userName: "Sarah Brown",
         time: "3:50 PM",
         isSender: false,
         message:
            "Absolutely, it's the passion that makes owning a Ferrari more than just having a car.",
         userImg:
            "https://images.unsplash.com/photo-1544005313-94ddf0286df2?q=80&w=1888&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      },
      {
         id: 8,
         userName: "Christopher Davis",
         time: "4:00 PM",
         isSender: false,
         message:
            "Absolutely, it's a lifestyle. Once you drive a Ferrari, nothing else compares.",
         userImg:
            "https://images.unsplash.com/photo-1492562080023-ab3db95bfbce?q=80&w=2048&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      },
   ];

   const LikeReplyFn = (data) => {
      return (
         <div className="flex items-center gap-[3px] justify-center">
            <p className="text-[#3ea8d8] text-[9px]">1k</p>
            <IoHeartCircle
               color={likeReply === data.replyId ? "red" : "white"}
               className={` text-[14px] cursor-pointer`}
               onClick={() => handleLikeReply(data?.replyId)}
            />
         </div>
      );
   };

   return (
      <div className="bg-[#000] overflow-hidden h-full relative">
         {/* <p className="w-full text-center text-[10px] text-red-900">
            CC USER CREATED GROUP POST COMMENTS PAGE / FE V2 / JULY 05, 2024
         </p> */}
         <p className="w-full text-center text-[10px] text-red-900">
         Copyright © 2024 Galileo One Inc
         </p>
         <div className="grid grid-cols-12 items-start pt-1 pb-2 px-3">
            <div className="col-span-1 mt-3">
               <IoMdCloseCircle
                  onClick={() => navigate(-1)}
                  className="text-[24px] md:text-[20px] text-red-500 ml-[-2px] flex justify-center items-center rounded-full cursor-pointer transform transition duration-300 ease-in-out hover:scale-125"
               />
            </div>
            <div className="col-span-9">
               <div className="flex items-center gap-3 mt-2">
                  <img
                     alt="profilepic"
                     className="h-10 md:h-8 w-10 md:w-8 rounded-full object-cover"
                     src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?q=80&w=1780&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                  />
                  <div className="leading-[15px] md:leading-[14px]">
                     <p className="text-white text-[18px] md:text-[14px]">John Smith</p>
                     <p className="text-[#3ea8d8] text-[14px] md:text-[10px] font-semibold p-0">
                        10 Day Ago
                     </p>
                  </div>
               </div>
               <img
                  alt="postimg"
                  className="w-full object-cover mt-2 rounded-[10px]"
                  src="https://images.unsplash.com/photo-1632441730372-d8509de481d1?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
               />
            </div>
         </div>
         <div className="relative h-[60%] overflow-y-scroll bg-[#0c0c0c] rounded-tl-[40px] md:rounded-tl-[30px] rounded-tr-[40px] md:rounded-tr-[30px] pb-6">
            {demoChats.map((data) => (
               <div className="px-3 pt-2 hover:bg-[#191919]" key={data.id}>
                  <div className="grid grid-cols-12 pb-[8px]">
                     <div className="col-span-1">
                        <img
                           alt={data.userName}
                           src={data.userImg}
                           className="h-7 w-7 md:h-5 md:w-5 object-cover rounded-full"
                        />
                     </div>
                     <div className="col-span-9 ml-[3px]">
                        <div className="flex items-center gap-2">
                           <p className="text-white text-[16px] md:text-[14px]">{data.userName}</p>
                           <p className="text-[#3ea8d8] text-[12px] md:text-[9px]">{data.time}</p>
                        </div>
                        <div className="flex mt-[1px]">
                           <p className="text-[14px] md:text-[12px] text-white bg-[#34333] leading-[14px] p-[8px] pl-0 rounded-md">
                              {data.message}
                           </p>
                        </div>
                        <div>
                           {data.replies?.length > 0 && (
                              <p
                                 className="text-[#3ea8d8] text-[14px] md:text-[12px] cursor-pointer"
                                 onClick={() => handleShowReply(data.id)}
                              >
                                 {showReply ? "Hide" : "show"} replies
                              </p>
                           )}
                        </div>
                     </div>
                     <div className="col-span-2 flex justify-end relative">
                        <BsThreeDots
                           className="text-[#3ea8d8] text-[16px] cursor-pointer "
                           onClick={() => handleShow(data.id)}
                        />
                        {data.id === showId && (
                           <Card
                              className="w-20 absolute top-4 right-4 rounded-md p-1 z-50"
                              ref={ref}
                           >
                              <p className="p-1 pl-2 cursor-pointer hover:bg-gray-200 hover:rounded-md text-[12px]">
                                 Report
                              </p>
                              <p className="p-1 pl-2 cursor-pointer hover:bg-gray-200 hover:rounded-md text-[12px]">
                                 Share
                              </p>
                              <p className="p-1 pl-2 cursor-pointer hover:bg-gray-200 hover:rounded-md text-[12px]">
                                 Reply
                              </p>
                           </Card>
                        )}
                     </div>
                  </div>
                  {showReply === data.id && (
                     <>
                        {data.replies?.map((data) => (
                           <div
                              className="grid grid-cols-12 pb-[8px] justify-end"
                              key={data.replyId}
                           >
                              <div className="col-span-1"></div>
                              <div className="col-span-9">
                                 <div className="flex items-center gap-[6px] justify-start">
                                    <img
                                       alt={data.userName}
                                       src={data.userImg}
                                       className="h-7 w-7 md:h-5 md:w-5 object-cover rounded-full"
                                    />
                                    <p className="text-white text-[14px] md:text-[12px]">
                                       {data.userName}
                                    </p>
                                    <p className="text-[#3ea8d8] text-[11px] md:text-[9px]">{data.time}</p>
                                 </div>
                              </div>
                              <div className="col-span-2 flex justify-between items-center relative">
                                 <LikeReplyFn data={data} />
                                 <BsThreeDotsVertical
                                    className="text-[#3ea8d8] text-[16px] cursor-pointer absolute right-[-6px]"
                                    onClick={() => handleShow(data?.replyId)}
                                 />
                                 {data?.replyId === showId && (
                                    <Card
                                       className="w-20 absolute top-4 right-4 rounded-md p-1 text-black z-50"
                                       ref={ref}
                                    >
                                       <p className="p-1 pl-2 cursor-pointer hover:bg-gray-200 hover:rounded-md text-[12px]">
                                          Report
                                       </p>
                                       <p className="p-1 pl-2 cursor-pointer hover:bg-gray-200 hover:rounded-md text-[12px]">
                                          Share
                                       </p>
                                       <p className="p-1 pl-2 cursor-pointer hover:bg-gray-200 hover:rounded-md text-[12px]">
                                          Reply
                                       </p>
                                    </Card>
                                 )}
                              </div>
                              <div className="col-span-2"></div>
                              <div className="col-span-9 flex justify-start mt-[2px]">
                                 <p className="text-[14px] md:text-[12px] text-gray-500 leading-[14px] p-[5px]">
                                    {data.message}
                                 </p>
                              </div>
                           </div>
                        ))}
                     </>
                  )}
               </div>
            ))}
         </div>
         <div className="sticky bottom-0 bg-[#0c0c0c] w-full flex items-center">
            <div className="flex justify-between items-center gap-1 py-1 px-2 relative w-full">
               <input
                  type="text"
                  placeholder="Add a comment..."
                  className="py-2 md:py-1 rounded-md focus:outline-none bg-white w-full pl-3 pr-8"
               />
               <FaArrowCircleUp className="absolute right-3 items-center text-black text-[24px] md:text-[20px] cursor-pointer" />
            </div>
         </div>
      </div>
   );
}
