import React, { useRef, useState } from "react";
import { FaBars } from "react-icons/fa";
import { IoArrowForwardCircle, IoClose } from "react-icons/io5";
import HelpOptions from "../../Component/Help/HelpOptions";
import { helpPageData } from "../../Datas/Datas";
import TitleSection from "../../Component/Help/TitleSection";
import { useNavigate } from "react-router-dom";
import { useOutsideClick } from "../../Hooks/useOutsideClick";
import { ccLogo } from "../../Datas/Assests";

export default function HelpMain({ isFullScreen }) {
  const [ShowMenu, setShowMenu] = useState(false);
  const menuRef = useRef(null);
  const navigate = useNavigate();

  useOutsideClick(menuRef, () => {
    setShowMenu(false);
  });

  return (
    <div
      className={`${isFullScreen ? "px-4 md:px-28 h-screen" : "p-2 px-4 h-full"
        }  relative  overflow-Y-scroll`}
    >
      {/* <p className="text-[red] text-[12px] w-full text-center font-bold ">
        CC HELP AND SUPPORT PAGE,FE,V3,JULY 10,2024
      </p> */}
      <p className="text-[red] text-[12px] w-full text-center font-bold ">
      Copyright © 2024 Galileo One Inc
      </p>
      <div className="sticky top-0 flex justify-between items-center z-50 bg-white">
  

        <img src={ccLogo}  onClick={() => navigate("/home")} className="h-12 w-12 cursor-pointer" />


        <div className="relative">
          {!ShowMenu ? (
            <FaBars
              className={`${isFullScreen ? "text-[1.3rem] md:text-[2rem]" : "text-[1.3rem]"
                } cursor-pointer`}
              onClick={() => setShowMenu(!ShowMenu)}
            />
          ) : (
            <IoClose
              className={`${isFullScreen ? "text-[1.5rem] md:text-[2rem]" : "text-[1.5rem]"
                }  duration-700 transform cursor-pointer`}
              onClick={() => setShowMenu(!ShowMenu)}
            />
          )}
          {ShowMenu && (
            <div
              className={`bg-blue-gray-900  rounded-md absolute z-50 right-0 duration-10 transform ${isFullScreen ? "w-36 md:p-2 md:w-44" : "w-36"
                }`}
              ref={menuRef}
            >
              <p className="text-white text-[12px] font-semibold p-2 hover:bg-gray-500">
                Get Started
              </p>
              <p className="text-white text-[12px] font-semibold p-2 hover:bg-gray-500">
                Manage Your account
              </p>
              <p className="text-white text-[12px] font-semibold p-2 hover:bg-gray-500">
                Account Privacy
              </p>
              <p className="text-white text-[12px] font-semibold p-2 hover:bg-gray-500">
                Policies and Reporting
              </p>
              <p className="text-white text-[12px] font-semibold p-2 hover:bg-gray-500">
                Advertisement
              </p>{" "}
              <p className="text-white text-[12px] font-semibold p-2 hover:bg-gray-500">
                Frequently asked questions
              </p>
            </div>
          )}
        </div>
      </div>
      <div className="w-full flex flex-col justify-center items-center mt-6">
        <TitleSection isFullScreen={isFullScreen} />
      </div>
      <div className="mt-4 flex flex-col justify-center items-center">
        <HelpOptions helpPageData={helpPageData} isFullScreen={isFullScreen} />
      </div>
      <div className={`sticky float-right  ${isFullScreen ? "mt-12" : ""}`}>
        <IoArrowForwardCircle
          className={`text-[1.5rem] cursor-pointer text-end ${isFullScreen ? "text-[2rem]" : ""
            }`}
          onClick={() =>
            navigate(isFullScreen ? "/help/questions/1" : "/get-questions/1")
          }
        />
      </div>
    </div>
  );
}
