import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { HiDotsVertical } from "react-icons/hi";
import { IoArrowBackCircle } from "react-icons/io5";
import { GoPlusCircle } from "react-icons/go";
import { IoMdCheckmark } from "react-icons/io";
import { FaRegPlusSquare } from "react-icons/fa";
import { TbPhotoEdit } from "react-icons/tb";

const OtherGroupPost = () => {
   const navigate = useNavigate();

   const [isCheckedComment, setIsCheckedComment] = useState(false);
   const [isCheckedShare, setIsCheckedShare] = useState(false);

   const [isCheckedPoll, setIsCheckedPoll] = useState(false);
   const [isCheckedLink, setIsCheckedLink] = useState(false);

   const [showMenu, setShowMenu] = useState(false)

   const handlePoll = () => {
      setIsCheckedPoll(!isCheckedPoll);
   };

   const handleLink = () => {
      setIsCheckedLink(!isCheckedLink);
   };


   const fileInputRef = useRef(null);
   const [open, setOpen] = useState(false);

   const [selectedImage, setSelectedImage] = useState(null); // State to store selected image

   const handleIconClick = () => {
      fileInputRef.current.click();
   };
   const [showImage, setShowImage] = useState(null);


   const handleFileInputChange = (event) => {
      const file = event.target.files[0];
      if (!file) return;
      const fileUrl = URL.createObjectURL(file);
      setSelectedImage(fileUrl);
      setOpen(!open);
      setShowImage(true);


   };

   return (
      <div className="bg-black h-full overflow-y-scroll">
         <div className="grid grid-cols-12 px-3 items-center">
            <div className="col-span-12">
               <p className="text-[#ff3b3b] text-center font-bold text-[12px] md:text-[10px] uppercase">
               Copyright © 2024 Galileo One Inc
               </p>
               {/* <p className="text-[#ff3b3b] text-center font-bold text-[12px] md:text-[10px] uppercase">
                  CC / OTHERS GROUP POST MEDIA / FE/ V2 /oct 11 / 2024
               </p> */}
            </div>
            <div className="col-span-2">
               <IoArrowBackCircle className="text-white text-[30px] md:text-[24px] cursor-pointer" onClick={() => navigate(-1)} />
            </div>
            <div className="col-span-8 text-center">
               <p className="text-white text-[20px] md:text-[18px]">Ferrari Group</p>
            </div>
            <div className="col-span-2 flex justify-end">
               {showMenu ?
                  <p className="text-white hover:text-red-600 text-[12px] font-bold cursor-pointer" onClick={() => setShowMenu(false)}>Close</p>
                  :
                  <HiDotsVertical className="text-white text-[30px] md:text-[24px] cursor-pointer" onClick={() => setShowMenu(!showMenu)} />
               }
            </div>
         </div>

         {showMenu &&
            <div className="absolute top-10 right-2 bg-[#d9d9d9] px-3 py-1 z-50 rounded">
               <p className="text-black text-[13px] cursor-pointer">Draft</p>
               <p className="text-black text-[13px] cursor-pointer">Delete</p>
            </div>
         }

         <hr className="w-full my-[6px] h-[0.5px] bg-gray-800 border-0" />

         <div className="px-3 pt-1">
            <div className="flex items-center gap-4">
               <p className="uppercase text-white font-bold text-[20px] md:text-[18px]">Add Media</p>
               <GoPlusCircle className="text-white text-[20px] md:text-[18px] cursor-pointer" />
            </div>
            <div className="pt-1">
         
               <input
                  type="file"
                  ref={fileInputRef}
                  accept="image/*"
                  className="hidden"
                  onChange={handleFileInputChange}
               />

               {!showImage && <div className="flex justify-center items-center  bg-[#D9D9D9] h-[25vh] md:h-[20vh] w-full object-cover rounded">
                  <FaRegPlusSquare
                     onClick={handleIconClick}
                     className="text-[#1d1d1d] text-[23px] cursor-pointer "
                  />
               </div>}
               {showImage && <div className="flex flex-col">
                  <div className="relative group">
                     <img src={selectedImage} alt="selected" className="h-[25vh] md:h-[20vh] w-full object-cover rounded"/>
                     <div className="absolute inset-0 z-10 items-center justify-center hidden h-[25vh] md:h-[20vh] rounded w-full object-cover  transition-all duration-300 bg-black/10 group-hover:flex">
                        <button className="flex items-center justify-center  h-2 w-2 text-center  cursor-pointer ring-1 ring-transparent ">
                           <TbPhotoEdit
                              color="white"
                              onClick={handleIconClick}
                           />
                        </button>
                     </div>
                  </div>
               </div>}


            </div>
            <div className="pt-4">
               <p className="uppercase text-white font-bold text-[20px] md:text-[18px]">Add Caption</p>
               <textarea
                  rows="4"
                  placeholder="Type Something"
                  className="w-full bg-[#ffffff] p-[8px] focus:outline-none resize-none rounded mt-1"
               />
            </div>
            <div className="pt-3">
               <p className="uppercase text-white font-bold text-[20px] md:text-[18px]">Settings</p>
               <div className="flex flex-col gap-1">
                  <div className="grid grid-cols-12 items-center">
                     <div className="col-span-5">
                        <p className="text-white text-[16px] md:text-[14px]">Comments</p>
                     </div>
                     <div className="col-span-7">
                        <label className="autoSaverSwitch relative inline-flex cursor-pointer select-none items-center">
                           <input
                              type="checkbox"
                              name="autoSaver"
                              className="sr-only"
                              checked={isCheckedComment}
                              onChange={() => setIsCheckedComment(!isCheckedComment)}
                           />
                           <span className={`slider mr-3 flex h-[19px] w-[40px] items-center rounded-full p-1 duration-200 ${isCheckedComment ? 'bg-[#9d00ff]' : 'bg-[#ffffff]'}`}>
                              <span className={`dot h-[13px] w-[13px] rounded-full duration-200 ${isCheckedComment ? 'translate-x-5 bg-[#fff]' : 'bg-black'}`}></span>
                           </span>
                        </label>
                     </div>
                  </div>
                  <div className="grid grid-cols-12 items-center">
                     <div className="col-span-5">
                        <p className="text-white text-[16px] md:text-[14px]">Share</p>
                     </div>
                     <div className="col-span-7">
                        <label className="autoSaverSwitch relative inline-flex cursor-pointer select-none items-center">
                           <input
                              type="checkbox"
                              name="autoSaver"
                              className="sr-only"
                              checked={isCheckedShare}
                              onChange={() => setIsCheckedShare(!isCheckedShare)}
                           />
                           <span className={`slider mr-3 flex h-[19px] w-[40px] items-center rounded-full p-1 duration-200 ${isCheckedShare ? 'bg-[#9d00ff]' : 'bg-[#ffffff]'}`}>
                              <span className={`dot h-[13px] w-[13px] rounded-full duration-200 ${isCheckedShare ? 'translate-x-5 bg-[#fff]' : 'bg-black'}`}></span>
                           </span>
                        </label>
                     </div>
                  </div>
               </div>
            </div>
            <div className="py-2 flex justify-between items-center">
               <div className="flex items-center">
                  <label for="default-checkbox" className="me-2 text-[16px] md:text-[14px] font-medium text-white">Poll</label>
                  <input
                     type="checkbox"
                     id="myPoll"
                     checked={isCheckedPoll}
                     onChange={handlePoll}
                     className="hidden"
                  />
                  <label
                     htmlFor="myPoll"
                     className={`w-4 h-4 border border-gray-300 rounded cursor-pointer flex items-center justify-center ${isCheckedPoll ? 'bg-purple-600' : ''}`}
                  >
                     {isCheckedPoll && (
                        <IoMdCheckmark className="text-white" />
                     )}
                  </label>
               </div>
               <div className="flex items-center">
                  <label for="default-checkbox" className="me-2 text-[16px] md:text-[14px] font-medium text-white">Links</label>
                  <input
                     type="checkbox"
                     id="myLinks"
                     checked={isCheckedLink}
                     onChange={handleLink}
                     className="hidden"
                  />
                  <label
                     htmlFor="myLinks"
                     className={`w-4 h-4 border border-gray-300 rounded cursor-pointer flex items-center justify-center ${isCheckedLink ? 'bg-purple-600' : ''}`}
                  >
                     {isCheckedLink && (
                        <IoMdCheckmark className="text-white" />
                     )}
                  </label>
               </div>
               <button className="text-black bg-white px-5 py-[2px] rounded hover:text-white hover:bg-purple-600">
                  Next
               </button>
            </div>
         </div>
      </div>
   )
}

export default OtherGroupPost