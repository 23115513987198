import React from "react";
import { useNavigate } from "react-router-dom";
import { FaArrowAltCircleLeft, FaSearch } from "react-icons/fa";
import { groupSearchComment } from "../../Datas/Datas";

export default function DraftNewMessages() {
   const navigate = useNavigate();

   return (
      <section className="h-full w-full bg-black overflow-hidden pt-4">
         {/* <p className="uppercase font-bold text-[10px] text-red-700 w-full text-center absolute top-0">
            CC Draft Message / FE / V3 / June 10, 2024
         </p> */}
         <p className="uppercase font-bold text-[10px] text-red-700 w-full text-center absolute top-0">
         Copyright © 2024 Galileo One Inc
         </p>

         <div className="grid grid-cols-12 px-2 pb-1">
            <div className="col-span-2 flex items-center justify-start text-[#fff]">
               <FaArrowAltCircleLeft
                  className="text-white text-[17px] cursor-pointer"
                  onClick={() => navigate(-1)}
               />
            </div>
            <div className="col-span-8 flex items-center justify-center">
               <p className="text-[16px] text-white font-bold">New Message</p>
            </div>
         </div>

         <div className="h-[0.1px] bg-[#6f6f6f] w-full" />

         <div className="grid grid-cols-12 gap-3 py-2 w-full items-center px-2">
            <div className="col-span-8 w-full flex justify-start relative">
               <FaSearch className="absolute top-2 left-2 cursor-pointer" />
               <input
                  type="text"
                  placeholder="Search"
                  className="rounded-full w-full p-1 pl-[30px] pr-2 outline-none"
               />
            </div>
            <button
               onClick={() => navigate("/mobileMessages")}
               className="col-span-4 text-white bg-[#2e2e2e] hover:bg-[#3B3B3B] rounded text-[12px] px-2 font-bold w-full h-full"
            >
               Group Chat
            </button>
         </div>

         <div className="overflow-y-scroll no-scrollbar mb-2 h-[-webkit-fill-available] absolute w-full px-2">
            <p className="text-[16px] font-bold text-[white]">Recommended</p>
            {groupSearchComment.map((data) => (
               <div className="grid grid-cols-12 items-center pt-2" key={data.id}>
                  <img
                     alt={data.userName}
                     src={data.profileUrl}
                     className="col-span-1 w-[22px] h-[22px] rounded-full object-cover cursor-pointer"
                  />
                  <p className="col-span-11 text-white text-[16px] ml-2 cursor-pointer line-clamp-1">{data.userName}</p>
               </div>
            ))}
         </div>
      </section>
   );
}
